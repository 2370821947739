import ExportStatus from '../../common/export-status';

function getPendingResponse(fileName) {
	return {
		id: '6ec0bd7f-11c0-43da-975e-2a8ad9ebae0b',
		meta: {
			userId: '2ba211d2-8724-4a52-9baf-85f407bda462',
			userName: 'sysadmin',
			userEmail: 'sysadmin@ainq.com',
			tenantId: 'TESTTENANT',
			tenantCode: 'TESTTENANT',
			organizationId: '22c5a2c8-4ca1-451d-b7e0-cddba08b140e',
			organizationCode: 'ORG_CODE_1',
			transactionId: 'e26ebbd7-9f58-40fb-b65e-a9d1766a3c29',
			createdTime: '2021-02-01T17:32:28Z',
			receivedTime: '2021-02-01T17:34:28Z',
			completedTime: '2021-02-01T17:36:28Z',
		},
		file: {
			name: fileName,
			size: 3000,
			rows: 203,
			bucketId: 'aiweb-prompt-export',
		},
		args: {
			fileName: 'Prompt-Alerts-02-27-2021',
			query: {
				expressions: [
					{
						var: 'subscription.id',
						op: 'equals',
						valueType: 'number',
						value: [16],
					},
					{
						var: 'patient.subscriber.pcpname',
						op: 'equals',
						value: ['Gregory House', 'Greg House'],
					},
				],
			},
			options: {
				timeZone: 'America/New_York',
				weekBeginDay: 0,
				orderBy: 'eventTime',
			},
			nameMrn: 'Ledner',
			include: {
				raw: true,
				columns: [
					'event.visit.dischargedisposition',
					'patient.publisher.insurance',
					'patient.publisher.gender',
				],
			},
		},
		status: ExportStatus.RUNNING,
	};
}

const triesBeforeComplete = 1;
let tries = 0;

export default function getExportTaskMock({ resolve, reject, db }) {
	if (!db.hasClickedExport) {
		// On page load return 204, mocking the export file not being found
		resolve(204);
		return;
	}

	if (db.exportFileName === 'error.csv') {
		// This allows testing failed export by setting the file name to 'error'
		reject(500);
		return;
	}

	tries += 1;

	// Set the filename from the startExport mocked service in the response
	let response = getPendingResponse(db.exportFileName);

	if (tries > triesBeforeComplete) {
		response = {
			...response,
			status: ExportStatus.COMPLETED,
		};

		tries = 0;
	}

	setTimeout(() => {
		resolve(response);
	}, 1000);
}

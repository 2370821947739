/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { Text } from '@audacious/components/components/Typography';
import { AnchorIconButton } from '@audacious/components/components/DropDown';
import Menu, { MenuButton } from '@audacious/components/components/Menu';
import Chip from '@audacious/components/components/Chip';
import { faEllipsis } from '@audacious/icons/solid/faEllipsis';
import cloneDeep from 'lodash/cloneDeep';
import {
	startEditFilterAction,
	startDeleteFilterAction,
} from '../../../../actions/filter-actions';

class SavedFilterListing extends Component {
	constructor(props) {
		super(props);

		this.handleEditFilter = this.handleEditFilter.bind(this);
		this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
		this.handleDuplicateFilter = this.handleDuplicateFilter.bind(this);
	}

	handleEditFilter({ meta: filterData }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startEditFilterAction, filterData);
	}

	handleDeleteFilter({ meta: filterData }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startDeleteFilterAction, {
			filterData,
			plusMore: 0,
		});
	}

	handleDuplicateFilter({ meta: filterData }) {
		const { name, expressions } = filterData;

		const {
			fluxibleContext: {
				service: { createFilter },
			},
		} = this.props;

		createFilter({
			data: {
				name: `${name} - copy`,
				query: {
					expressions: cloneDeep(expressions),
				},
			},
		});
	}

	render() {
		const { filterData, savedFilterId } = this.props;

		const { _id: filterId, name: filterName } = filterData;

		const inUse = savedFilterId === filterId;

		const inUseMessage = inUse ? (
			<Chip
				className="in-use-message"
				variant="pill"
				color="cyan"
				size="xs"
			>
				In Use
			</Chip>
		) : null;

		return (
			<div className="saved-filter-listing">
				<div className="listing-text">
					<Text weight="normal" size="md">
						{filterName}
					</Text>
					{inUseMessage}
				</div>
				<Menu
					id={`filter-${filterId}-menu`}
					Anchor={AnchorIconButton}
					anchorProps={{
						icon: faEllipsis,
						size: 'md',
						'aria-label': `${filterName} Menu`,
					}}
				>
					<MenuButton
						id="edit-saved-filter-menu-item"
						onClick={this.handleEditFilter}
						onClickMeta={filterData}
						disabled={inUse}
					>
						Edit
					</MenuButton>
					<MenuButton
						id="duplicate-saved-filter-menu-item"
						onClick={this.handleDuplicateFilter}
						onClickMeta={filterData}
					>
						Duplicate
					</MenuButton>
					<MenuButton
						id="delete-saved-filter-menu-item"
						onClick={this.handleDeleteFilter}
						onClickMeta={filterData}
						disabled={inUse}
					>
						Delete
					</MenuButton>
				</Menu>
			</div>
		);
	}
}

SavedFilterListing.propTypes = {
	filterData: PropTypes.shape({
		_id: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
	savedFilterId: PropTypes.string,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

SavedFilterListing.defaultProps = {
	savedFilterId: null,
};

export default applyFluxibleContext(SavedFilterListing);

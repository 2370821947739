/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import RemovableChip from '@audacious/components/components/RemovableChip';
import Chip from '@audacious/components/components/Chip';
import { Row, Column } from '@audacious/components/components/Grid';
import Button from '@audacious/components/components/Button';
import Icon from '@audacious/components/components/Icon';
import { faAnglesLeft } from '@audacious/icons/solid/faAnglesLeft';
import { faAnglesRight } from '@audacious/icons/solid/faAnglesRight';
import forEach from 'lodash/forEach';
import SavedFilterMenu from './saved-filter-menu';
import CombinedFiltersMenu from './combined-filters-menu';
import {
	createFilterFromCombined,
	removeCustomFilter,
	clearAllFilters,
	editFilter,
} from '../../../../actions/filter-actions';
import './applied-filters.scss';
import FilterLabel from './filter-label';

const maxNumberChips = 3;

class AppliedFilters extends Component {
	constructor(props) {
		super(props);

		this.handleClearCustom = this.handleClearCustom.bind(this);
		this.handleClearAll = this.handleClearAll.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleEdit = this.handleEdit.bind(this);

		this.state = {
			showMore: false,
		};
	}

	handleClearAll() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(clearAllFilters);
	}

	handleClearCustom({ meta }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(removeCustomFilter, meta);
	}

	handleSave() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(createFilterFromCombined);
	}

	handleEdit() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(editFilter);
	}

	renderChip(filterTypeName, displayValue, operator, keys) {
		return (
			<Column key={JSON.stringify(keys)} width="content">
				<RemovableChip
					variant="pill"
					color="orange"
					size="lg"
					onClear={this.handleClearCustom}
					onClearMeta={keys}
				>
					<FilterLabel
						name={filterTypeName}
						operator={operator}
						value={displayValue}
					/>
				</RemovableChip>
			</Column>
		);
	}

	renderSavedFilter() {
		const { savedFilterGroup } = this.props;

		if (isNil(savedFilterGroup)) {
			return null;
		}

		return (
			<Column width="content">
				<SavedFilterMenu filterGroup={savedFilterGroup} />
			</Column>
		);
	}

	renderCustomFilters() {
		const { customFilterGroup } = this.props;

		if (isNil(customFilterGroup)) {
			return null;
		}

		let chips = [];

		forEach(customFilterGroup.getFilters(), filter => {
			const { id, name, path, operator, valueData } = filter;

			if (valueData.length > 1) {
				chips.push(
					<Column key={`${path}${operator}`} width="content">
						<CombinedFiltersMenu
							name={name}
							values={valueData}
							onRemove={this.handleClearCustom}
							filterId={id}
							operator={operator}
						/>
					</Column>,
				);
			} else {
				forEach(valueData, (valueItem, valueIndex) => {
					chips.push(
						this.renderChip(
							name,
							valueItem.displayValue,
							operator,
							{
								filterId: id,
								valueIndex,
							},
						),
					);
				});
			}
		});

		if (chips.length <= 0) {
			return null;
		}

		let showElement = null;
		const { showMore } = this.state;

		if (chips.length > maxNumberChips) {
			let showElementContent = null;

			if (showMore) {
				showElementContent = <Icon icon={faAnglesLeft} size="lg" />;
			} else {
				const count = chips.length - maxNumberChips;

				chips = chips.slice(0, maxNumberChips);

				showElementContent = (
					<>
						<span>{`+${count}`}</span>
						<Icon
							className="show-more"
							icon={faAnglesRight}
							size="lg"
						/>
					</>
				);
			}

			showElement = (
				<Column width="content">
					<Chip
						className="show-chip"
						variant="pill"
						color="orange"
						size="lg"
						onClick={() => this.setState({ showMore: !showMore })}
					>
						{showElementContent}
					</Chip>
				</Column>
			);
		}

		return (
			<>
				{chips}
				{showElement}
			</>
		);
	}

	render() {
		const customSection = this.renderCustomFilters();
		const savedSection = this.renderSavedFilter();

		let content = null;

		if (!isNil(customSection) || !isNil(savedSection)) {
			let saveFiltersElement = null;
			let editFiltersElement = null;

			if (!isNil(customSection)) {
				saveFiltersElement = (
					<Column width="content">
						<Button
							id="save-applied-filters-btn"
							color="primary"
							size="xs"
							variant="opaque"
							onClick={this.handleSave}
						>
							Save
						</Button>
					</Column>
				);

				editFiltersElement = (
					<Column width="content">
						<Button
							id="edit-applied-filters-btn"
							color="primary"
							size="xs"
							variant="opaque"
							onClick={this.handleEdit}
						>
							Edit
						</Button>
					</Column>
				);
			}

			content = (
				<>
					{savedSection}
					{customSection}
					<Column width="content">
						<Button
							id="remove-applied-filters-btn"
							color="primary"
							size="xs"
							variant="opaque"
							onClick={this.handleClearAll}
						>
							Clear
						</Button>
					</Column>
					{saveFiltersElement}
					{editFiltersElement}
				</>
			);
		}

		return <Row gutter={{ h: '8', v: '16' }}>{content}</Row>;
	}
}

AppliedFilters.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customFilterGroup: PropTypes.any.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	savedFilterGroup: PropTypes.any.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

AppliedFilters.defaultProps = {};

export default connectToStores(
	applyFluxibleContext(AppliedFilters),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		return {
			customFilterGroup: filterStore.getCustomFilterGroup(),
			savedFilterGroup: filterStore.getSavedFilterGroup(),
		};
	},
);

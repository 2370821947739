export default function retrieveFilterConfigMock({ resolve }) {
	resolve([
		{
			path: 'event.adttype',
			name: 'Event Type',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: false,
			valueOptions: [
				{
					label: 'Admission',
					value: 'A01',
				},
				{
					label: 'Discharge',
					value: 'A03',
				},
				{
					label: 'Registration',
					value: 'A04',
				},
				{
					label: 'Change an Outpatient to an Inpatient',
					value: 'A06',
				},
				{
					label: 'Change an Inpatient to an Outpatient',
					value: 'A07',
				},
				{
					label: 'Update',
					value: 'A08',
				},
				{
					label: 'Cancel Admit',
					value: 'A11',
				},
				{
					label: 'Cancel Discharge',
					value: 'A13',
				},
				{
					label: 'Ambulance Emergency Medical Services',
					value: 'EMS',
				},
			],
		},
		{
			path: 'patient.publisher.gender',
			name: 'Gender',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [
				{
					label: 'Male',
					value: 'M',
				},
				{
					label: 'Female',
					value: 'F',
				},
				{
					label: 'Unknown',
					value: 'U',
				},
				{
					label: 'Other',
					value: 'O',
				},
			],
		},
		{
			path: 'event.adtpatientclass',
			name: 'Patient Class',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: false,
			valueOptions: [
				{
					label: 'IP-Inpatient',
					value: 'I',
				},
				{
					label: 'OP-Outpatient',
					value: 'O',
				},
				{
					label: 'E-Emergency',
					value: 'E',
				},
				{
					label: 'AE-Ambulatory encounter',
					value: 'AE',
				},
				{
					label: 'OBS-Observation',
					value: 'OBS',
				},
			],
		},
		{
			path: 'patient.publisher.race',
			name: 'Race',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [
				{
					label: 'American Indian or Alaska Native',
					value: '1002-5',
				},
				{
					label: 'Asian',
					value: '2028-9',
				},
				{
					label: 'Black or African American',
					value: '2054-5',
				},
				{
					label: 'Native Hawaiian or Other Pacific Islander',
					value: '2076-8',
				},
				{
					label: 'White',
					value: '2106-3',
				},
				{
					label: 'Other Race',
					value: '2131-1',
				},
			],
		},
		{
			path: 'event.visit.diagnosis[0].diagnosiscode',
			name: 'Diagnosis Code',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'event.visit.diagnosis[0].diagnosisdescription',
			name: 'Diagnosis Description',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.pcpname',
			name: 'Primary Care Provider',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'event.visit.admitsource',
			name: 'Admit Source',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.caremanagername',
			name: 'Care Manager',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'event.visit.dischargedisposition',
			name: 'Discharge Disposition',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.insurance',
			name: 'Insurance',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.lastname',
			name: 'Last Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.firstname',
			name: 'First Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'publisher.name',
			name: 'Facility Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.practice',
			name: 'Practice',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'event.visit.admittime',
			name: 'Admit Date',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'event.date',
			name: 'Event Time',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'metadata.receiveddate',
			name: 'Received Time',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'event.visit.dischargetime',
			name: 'Discharge Date',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.dateofbirth',
			name: 'Date of Birth',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.dateofdeath',
			name: 'Date of Death',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.patientid',
			name: 'Sender MRN',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.deathindicator',
			name: 'Death Indicator',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [
				{
					label: 'Yes',
					value: 'y',
				},
				{
					label: 'No',
					value: 'n',
				},
			],
		},
		{
			path: 'task.statusCode',
			name: 'Task Status',
			type: 'number',
			allowMultipleValues: true,
			allowCustomValue: false,
			valueOptions: [
				{
					label: 'Not Started',
					value: '0',
				},
				{
					label: 'In Progress',
					value: '1',
				},
				{
					label: 'Completed',
					value: '2',
				},
			],
		},
		{
			path: 'patient.publisher.sourcecode',
			name: 'Sender Source Code',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.ethnicgroup',
			name: 'Ethnicity',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.addr1',
			name: 'Address 1',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.addr2',
			name: 'Address 2',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.city',
			name: 'City',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.state',
			name: 'State',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.zipcode',
			name: 'Zipcode',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.admittingprovidername',
			name: 'Admitting Provider Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.admittingproviderid',
			name: 'Admitting Provider ID',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.attendingprovidername',
			name: 'Attending Provider Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.attendingproviderid',
			name: 'Attending Provider ID',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.consultingprovidername',
			name: 'Consulting Provider Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.consultingproviderid',
			name: 'Consulting Provider ID',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.referringprovidername',
			name: 'Referring Provider Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.referringproviderid',
			name: 'Referring Provider ID',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.patientidentifiedprovidername',
			name: 'Patient Identified Provider Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.patientidentifiedproviderid',
			name: 'Patient Identified Provider ID',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.observationstatus',
			name: 'Observation Status',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [
				{
					label: 'Yes',
					value: 'y',
				},
				{
					label: 'No',
					value: 'n',
				},
			],
		},
		{
			path: 'patient.publisher.nonempaneledpatient',
			name: 'Non-empaneled Patient (CoP Alert)',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [
				{
					label: 'Yes',
					value: 'y',
				},
				{
					label: 'No',
					value: 'n',
				},
			],
		},
		{
			path: 'event.visit.dischargetolocation',
			name: 'Discharge Location',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'metadata.erdischargecount',
			name: 'Number of ER Visits',
			type: 'number',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'metadata.ipdischargecount',
			name: 'Number of IP Visits',
			type: 'number',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.homephone',
			name: 'Home Phone',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.workphone',
			name: 'Work Phone',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.cellphone',
			name: 'Cell Phone',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'event.visit.hospitalservice',
			name: 'Hospital Service',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.publisher.accountnumber',
			name: 'Publisher/ADT account number',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.accountnumber',
			name: 'Subscriber/Panel account number',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.sourcecode',
			name: 'Subscriber Source Code',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.careprogramname',
			name: 'Care Program Name',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.empanelmentdate',
			name: 'ENS Start Date',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.careprogramstartdate',
			name: 'Care Program Start Date',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.careprogramenddate',
			name: 'Care Program End Date',
			type: 'date',
			allowMultipleValues: false,
			allowCustomValue: false,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.caremanagerduration',
			name: 'Care Program Duration',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.caremanagerphone',
			name: 'Care Manager Phone',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.caremanageremail',
			name: 'Care Manager Email',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'patient.subscriber.directemail',
			name: 'Direct Email',
			type: 'text',
			allowMultipleValues: true,
			allowCustomValue: true,
			valueOptions: [],
		},
		{
			path: 'subscription.id',
			name: 'Subscription',
			type: 'number',
			allowMultipleValues: true,
			allowCustomValue: false,
			valueOptions: [
				{
					label: 'E Subscriber',
					value: '6',
				},
				{
					label: 'J Subscriber',
					value: '11',
				},
				{
					label: 'D Subscriber',
					value: '5',
				},
			],
		},
	]);
}

const items = [
	// First two are event history events
	{
		id: 1,
		messageType: 'ADT',
		eventDate: '2014-02-11T11:30:30',
		patientClass: 'E',
		eventType: 'A01',
		sourceName: 'A.facility',
		admitReason: 'A.patientComplaints',
		diagnosisCode: 'A.diagnosisCode',
		diagnosisDescription: '',
		observationStatus: 'A.observationStatus',
		dischargeDisposition: 'A.dischargeDisposition',
	},
	{
		id: 2,
		messageType: 'ADT',
		eventDate: '2014-02-11T11:30:30',
		patientClass: 'I',
		eventType: 'A03',
		sourceName: 'B.facility',
		admitReason: 'B.patientComplaints',
		diagnosisCode: 'B.diagnosisCode',
		diagnosisDescription: 'B.diagnosisDescription',
		observationStatus: 'B.observationStatus',
		dischargeDisposition: null,
	},
	// Document events are below
	{
		id: '2022-09-19--20--80453a79-8aaa-4336-8845-fa09098ac7f5',
		messageType: 'ARCHERDOC',
		name: `BAXTER Summary Note`,
		sourceFacility: 'FLHIE VAL Hospital',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. David Johnson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'FLHIE VAL',
		serviceStartTime: '2022-09-14T22:21:21.000Z',
		serviceStopTime: '2022-09-15T05:04:21.000Z',
	},
	{
		id: 'id-2',
		messageType: 'ARCHERDOC',
		name: `CCD 2`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-3',
		messageType: 'ARCHERDOC',
		name: `CCD 3`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-4',
		messageType: 'ARCHERDOC',
		name: `CCD 4`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-5',
		messageType: 'ARCHERDOC',
		name: `CCD 5`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-6',
		messageType: 'ARCHERDOC',
		name: `CCD 6`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-7',
		messageType: 'ARCHERDOC',
		name: `CCD 7`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-8',
		messageType: 'ARCHERDOC',
		name: `CCD 8`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-9',
		messageType: 'ARCHERDOC',
		name: `CCD 9`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-10',
		messageType: 'ARCHERDOC',
		name: `CCD 10`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
	{
		id: 'id-11',
		messageType: 'ARCHERDOC',
		name: `CCD 11`,
		sourceFacility: 'Test Facility',
		sourceDepartment: 'General Medicines',
		documentType: 'Summary of Episode Note',
		authorName: 'Dr. Bill Davidson',
		authorDetail: 'Primary Care Provider, General',
		authorInstitution: 'John Hopkins',
		serviceStartTime: '2022-07-05T11:15:17.000Z',
		serviceStopTime: null,
	},
];

export default function retrieveNotificationsMock({ resolve }) {
	setTimeout(() => {
		resolve(items);
	}, 1000);
}

// 					admitReason: !isNil(admitReason) ? admitReason : undefined,

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import chunk from 'lodash/chunk';
import ceil from 'lodash/ceil';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import values from 'lodash/values';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import AlertMessage from '@audacious/components/components/AlertMessage';
import Button from '@audacious/components/components/Button';
import Card from '@audacious/components/components/Card';
import { Row, Column } from '@audacious/components/components/Grid';
import TextInput from '@audacious/components/components/TextInput';
import {
	DataAccess,
	DataCheckProperty,
	DataDateProperty,
	DataTextProperty,
} from '@audacious/components/components/Data';
import { DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { Text } from '@audacious/components/components/Typography';
import Link from '@audacious/components/components/Link';
import searchFilter from '@audacious/web-common/utilities/searchFilter';

function getFieldColumns(configuration) {
	let leftColFieldConfig = null;
	let rightColFieldConfig = null;

	if (!isNil(configuration)) {
		const halfConfigLength = ceil(configuration.length / 2);
		[leftColFieldConfig = null, rightColFieldConfig = null] = chunk(
			configuration,
			halfConfigLength,
		);
	}

	return {
		leftColFieldConfig,
		rightColFieldConfig,
	};
}

function filterFields(configuration, fieldQuery) {
	return searchFilter(configuration, fieldQuery, [{ path: 'label' }]);
}

function getDateFilterLabel(orderBy) {
	return orderBy === 'receivedTime' ? 'Received Time' : 'Event Time';
}

class ExportOptions extends PureComponent {
	constructor(props) {
		super(props);

		const { configuration } = props;

		this.state = {
			// Date range filter max date is today and min date is 6 months ago
			availableDatesRange: {
				from: moment()
					.subtract(6, 'months')
					.toDate(),
				to: moment().toDate(),
			},
			alertDatesOpen: false,
			alertInfoOpen: false,
			prevDates: null,
			prevAlertInfo: null,
			fieldQuery: null,
			...getFieldColumns(configuration),
		};

		this.handleStartEditAlertDates = this.handleStartEditAlertDates.bind(
			this,
		);
		this.handleAlertDatesCancel = this.handleAlertDatesCancel.bind(this);
		this.handleAlertDatesDone = this.handleAlertDatesDone.bind(this);
		this.handleStartEditAlertInfo = this.handleStartEditAlertInfo.bind(
			this,
		);
		this.handleAlertInfoCancel = this.handleAlertInfoCancel.bind(this);
		this.handleAlertInfoDone = this.handleAlertInfoDone.bind(this);
		this.handleExportClick = this.handleExportClick.bind(this);
		this.renderEditButtons = this.renderEditButtons.bind(this);
		this.renderAlertDatesOpen = this.renderAlertDatesOpen.bind(this);
		this.renderAlertDatesClosed = this.renderAlertDatesClosed.bind(this);
		this.renderAlertInfoOpen = this.renderAlertInfoOpen.bind(this);
		this.renderAlertInfoClosed = this.renderAlertInfoClosed.bind(this);
		this.handleQueryChange = this.handleQueryChange.bind(this);
	}

	handleQueryChange(fieldQuery) {
		const { configuration } = this.props;

		this.setState({
			fieldQuery,
			...getFieldColumns(filterFields(configuration, fieldQuery)),
		});
	}

	handleExportClick() {
		const { dataRef } = this.props;
		dataRef.current.execute();
	}

	handleStartEditAlertDates() {
		const { dataRef } = this.props;
		const { dateRangeFrom, dateRangeTo } = dataRef.current.get();
		dataRef.current.validate();

		this.setState({
			alertDatesOpen: true,
			prevDates: { dateRangeFrom, dateRangeTo },
		});
	}

	handleAlertDatesCancel() {
		const { dataRef } = this.props;
		const {
			prevDates: { dateRangeFrom, dateRangeTo },
		} = this.state;

		dataRef.current.set(dateRangeFrom, 'dateRangeFrom');
		dataRef.current.set(dateRangeTo, 'dateRangeTo');

		this.setState({
			alertDatesOpen: false,
			prevDates: null,
		});
	}

	handleAlertDatesDone() {
		const { dataRef } = this.props;

		if (!dataRef.current.isValid()) {
			return;
		}

		this.setState({
			alertDatesOpen: false,
			prevDates: null,
		});
	}

	handleStartEditAlertInfo() {
		const { dataRef } = this.props;

		const { alertInfo } = dataRef.current.get();

		this.setState({
			alertInfoOpen: true,
			prevAlertInfo: cloneDeep(alertInfo),
		});
	}

	handleAlertInfoCancel() {
		const { dataRef } = this.props;
		const { prevAlertInfo } = this.state;

		dataRef.current.set(prevAlertInfo, 'alertInfo');

		this.setState({
			alertInfoOpen: false,
			prevAlertInfo: null,
		});
	}

	handleAlertInfoDone() {
		this.setState({
			alertInfoOpen: false,
			prevAlertInfo: null,
		});
	}

	// eslint-disable-next-line class-methods-use-this
	renderEditButtons(cancel, done) {
		return (
			<div className="export-edit-btn-container">
				<ButtonGroup>
					<Button
						id="exportEditDone"
						color="secondary"
						variant="fill"
						size="sm"
						onClick={done}
					>
						Done
					</Button>
					<Button
						id="exportEditCancel"
						color="secondary"
						variant="opaque"
						size="sm"
						onClick={cancel}
					>
						Cancel
					</Button>
				</ButtonGroup>
			</div>
		);
	}

	renderAlertDatesOpen() {
		const { orderBy } = this.props;
		const { availableDatesRange } = this.state;

		return (
			<>
				<div className="export-label editing">
					{getDateFilterLabel(orderBy)}
				</div>
				<DataDateProperty
					id="export-from"
					className="export-from"
					path="dateRangeFrom"
					required
					fromDate={availableDatesRange.from}
					toDate={availableDatesRange.to}
					validDate={[true, 'Date is invalid']}
					minDate={[true, 'Date out of max. range']}
					maxDate={[true, 'Date out of max. range']}
				/>
				<DataDateProperty
					id="export-to"
					className="export-to"
					path="dateRangeTo"
					required
					fromDate={availableDatesRange.from}
					toDate={availableDatesRange.to}
					validDate={[true, 'Date is invalid']}
					minDate={[true, 'Date out of max. range']}
					maxDate={[true, 'Date out of max. range']}
					attributes={{
						custom: {
							enabled: attributeContext => attributeContext,
							onValidate: (dateRangeAfter, attributeContext) => {
								const dateRangeFrom = attributeContext.get(
									'dateRangeFrom',
								);

								if (
									dateRangeAfter === null ||
									dateRangeFrom === null
								) {
									return null;
								}

								const toDate = new Date(dateRangeAfter);
								const toTime = toDate.getTime();

								if (isNaN(toTime)) {
									return null;
								}

								const fromDate = new Date(dateRangeFrom);
								const fromTime = fromDate.getTime();

								if (fromTime > toTime) {
									return 'End date must be later than start date';
								}

								return null;
							},
						},
					}}
				/>
				<div className="date-range-info">
					The date range must be within the last 6 months.
				</div>
				<hr />
				{this.renderEditButtons(
					this.handleAlertDatesCancel,
					this.handleAlertDatesDone,
				)}
			</>
		);
	}

	renderAlertDatesClosed() {
		const { exportTimeFilterApplied, orderBy } = this.props;

		const dateFilterLabel = getDateFilterLabel(orderBy);

		return (
			<DataAccess>
				{({
					property: {
						value: { dateRangeFrom, dateRangeTo },
					},
				}) =>
					exportTimeFilterApplied ? (
						<span className="export-label">
							{`The applied ${toLower(
								dateFilterLabel,
							)} filter(s) will determine the
                            alert ${toLower(dateFilterLabel)} range.`}
						</span>
					) : (
						<>
							<span className="export-label">
								{dateFilterLabel}
							</span>
							<span className="export-label pull-right">
								{`${dateRangeFrom} - ${dateRangeTo}`}
							</span>
						</>
					)
				}
			</DataAccess>
		);
	}

	// eslint-disable-next-line class-methods-use-this
	renderCheck(config) {
		return (
			<div key={config.dataPath}>
				<DataCheckProperty
					id={config.dataPath}
					size="sm"
					color="secondary"
					path={config.dataPath}
					label={config.label}
					disabled={!config.enabled}
				/>
			</div>
		);
	}

	renderAlertInfoOpen() {
		const {
			leftColFieldConfig,
			rightColFieldConfig,
			fieldQuery,
		} = this.state;

		return (
			<>
				<Row gutter="16">
					<Column width={[null, '6']}>
						<span className="export-label">
							* Fields are required for export.
						</span>
					</Column>
					<Column width={[null, '6']}>
						<TextInput
							id="fieldSearch"
							placeholder="Search filter Data"
							leftIcon={{
								name: faMagnifyingGlass,
							}}
							value={fieldQuery}
							onChange={this.handleQueryChange}
						/>
					</Column>
				</Row>
				<Row className="export-fields-row">
					<Column
						width={[null, '6']}
						className="export-fields-column"
					>
						{map(leftColFieldConfig, fieldConfig =>
							this.renderCheck(fieldConfig),
						)}
					</Column>
					<Column
						width={[null, '6']}
						className="export-fields-column"
					>
						{map(rightColFieldConfig, fieldConfig =>
							this.renderCheck(fieldConfig),
						)}
					</Column>
				</Row>
				<hr />
				{this.renderEditButtons(
					this.handleAlertInfoCancel,
					this.handleAlertInfoDone,
				)}
			</>
		);
	}

	// eslint-disable-next-line class-methods-use-this
	renderAlertInfoClosed() {
		return (
			<DataAccess>
				{({
					property: {
						value: { alertInfo },
					},
				}) => {
					let numSelected = 0;
					const fieldValues = values(alertInfo);

					forEach(fieldValues, fieldValue => {
						if (fieldValue === true) {
							numSelected += 1;
						}
					});

					return (
						<>
							<span className="export-label">Event Details</span>
							<span className="export-label pull-right">
								{numSelected === fieldValues.length
									? `All (${numSelected} items)`
									: `${numSelected} / ${fieldValues.length} items`}
							</span>
						</>
					);
				}}
			</DataAccess>
		);
	}

	renderDatesCard() {
		const { exportTimeFilterApplied } = this.props;

		const { alertInfoOpen, alertDatesOpen } = this.state;

		if (alertInfoOpen) {
			return null;
		}

		return (
			<Card
				className="edit-card"
				size="xs"
				color={exportTimeFilterApplied ? 'grey' : null}
				onClick={
					alertDatesOpen || exportTimeFilterApplied
						? null
						: this.handleStartEditAlertDates
				}
			>
				{alertDatesOpen
					? this.renderAlertDatesOpen()
					: this.renderAlertDatesClosed()}
			</Card>
		);
	}

	renderAlertsCard() {
		const { configuration } = this.props;
		const { alertInfoOpen, alertDatesOpen } = this.state;

		if (alertDatesOpen) {
			return null;
		}

		return (
			<Card
				className="edit-card"
				size="xs"
				onClick={
					alertInfoOpen || !configuration
						? null
						: this.handleStartEditAlertInfo
				}
			>
				{alertInfoOpen
					? this.renderAlertInfoOpen()
					: this.renderAlertInfoClosed()}
			</Card>
		);
	}

	render() {
		const {
			dataRef,
			exportOptions,
			configuration,
			isLoading,
			maxSize,
			handleViewAcknowledgement,
			handleCancelClick,
		} = this.props;
		const { alertInfoOpen, alertDatesOpen } = this.state;

		if (
			dataRef?.current &&
			dataRef.current.get('alertInfo') === null &&
			configuration !== null
		) {
			// Set alert info in Data once export config is retrieved
			dataRef.current.set(exportOptions.alertInfo, 'alertInfo');
		}

		return (
			<>
				<DocumentBody className="export-start-modal-body export-options">
					<div className="file-name-container">
						<span className="export-label file-name-label">
							File Name
						</span>
						<DataTextProperty
							id="fileName"
							className="file-name"
							size="sm"
							path="fileName"
							required
							minLength={[3, 'Minimum 3 characters required.']}
							maxLength={[50, 'Maximum 50 characters.']}
						/>
					</div>
					{alertInfoOpen ||
					alertDatesOpen ||
					maxSize === null ? null : (
						<AlertMessage
							className="export-limit-message"
							color="warn"
							message={`An export file is limited to the first ${maxSize} records`}
							size="xs"
						/>
					)}
					{this.renderDatesCard()}
					{this.renderAlertsCard()}
					<div className="acknowledged-text">
						<Text size="md">
							You have agreed to{' '}
							<Link onClick={handleViewAcknowledgement}>
								the terms
							</Link>{' '}
							of downloading protected patient information.{' '}
						</Text>
						<Text size="md" weight="bold">
							You must use an approved device to download this
							file.
						</Text>
					</div>
				</DocumentBody>
				{alertInfoOpen || alertDatesOpen ? null : (
					<DocumentFooter sticky={false}>
						<ButtonGroup>
							<Button
								id="export-export-btn"
								color="primary"
								variant="fill"
								disabled={isLoading || isNil(configuration)}
								onClick={this.handleExportClick}
							>
								Export
							</Button>
							<Button
								id="cancel-export-btn"
								color="primary"
								variant="opaque"
								onClick={handleCancelClick}
							>
								Cancel
							</Button>
						</ButtonGroup>
					</DocumentFooter>
				)}
			</>
		);
	}
}

ExportOptions.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	exportOptions: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	configuration: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	dataRef: PropTypes.object.isRequired,
	exportTimeFilterApplied: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	orderBy: PropTypes.string.isRequired,
	handleViewAcknowledgement: PropTypes.func.isRequired,
	handleCancelClick: PropTypes.func.isRequired,
	maxSize: PropTypes.number,
};

ExportOptions.defaultProps = {
	exportOptions: null,
	maxSize: null,
};

export default ExportOptions;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { Container } from '@audacious/components/components/Grid';
import Data, {
	DataTextProperty,
} from '@audacious/components/components/Data';
import {
	filterConfigPropType,
	filterDataPropType,
} from '../../../../common/app-prop-types';
import { updateFilter } from '../../../../actions/filter-actions';
import FilterProperties from '../../../filter-properties';
import {
	loadFilterData,
	convertFilterDataFromMeta,
	getAvailableFilters,
} from '../../../../common/filter-utils';

function handleExecuteStart(_, results) {
	return isEmpty(results);
}

class EditFilterForm extends Component {
	constructor(props) {
		super(props);

		this.formRef = createRef();

		this.handleExecute = this.handleExecute.bind(this);
		this.handleAvailableUpdateFilters = this.handleAvailableUpdateFilters.bind(
			this,
		);

		const { filterConfig, initialData } = this.props;

		const { lookup } = filterConfig;

		this.state = {
			...getAvailableFilters(filterConfig, initialData.expressions),
			initialFilterData: loadFilterData(initialData, lookup),
		};
	}

	handleAvailableUpdateFilters() {
		const { filterConfig } = this.props;

		this.setState({
			...getAvailableFilters(
				filterConfig,
				this.formRef.current.get('expressions'),
			),
		});
	}

	handleExecute(filterData) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		const convertedData = convertFilterDataFromMeta(filterData);

		executeAction(updateFilter, convertedData);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	next() {
		this.formRef.current.execute();
	}

	renderFormContents() {
		const { filterConfig } = this.props;

		const { availableFilters, availableOperatorsLookup } = this.state;

		return (
			<>
				<Container gutter="16">
					<DataTextProperty
						id="edit-filter-name-btn"
						path="name"
						required={[true, 'Please enter a valid Name']}
						minLength={[3, 'Minimum 3 characters required']}
					/>
				</Container>
				<FilterProperties
					filterConfig={filterConfig}
					availableFilters={availableFilters}
					availableOperatorsLookup={availableOperatorsLookup}
					onUpdateAvailableFilters={this.handleAvailableUpdateFilters}
				/>
			</>
		);
	}

	render() {
		const { initialFilterData } = this.state;

		return (
			<Data
				ref={this.formRef}
				baseValue={initialFilterData}
				onExecuteStart={handleExecuteStart}
				onExecute={this.handleExecute}
				validateOnBlur
				validateOnExecute
			>
				{this.renderFormContents()}
			</Data>
		);
	}
}

EditFilterForm.propTypes = {
	filterConfig: filterConfigPropType.isRequired,
	initialData: filterDataPropType.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

EditFilterForm.defaultProps = {};

export default applyFluxibleContext(EditFilterForm);

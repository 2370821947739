import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Popover from '@audacious/components/components/Popover';
import Menu from '@audacious/components/components/Menu';
import { AnchorChip , AnchorIconButton } from '@audacious/components/components/DropDown';
import { Text } from '@audacious/components/components/Typography';
import { faCircleInfo } from '@audacious/icons/regular/faCircleInfo';
import SingleSavedFilter from './single-saved-filter';
import MultipleSavedFilters from './multiple-saved-filters';
import { toggleSavedFilter } from '../../../../actions/filter-actions';
import {
	duplicateFilters,
	disabledFilters,
} from '../../../../common/static-text';
import FilterLabel from './filter-label';

class SavedFilterMenu extends Component {
	constructor(props) {
		super(props);

		this.renderFilter = this.renderFilter.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
	}

	handleToggle(filterId, valueIndex, enabled) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(toggleSavedFilter, {
			filterId,
			valueIndex,
			enabled,
		});
	}

	// eslint-disable-next-line class-methods-use-this
	renderFilter(filter) {
		const {
			id,
			name,
			valueData,
			totalEnabled,
			isOverridden,
			operator,
		} = filter;

		if (valueData.length > 1) {
			return (
				<MultipleSavedFilters
					key={id}
					id={id}
					filterId={id}
					name={name}
					values={valueData}
					onToggle={this.handleToggle}
					totalEnabled={totalEnabled}
					isOverridden={isOverridden}
					operator={operator}
				/>
			);
		}
		if (valueData.length === 1) {
			const { displayValue, enabled, overridden } = valueData[0];

			return (
				<SingleSavedFilter
					key={id}
					id={id}
					label={
						<span>
							<FilterLabel
								name={name}
								operator={operator}
								value={displayValue}
							/>
						</span>
					}
					enabled={enabled}
					overridden={overridden}
					filterId={id}
					valueIndex={0}
					onToggle={this.handleToggle}
				/>
			);
		}

		throw new Error('This filter Should not exist');
	}

	renderPopOver() {
		const { filterGroup } = this.props;

		const hasOverridden = filterGroup.hasOverridden();
		const hasDisabled = filterGroup.hasDisabled();

		if (!hasDisabled && !hasOverridden) {
			return null;
		}

		const message = hasOverridden ? duplicateFilters : disabledFilters;

		return (
			<Popover
				anchor={AnchorIconButton}
				anchorProps={{
					className: 'saved-filter-message',
					size: 'xs',
					color: 'info',
					variant: 'none',
					icon: faCircleInfo,
				}}
				maxDrawerWidth={230}
			>
				<Text weight="normal" size="md">
					{message}
				</Text>
			</Popover>
		);
	}

	render() {
		const { filterGroup } = this.props;

		const name = filterGroup.getName();
		const filters = filterGroup.getFilters();

		return (
			<>
				<Menu
					id="saved-menu"
					className="saved-filter-drop-down"
					Anchor={AnchorChip}
					anchorProps={{
						variant: 'pill',
						color: 'cyan',
						shade: 'dark',
						size: 'lg',
						children: name,
					}}
				>
					{map(filters, this.renderFilter)}
				</Menu>
				{this.renderPopOver()}
			</>
		);
	}
}

SavedFilterMenu.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	filterGroup: PropTypes.any.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

SavedFilterMenu.defaultProps = {};

export default applyFluxibleContext(SavedFilterMenu);

/* eslint-disable no-underscore-dangle */
import endsWith from 'lodash/endsWith';
import map from 'lodash/map';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import clone from 'lodash/clone';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';

const dateSubOperatorLabeling = {
	today: 'Today',
	yesterday: 'Yesterday',
	exact: 'Exact Date',
	exactTime: 'Exact Date & Time',
	exactPeriod: 'Exact Period',
	exactPeriodTime: 'Exact Period & Time',
	currentWeek: 'Current Week',
	currentMonth: 'Current Month',
	hoursPast: '# of hours ago',
	hoursFuture: '# of hours from now',
	daysPast: '# of days ago',
	daysFuture: '# of days from now',
	monthsPast: '# of months ago',
	monthsFuture: '# of months from now',
};

const dateSubOperatorLookup = {
	today: 'text',
	yesterday: 'text',
	exact: 'date',
	exactTime: 'dateTime',
	currentWeek: 'text',
	currentMonth: 'text',
	hoursPast: 'hours',
	hoursFuture: 'hours',
	daysPast: 'days',
	daysFuture: 'days',
	monthsPast: 'months',
	monthsFuture: 'months',
	exactPeriod: 'dateRange',
	exactPeriodTime: 'dateTimeRange',
};

const customTextValueOperators = ['contains', 'notcontains', 'beginswith'];

export const numberOperators = [
	'lessthan',
	'lessthanorequal',
	'greaterthan',
	'greaterthanorequal',
];

function buildDateSubOperatorOption(key) {
	return {
		key,
		text: dateSubOperatorLabeling[key],
	};
}

const dateSubOperatorValues = {
	isempty: [],
	dateequals: [
		buildDateSubOperatorOption('today'),
		buildDateSubOperatorOption('yesterday'),
		buildDateSubOperatorOption('exact'),
	],
	datebefore: [
		buildDateSubOperatorOption('today'),
		buildDateSubOperatorOption('currentWeek'),
		buildDateSubOperatorOption('currentMonth'),
		buildDateSubOperatorOption('daysPast'),
		buildDateSubOperatorOption('exact'),
	],
	dateafter: [
		buildDateSubOperatorOption('today'),
		buildDateSubOperatorOption('yesterday'),
		buildDateSubOperatorOption('currentWeek'),
		buildDateSubOperatorOption('currentMonth'),
		buildDateSubOperatorOption('daysPast'),
		buildDateSubOperatorOption('daysFuture'),
		buildDateSubOperatorOption('exact'),
	],
	datewithin: [
		// buildDateSubOperatorOption('currentWeek'),
		// buildDateSubOperatorOption('currentMonth'),
		buildDateSubOperatorOption('hoursPast'),
		buildDateSubOperatorOption('daysPast'),
		buildDateSubOperatorOption('monthsPast'),
		buildDateSubOperatorOption('exactPeriod'),
	],
};

const dateTimeSubOperatorValues = {
	isempty: [],
	dateequals: [],
	datebefore: [buildDateSubOperatorOption('exactTime')],
	dateafter: [buildDateSubOperatorOption('exactTime')],
	datewithin: [buildDateSubOperatorOption('exactPeriodTime')],
};

export function getDateSubOperatorType(subOperator) {
	if (isNil(subOperator)) {
		return 'text';
	}

	return dateSubOperatorLookup[subOperator];
}

export function getDateSubOperators(operator, filterDefinition) {
	let subOperators = dateSubOperatorValues[operator];

	if (!filterDefinition.ignoreTime) {
		subOperators = [
			...subOperators,
			...dateTimeSubOperatorValues[operator],
		];
	}

	return subOperators;
}

export function calculateDateValue(subOperator, value) {
	if (isNil(subOperator)) {
		return null;
	}

	const type = getDateSubOperatorType(subOperator);

	if (type === 'text') {
		return subOperator;
	}

	if (type === 'number' && endsWith(subOperator, 'past')) {
		return value * -1;
	}

	return value;
}

export function getDateNumericSubOperator(valueType, value) {
	const direction = value < 0 ? 'Past' : 'Future';

	return `${valueType}${direction}`;
}

function getDateParts(value) {
	if (value.length === 10) {
		return [value, '00:00:00'];
	}

	const parsed = moment(value);

	return [parsed.format('MM/DD/YYYY'), parsed.format('HH:mm:ss')];
}

export function calculateMetaFromValue(value, valueType, definitionValueType) {
	if (definitionValueType !== 'date') {
		return {
			subOperator: null,
			value,
		};
	}

	let subOperator = null;
	let newValue = value;

	if (valueType === 'text') {
		subOperator = value;
	} else if (valueType === 'date') {
		subOperator = 'exact';

		// Get Date
		[newValue] = getDateParts(value);
	} else if (valueType === 'dateTime') {
		subOperator = 'exactTime';

		// Get Date and Time
		newValue = getDateParts(value);
	} else if (valueType === 'dateRange') {
		subOperator = 'exactPeriod';

		// Get Date and Date
		newValue = [getDateParts(value[0])[0], getDateParts(value[1])[0]];
	} else if (valueType === 'dateTimeRange') {
		subOperator = 'exactPeriodTime';

		// Get Date and Time, and Date and Time
		newValue = [...getDateParts(value[0]), ...getDateParts(value[1])];
	} else {
		subOperator = getDateNumericSubOperator(valueType, value);

		newValue = Math.abs(value);
	}

	return {
		subOperator,
		value: newValue,
	};
}

function convertToISO(date, time) {
	const finalTime = !isNil(time) ? time : '00:00:00';
	const finalDate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');

	return `${finalDate}T${finalTime}`;
}

export function convertExpressionsFromMeta(expressions) {
	return map(expressions, expression => {
		const { meta, value, ...rest } = expression;

		let newValue = value;

		if (!isNil(meta) && !isNil(meta.subOperator)) {
			if (meta.subOperator === 'exact') {
				newValue = convertToISO(newValue);
			} else if (meta.subOperator === 'exactTime') {
				newValue = convertToISO(newValue[0], newValue[1]);
			} else if (meta.subOperator === 'exactPeriod') {
				newValue = [
					convertToISO(newValue[0]),
					convertToISO(newValue[1]),
				];
			} else if (meta.subOperator === 'exactPeriodTime') {
				newValue = [
					convertToISO(newValue[0], newValue[1]),
					convertToISO(newValue[2], newValue[3]),
				];
			} else if (endsWith(meta.subOperator, 'Past')) {
				newValue *= -1;
			}
		}

		if (isNil(newValue)) {
			newValue = '';
		}

		return {
			...rest,
			value: newValue,
		};
	});
}

export function convertFilterDataFromMeta(filterData) {
	const ret = {
		name: filterData.name,
		_id: filterData._id,
		expressions: convertExpressionsFromMeta(filterData.expressions),
	};

	return ret;
}

export function convertExpressionsToMeta(expressions, lookup) {
	return reduce(
		expressions,
		(acc, expression) => {
			const filterDefinition = lookup[expression.var];

			if (!isNil(filterDefinition)) {
				const { subOperator, value } = calculateMetaFromValue(
					expression.value,
					expression.valueType,
					filterDefinition.type,
				);

				acc.push({
					...expression,
					value,
					meta: {
						subOperator,
					},
				});
			}

			return acc;
		},
		[],
	);
}

export function loadFilterData(filterData, lookup) {
	const convertedExpressions = convertExpressionsToMeta(
		filterData.expressions,
		lookup,
	);

	const ret = {
		name: filterData.name,
		_id: filterData._id,
		expressions:
			!isNil(convertedExpressions) && convertedExpressions.length > 0
				? convertedExpressions
				: [{}],
	};

	return ret;
}

function getDateValue(value, hasTime) {
	if (value.length <= 10) {
		return value;
	}

	const parsed = moment(value);

	if (hasTime) {
		const hour = parsed.format('HH');

		const meridian = hour < 12 ? 'a.m.' : 'p.m.';

		return `${parsed.format('MM/DD/YYYY hh:mm')} ${meridian}`;
	}

	return parsed.format('MM/DD/YYYY');
}

export function getDateDisplayValue(valueType, value) {
	if (valueType === 'text') {
		return dateSubOperatorLabeling[value];
	}

	if (valueType === 'date') {
		return getDateValue(value, false);
	}

	if (valueType === 'dateTime') {
		return getDateValue(value, true);
	}

	if (valueType === 'dateRange') {
		return `${getDateValue(value[0], false)} - ${getDateValue(
			value[1],
			false,
		)}`;
	}

	if (valueType === 'dateTimeRange') {
		return `${getDateValue(value[0], true)} - ${getDateValue(
			value[1],
			true,
		)}`;
	}

	const subOperator = getDateNumericSubOperator(valueType, value);

	const labeling = dateSubOperatorLabeling[subOperator];

	return replace(labeling, '# of', Math.abs(value));
}

export function roundDateTime(operator, subOperator) {
	if (operator === 'isempty') {
		return 'none';
	}

	if (
		subOperator === 'hoursFuture' ||
		subOperator === 'daysFuture' ||
		subOperator === 'monthsFuture'
	) {
		return 'none';
	}

	if (operator === 'datewithin') {
		if (
			subOperator === 'hoursPast' ||
			subOperator === 'daysPast' ||
			subOperator === 'monthsPast'
		) {
			return ['none', 'none'];
		}

		return ['down', 'up'];
	}

	return 'down';
}

function getUsedMetaData(filters, lookup) {
	const usedExclusionSetPathIds = {};

	const usedFilters = reduce(
		filters,
		(acc, filterItem) => {
			if (isNil(filterItem.var)) {
				return acc;
			}

			const filterDefinition = lookup[filterItem.var];

			if (isNil(filterDefinition)) {
				return acc;
			}

			forEach(filterDefinition.exclusionSet, exclusionSet => {
				usedExclusionSetPathIds[exclusionSet] = filterItem.var;
			});

			set(acc, [filterItem.var, filterItem.op], true);

			return acc;
		},
		{},
	);

	return {
		usedExclusionSetPathIds,
		usedFilters,
	};
}

function getAvailableOperators(usedFilters, filterDefinition) {
	const usedFilterType = usedFilters[filterDefinition.path];

	if (isNil(usedFilterType)) {
		return filterDefinition.operators;
	}

	return reduce(
		filterDefinition.operators,
		(accOp, operator) => {
			if (
				filterDefinition.type === 'number' &&
				!isEmpty(filterDefinition.valueOptions) &&
				includes(numberOperators, operator)
			) {
				// Don't show <, <=, > and >= operators for fields that have value options
				return accOp;
			}

			if (
				filterDefinition.type === 'text' &&
				filterDefinition.allowCustomValue !== true &&
				includes(customTextValueOperators, operator)
			) {
				// The "Contains", "Does Not Contain" and "Begins With" operators don't make sense
				// to use with values that can only be selected from a predefiend list, so don't
				// allow these operators when the user can't select a custom filter values
				return accOp;
			}

			if (!usedFilterType[operator]) {
				accOp.push(operator);
			}

			return accOp;
		},
		[],
	);
}

export function getAvailableFilters({ list, lookup }, filters) {
	const { usedExclusionSetPathIds, usedFilters } = getUsedMetaData(
		filters,
		lookup,
	);

	const availableOperatorsLookup = {};
	const usedExclusionKeys = keys(usedExclusionSetPathIds);

	const availableFilters = reduce(
		list,
		(acc, filterDefinition) => {
			const matchingExclusionSets = intersection(
				filterDefinition.exclusionSet,
				usedExclusionKeys,
			);

			const availableOperators = getAvailableOperators(
				usedFilters,
				filterDefinition,
			);

			// If all operators are being used then the user cannot be allowed to add another one of it.
			if (isNil(availableOperators) || availableOperators.length <= 0) {
				return acc;
			}

			if (matchingExclusionSets.length <= 0) {
				// If this filter does not match any used exclusion set
				// then add it to the basic list
				acc.basic.push(filterDefinition.path);

				// Then update existing targeted available filters with this item.
				forEach(acc.targeted, targetedSet => {
					targetedSet.push(filterDefinition.path);
				});
			} else {
				// If there are matching seclusion sets
				// then loop over them and add any unused filters which match it so
				// that the user can change to them from the one being used.
				forEach(matchingExclusionSets, exclusionSet => {
					// Only do this not a filter which matched the used set but is not the one being used.
					if (
						usedExclusionSetPathIds[exclusionSet] !==
						filterDefinition.path
					) {
						// eslint-disable-next-line no-param-reassign
						acc.targeted[exclusionSet] =
							acc.targeted[exclusionSet] ?? clone(acc.basic);

						acc.targeted[exclusionSet].push(filterDefinition.path);
					}
				});
			}

			set(
				availableOperatorsLookup,
				[filterDefinition.path],
				availableOperators,
			);

			return acc;
		},
		{
			targeted: {},
			basic: [],
		},
	);

	// Change targeted from { setKey: [filters] } to { pathKey: [filters] }
	availableFilters.targeted = mapKeys(
		availableFilters.targeted,
		(targetFilters, setId) => usedExclusionSetPathIds[setId],
	);

	return {
		availableFilters,
		availableOperatorsLookup,
	};
}

import merge from 'lodash/merge';
import map from 'lodash/map';
import { setDefaultSubscription } from '@audacious/cca-components/services';

import endpoints from '../configs/endpoints';
import retrieveSettings from './settings/retrieve-settings';
import retrieveNotifications from './notifications/retrieve-notifications';
import retrieveNotificationDetails from './notifications/retrieve-notification-details';
import retrieveNotificationInitialCount from './notifications/retrieve-notification-initial-count';
import retrieveNotificationThresholdState from './notifications/retrieve-notification-threshold-state';
import retrieveNotificationCount from './notifications/retrieve-notification-count';
import updateTask from './notifications/update-task';
import retrieveFilterConfig from './filters/retrieve-filter-config';
import retrieveFilters from './filters/retrieve-filters';
import retrieveFilter from './filters/retrieve-filter';
import createFilter from './filters/create-filter';
import updateFilter from './filters/update-filter';
import deleteFilter from './filters/delete-filter';
import retrieveQuickFilters from './filters/retrieve-quick-filters';
import retrieveExportConfig from './export/retrieve-export-config';
import startExport from './export/start-export';
import getExportTask from './export/get-export-task';
import downloadExportedFile from './export/download-exported-file';
import deleteExportedFile from './export/delete-exported-file';
import retrieveEvents from './events/retrieve-events';
import retrieveDocument from './documents/retrieve-document';
import retrieveCareTeams from './patient-summary/retrieve-care-teams';
import retrievePatientDetails from './patient-summary/retrieve-patient-details';
import retrieveCareNotes from './care-notes/retrieve-care-notes';
import createCareNote from './care-notes/create-care-note';
import updateCareNote from './care-notes/update-care-note';
import deleteCareNote from './care-notes/delete-care-note';

const DEFAULT_CONFIG = {
	endpoints,
};

export default config => {
	const configuration = merge(DEFAULT_CONFIG, config);

	const serviceConfigs = [
		retrieveSettings(configuration),
		retrieveNotifications(configuration),
		retrieveNotificationDetails(configuration),
		retrieveNotificationInitialCount(configuration),
		retrieveNotificationThresholdState(configuration),
		retrieveNotificationCount(configuration),
		retrieveExportConfig(configuration),
		retrieveFilterConfig(configuration),
		retrieveFilters(configuration),
		retrieveFilter(configuration),
		createFilter(configuration),
		updateFilter(configuration),
		deleteFilter(configuration),
		retrieveQuickFilters(configuration),
		startExport(configuration),
		getExportTask(configuration),
		downloadExportedFile(configuration),
		deleteExportedFile(configuration),
		updateTask(configuration),
		retrieveEvents(configuration),
		retrieveDocument(configuration),
		retrieveCareTeams(configuration),
		retrievePatientDetails(configuration),
		retrieveCareNotes(configuration),
		createCareNote(configuration),
		updateCareNote(configuration),
		deleteCareNote(configuration),
		setDefaultSubscription(configuration),
	];

	const baseUrlOverride = process.env.BASE_URL_OVERRIDE;

	if (baseUrlOverride) {
		return map(serviceConfigs, serviceConfig => ({
			...serviceConfig,
			config: {
				...serviceConfig.config,
				baseUrl: baseUrlOverride,
			},
		}));
	}

	return serviceConfigs;
};

/* eslint-disable no-underscore-dangle */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Drawer from '@audacious/components/components/Drawer';
import Button from '@audacious/components/components/Button';
import Document, { DocumentHeader, DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { Heading } from '@audacious/components/components/Typography';
import NewFilterForm from './new-filter-form';
import {
	filterDataPropType,
	filterConfigPropType,
} from '../../../../common/app-prop-types';
import { closeCurrentFilterDrawer } from '../../../../actions/filter-actions';
import DrawerTypes from '../../../../common/drawer-types';
import './new-filter-drawer.scss';

class NewFilterDrawer extends Component {
	constructor(props) {
		super(props);

		this.formRef = createRef();

		this.handleSave = this.handleSave.bind(this);
		this.handleApply = this.handleApply.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleSaveCommit = this.handleSaveCommit.bind(this);
		this.handleSaveCancel = this.handleSaveCancel.bind(this);
		this.handleNextStep = this.handleNextStep.bind(this);

		this.state = {
			willSaveFilter: false,
		};
	}

	static getDerivedStateFromProps(nextProps, currentState) {
		const { drawerId } = nextProps;

		const { willSaveFilter } = currentState;

		if (drawerId !== DrawerTypes.CREATE && willSaveFilter) {
			return {
				willSaveFilter: false,
			};
		}

		return null;
	}

	handleSave() {
		this.formRef.current.next(false);
	}

	handleApply() {
		this.formRef.current.next(true);
	}

	handleClose() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeCurrentFilterDrawer);
	}

	handleSaveCommit() {
		this.formRef.current.next(true);
	}

	handleSaveCancel() {
		this.setState({
			willSaveFilter: false,
		});
	}

	handleNextStep() {
		this.setState({
			willSaveFilter: true,
		});
	}

	renderBodyContent() {
		const { filterConfig, initialData } = this.props;

		const { willSaveFilter } = this.state;

		if (filterConfig instanceof Error) {
			return <div>Failed to load filter configuration</div>;
		}

		if (initialData instanceof Error) {
			return <div>Failed to load filter</div>;
		}

		return (
			<NewFilterForm
				ref={this.formRef}
				filterConfig={filterConfig}
				initialData={initialData}
				willSaveFilter={willSaveFilter}
				onNextStep={this.handleNextStep}
			/>
		);
	}

	renderPropertiesFooter() {
		const { saveOnly } = this.props;

		const applyButton = !saveOnly ? (
			<Button
				id="apply-new-filter-btn"
				color="primary"
				variant="fill"
				onClick={this.handleApply}
			>
				Apply
			</Button>
		) : null;

		return (
			<ButtonGroup>
				{applyButton}
				<Button
					id="save-new-filter-btn"
					color="primary"
					variant={!saveOnly ? 'outline' : 'fill'}
					onClick={this.handleSave}
				>
					Save
				</Button>
				<Button
					id="cancel-new-filter-btn"
					className={classnames({
						'pull-right': !saveOnly,
					})}
					color="primary"
					variant="opaque"
					onClick={this.handleClose}
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	}

	renderSaveFooter() {
		return (
			<ButtonGroup>
				<Button
					id="commit-save-new-filter-btn"
					color="primary"
					variant="fill"
					onClick={this.handleSaveCommit}
				>
					Save
				</Button>
				<Button
					id="cancel-save-new-filter-btn"
					color="primary"
					variant="opaque"
					onClick={this.handleSaveCancel}
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	}

	render() {
		const { drawerId, filterConfig, initialData, isSaving } = this.props;

		const { willSaveFilter } = this.state;

		if (drawerId !== DrawerTypes.CREATE) {
			return null;
		}

		let footer = null;

		if (willSaveFilter) {
			footer = this.renderSaveFooter();
		} else {
			footer = this.renderPropertiesFooter();
		}

		return (
			<Drawer
				id="new-filter-drawer"
				open
				variant="right"
				size="lg"
				isLoading={isNil(filterConfig) || isNil(initialData)}
				isSaving={isSaving}
			>
				<Document size="lg">
					<DocumentHeader enableDivider>
						<Heading level="5">New Filter</Heading>
					</DocumentHeader>
					<DocumentBody className="filter-drawer-body">
						{this.renderBodyContent()}
					</DocumentBody>
					<DocumentFooter>{footer}</DocumentFooter>
				</Document>
			</Drawer>
		);
	}
};

NewFilterDrawer.propTypes = {
	isSaving: PropTypes.bool.isRequired,
	drawerId: PropTypes.string,
	saveOnly: PropTypes.bool,
	filterConfig: filterConfigPropType,
	initialData: filterDataPropType,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

NewFilterDrawer.defaultProps = {
	filterConfig: null,
	saveOnly: false,
	initialData: null,
	drawerId: null,
};

export default connectToStores(
	applyFluxibleContext(NewFilterDrawer),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		const currentFilterDrawer = filterStore.getCurrentDrawer();

		return {
			isSaving: filterStore.isSaving(),
			filterConfig: filterStore.getConfiguration(),
			drawerId: get(currentFilterDrawer, 'drawerId'),
			saveOnly: get(currentFilterDrawer, 'data.saveOnly'),
			initialData: get(currentFilterDrawer, 'data.filterData'),
		};
	},
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Popover from '@audacious/components/components/Popover';
import { faCirclePlus } from '@audacious/icons/solid/faCirclePlus';
import { faCircleXmark } from '@audacious/icons/duotone/faCircleXmark';
import { faCircleInfo } from '@audacious/icons/regular/faCircleInfo';
import { faEllipsis } from '@audacious/icons/regular/faEllipsis';
import Menu, { MenuButton } from '@audacious/components/components/Menu';
import { Text } from '@audacious/components/components/Typography';
import Button from '@audacious/components/components/Button';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Chip from '@audacious/components/components/Chip';
import Spinner from '@audacious/components/components/Spinner';
import { SimpleTable, Cell } from '@audacious/components/components/Table';
import TableMessage from '@audacious/components/components/TableMessage';
import Icon from '@audacious/components/components/Icon';
import { AnchorIconButton } from '@audacious/components/components/DropDown';
import { Column, Row } from '@audacious/components/components/Grid';
import IconButton from '@audacious/components/components/IconButton';
import {
	startDeleteNoteInline,
	unsetNoteToBeDeleted,
} from '../../../actions/notification-details-actions';

const careNotesInstructions =
	'You can only modify or delete the Care Note authored by you. The edit features are turned off for entries created by other users.';

function preventDefault(event) {
	event.preventDefault();
}
class CareNoteTable extends PureComponent {
	constructor(props) {
		super(props);

		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleEditNote = this.handleEditNote.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.handleFinalizeDelete = this.handleFinalizeDelete.bind(this);
		this.handleCancelDelete = this.handleCancelDelete.bind(this);

		this.state = {
			opened: null,
		};

		this.columns = [
			{
				headerBody: 'Date',
				width: '180px',
				cellValuePath: ['date', 'age'],
				renderCell: ([date, age], { id, deleted }) => {
					const { noteToDelete } = this.props;

					const disabledRow =
						(noteToDelete && noteToDelete.id === id) || deleted;

					return (
						<div className="care-note-table-date">
							<Text
								className={
									disabledRow
										? 'note-date care-deleted'
										: 'note-date'
								}
								weight="normal"
								size="lg"
							>
								{date}
							</Text>
							{!deleted && (
								<Chip
									disabled={
										noteToDelete && noteToDelete.id === id
									}
									className="note-age"
									color="purple"
									size="sm"
								>
									{age}
								</Chip>
							)}
						</div>
					);
				},
			},
			{
				headerBody: 'Care Note',
				cellValuePath: ['text'], // width: '100px',
				renderCell: ([text], { id, deleted }) => {
					const { noteToDelete } = this.props;
					const disabledRow =
						(noteToDelete && noteToDelete.id === id) || deleted;
					return (
						<div>
							<Text
								className={disabledRow ? 'care-deleted' : ''}
								weight="normal"
								size="lg"
							>
								{text}
							</Text>
						</div>
					);
				},
			},
			{
				headerClass: 'care-note-action-header',
				headerBody: (
					<div className="care-updated">
						<span>Actions{'  '}</span>
						<Popover
							className="care-note-action-info"
							anchor={Icon}
							anchorProps={{
								icon: faCircleInfo,
								size: 'sm',
							}}
							dark
							maxDrawerWidth={230}
							openOnHover
						>
							{careNotesInstructions}
						</Popover>
					</div>
				),
				width: '257px',
				cellValuePath: ['id', 'updated'],
				renderCell: ([id, updated], { deleted, createdBy }) => {
					const { noteToDelete, user } = this.props;

					if (noteToDelete && noteToDelete.id === id) {
						return (
							<ButtonGroup>
								<>
									<Button
										id="deleteYes"
										aria-label="Proceed to delete care note"
										color="danger"
										variant="fill"
										onClick={this.handleFinalizeDelete}
									>
										Confirm Delete
									</Button>
									<Button
										id="deleteNo"
										variant="fill"
										textOnly
										aria-label="Cancel delete carenote"
										onClick={this.handleCancelDelete}
									>
										Cancel
									</Button>
								</>
							</ButtonGroup>
						);
					}

					if (!deleted) {
						return (
							<div>
								{updated ? (
									<Chip
										className="care-note-modified"
										color="grey"
										size="sm"
									>
										Modified
									</Chip>
								) : null}
								<Menu
									id={`carenote-${id}-menu`}
									Anchor={AnchorIconButton}
									anchorProps={{
										icon: faEllipsis,
										size: 'md',
										'aria-label': `${id} Carenote`,
									}}
									disabled={createdBy !== user.getId()}
									onClick={preventDefault}
								>
									<MenuButton
										id="edit-care-note"
										onClickMeta={id}
										onClick={this.handleEditNote}
									>
										Edit
									</MenuButton>
									<MenuButton
										id="delete-care-note"
										onClickMeta={id}
										onClick={this.handleDeleteNote}
									>
										Delete
									</MenuButton>
								</Menu>
							</div>
						);
					}
					return '';
				},
			},
		];
	}

	handleFinalizeDelete({ event }) {
		const { noteToDelete, notificationId } = this.props;
		event.preventDefault();
		const {
			fluxibleContext: {
				service: { deleteCareNote },
			},
		} = this.props;

		deleteCareNote({
			options: {
				careNoteId: noteToDelete.id,
				notificationId,
			},
			data: {},
		});
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(unsetNoteToBeDeleted);
	}

	handleEditNote({ meta: id }) {
		const { onEditNote } = this.props;
		onEditNote(id);
	}

	handleCancelDelete({ event }) {
		event.preventDefault();
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(unsetNoteToBeDeleted);
	}

	handleDeleteNote({ meta: id }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startDeleteNoteInline, id);
	}

	handleRowClick({ meta, event }) {
		if (event.defaultPrevented) {
			return;
		}

		const id = meta?.item?.id;

		const { opened } = this.state;

		this.setState({
			opened: opened !== id ? id : null,
		});
	}

	render() {
		const {
			error,
			isLoading,
			notes,
			startPeriod,
			endPeriod,
			onCreateNew,
			onReload,
		} = this.props;

		const { opened } = this.state;

		let message = null;
		let period = '-';
		let disableAddNote = true;

		if (!isNil(error)) {
			message = (
				<TableMessage
					header="Something went wrong..."
					body="Please try again."
					footer={
						<Button
							color="secondary"
							size="sm"
							variant="fill"
							onClick={onReload}
						>
							Try Again
						</Button>
					}
				/>
			);
		} else if (isLoading || isNil(notes)) {
			message = (
				<div className="spinner-container">
					<Spinner size="lg" variant="overlay" />
				</div>
			);
		} else {
			disableAddNote = false;

			period = (
				<Text size="sm" weight="semi-bold">
					<span className="care-note-start-period">
						{startPeriod}
					</span>
					{' - '}
					<span className="care-note-end-period">{endPeriod}</span>
				</Text>
			);

			if (notes.length <= 0) {
				message = (
					<TableMessage
						header="No record(s) available at this time."
						body="Try again at later time."
					/>
				);
			}
		}

		return (
			<>
				<div className="care-note-top">
					<span className="care-note-period">
						<Text size="sm" weight="bold">
							Note period:
						</Text>
						{period}
					</span>
					<Button
						size="sm"
						color="secondary"
						leftIcon={faCirclePlus}
						variant="outline"
						onClick={onCreateNew}
						disabled={disableAddNote}
					>
						Add Note
					</Button>
				</div>
				<SimpleTable
					className="care-note-table"
					items={notes}
					columns={this.columns}
					alwaysShowHeaders
					onClickRow={this.handleRowClick}
					isRowSelected={item => opened === item.id}
					renderRowChild={item => {
						if (opened !== item.id) {
							return null;
						}

						const { text, author, updated, updatedDate } = item;

						return (
							<Cell
								className="second-row-cell care-details"
								colSpan={3}
							>
								<p>
									<Row gutter>
										<Column width="11">
											<Text
												className="full-note-text"
												weight="normal"
												size="lg"
											>
												{text}
											</Text>
										</Column>
										<Column justify="right" width="1">
											<IconButton
												shape="circle"
												variant="default"
												color="info"
												id="delete-carenote-btn"
												size="md"
												icon={faCircleXmark}
												onClick={this.handleRowClick}
												onClickMeta={item}
											/>
										</Column>
									</Row>
								</p>
								<p>
									<Text
										className="note-meta-label"
										size="lg"
										weight="semi-bold"
									>
										Author:
									</Text>
									<Text
										className="note-author"
										weight="normal"
										size="lg"
									>
										<span className="author-first-name">
											{author.firstName}
										</span>{' '}
										<span className="author-last-name">
											{author.lastName}
										</span>
									</Text>
									{updated && (
										<>
											{'   '}
											<Text
												className="note-meta-label"
												size="lg"
												weight="semi-bold"
											>
												Modified Date:
											</Text>
											<Text weight="normal" size="lg">
												{updatedDate}
											</Text>
										</>
									)}
								</p>
							</Cell>
						);
					}}
				/>
				{message}
			</>
		);
	}
}

CareNoteTable.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	notes: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
			age: PropTypes.string,
			text: PropTypes.string,
			id: PropTypes.string,
			author: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
			}),
		}),
	),
	// eslint-disable-next-line react/forbid-prop-types
	user: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	noteToDelete: PropTypes.object.isRequired,
	startPeriod: PropTypes.string.isRequired,
	endPeriod: PropTypes.string.isRequired,
	onCreateNew: PropTypes.func.isRequired,
	onEditNote: PropTypes.func.isRequired,
	onReload: PropTypes.func.isRequired,
	notificationId: PropTypes.string.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

CareNoteTable.defaultProps = {
	error: null,
	isLoading: false,
	notes: null,
};

export default applyFluxibleContext(CareNoteTable);

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MenuCheck } from '@audacious/components/components/Menu';

class SingleSavedFilter extends PureComponent {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(value) {
		const { onToggle, filterId, valueIndex } = this.props;

		onToggle(filterId, valueIndex, value);
	}

	render() {
		const { id, label, enabled, overridden } = this.props;

		return (
			<MenuCheck
				id={`${id}-menu-item`}
				className={classnames('saved-menu-item', {
					overridden,
				})}
				value={enabled}
				onChange={this.handleChange}
				disabled={overridden}
			>
				{label}
			</MenuCheck>
		);
	}
}

SingleSavedFilter.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	enabled: PropTypes.bool.isRequired,
	overridden: PropTypes.bool.isRequired,
	filterId: PropTypes.string.isRequired,
	valueIndex: PropTypes.number.isRequired,
	onToggle: PropTypes.func.isRequired,
};

export default SingleSavedFilter;

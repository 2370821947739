/* eslint-disable react/no-access-state-in-setstate */
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
	error: null,
	isLoading: false,
	isOpen: false,
	documentMeta: null,
	documentId: null,
	documentHtml: null,
	documentCache: {},
};

class DocumentStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	getOpenDocumentState(documentEvent) {
		return {
			...initialState,
			documentCache: this.state.documentCache,
			documentId: documentEvent.id,
			documentMeta: {
				...pick(documentEvent, [
					'name',
					'sourceFacility',
					'sourceDepartment',
					'authorName',
					'authorDetail',
					'authorInstitution',
					'documentType',
				]),
				serviceStartTime: documentEvent.formatted.serviceStartTime,
				serviceStopTime: documentEvent.formatted.serviceStopTime,
			},
			isOpen: true,
		};
	}

	retrieveDocumentStart(documentEvent) {
		this.setState({
			...this.getOpenDocumentState(documentEvent),
			isLoading: true,
		});
	}

	retrieveDocumentFinish([error, documentHtml]) {
		if (!isNil(error)) {
			this.setState({
				error,
				isLoading: false,
			});

			return;
		}

		const { documentCache } = this.state;

		documentCache[`_${this.state.documentId}`] = documentHtml;

		this.setState({
			...this.state,
			documentHtml,
			isLoading: false,
			documentCache,
		});
	}

	openCachedDocument({ documentEvent, documentHtml }) {
		this.setState({
			...this.getOpenDocumentState(documentEvent),
			documentHtml,
		});
	}

	closeDocumentView() {
		this.setState({
			...initialState,
			documentCache: this.state.documentCache,
		});
	}
}

DocumentStore.storeName = 'DocumentStore';
DocumentStore.handlers = {
	RETRIEVE_DOCUMENT_START: 'retrieveDocumentStart',
	RETRIEVE_DOCUMENT_FINISH: 'retrieveDocumentFinish',
	OPEN_CACHED_DOCUMENT: 'openCachedDocument',
	CLOSE_DOCUMENT_VIEW: 'closeDocumentView',
	CLOSE_NOTIFICATION_DETAILS: 'resetState',
	LOGOUT: 'resetState',
};

export default DocumentStore;

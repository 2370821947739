import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { faTrashCan } from '@audacious/icons/regular/faTrashCan';
import Button from '@audacious/components/components/Button';
import AlertMessage from '@audacious/components/components/AlertMessage';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { Text } from '@audacious/components/components/Typography';
import Data, { DataTextAreaProperty } from '@audacious/components/components/Data';
import Spinner from '@audacious/components/components/Spinner';
import { Row, Column } from '@audacious/components/components/Grid';
import IconButton from '@audacious/components/components/IconButton';
import {
	cancelCreateNote,
	startDeleteNote,
} from '../../../actions/notification-details-actions';

function handleExecuteStart(value, results) {
	if (!isNil(results)) {
		return false;
	}

	return true;
}

class CareNoteForm extends PureComponent {
	constructor(props) {
		super(props);

		this.dataRef = createRef();

		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleExecute = this.handleExecute.bind(this);

		this.state = {
			color: null,
			message: null,
			messageTime: null,
		};
	}

	static getDerivedStateFromProps(nextProps, currentState) {
		const { moveAttemptTime, error } = nextProps;

		let { color, message, messageTime } = currentState;

		if (
			!isNil(moveAttemptTime) &&
			(isNil(messageTime) || messageTime < moveAttemptTime)
		) {
			color = 'warn';
			message =
				'Your note will be lost if you leave this tab without saving it. If you want to leave without saving, please click Cancel.';
			messageTime = moveAttemptTime;
		}

		if (
			!isNil(error) &&
			(isNil(messageTime) || messageTime < error.timestamp)
		) {
			color = 'danger';
			message =
				'We are unable to save the note. Please save your note again. ';
			messageTime = error.timestamp;
		}

		return {
			color,
			message,
			messageTime,
		};
	}

	handleSave() {
		this.dataRef.current.execute();
	}

	handleDelete() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		const { noteToUpdate } = this.props;

		executeAction(startDeleteNote, noteToUpdate.id);
	}

	handleCancel() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(cancelCreateNote);
	}

	// eslint-disable-next-line class-methods-use-this
	handleExecute({ text }) {
		const {
			fluxibleContext: {
				service: { createCareNote, updateCareNote },
			},
		} = this.props;

		const { notificationId, noteToUpdate } = this.props;
		if (!isNil(noteToUpdate) && !isNil(noteToUpdate.id)) {
			updateCareNote({
				options: {
					careNoteId: noteToUpdate.id,
					notificationId,
				},
				data: {
					noteContent: text,
				},
			});
		} else {
			createCareNote({
				options: {
					notificationId,
				},
				data: {
					noteContent: text,
				},
			});
		}
	}

	renderAlert() {
		const { color, message } = this.state;

		if (isNil(message)) {
			return null;
		}

		return (
			<Row gutter="16">
				<Column>
					<AlertMessage message={message} color={color} size="xs" />
				</Column>
			</Row>
		);
	}

	render() {
		const { noteToUpdate } = this.props;

		const initialData = {
			text: noteToUpdate?.text ?? null,
		};

		const { isSaving, user } = this.props;

		let spinnerElement = null;

		if (isSaving) {
			spinnerElement = <Spinner size="lg" variant="overlay" />;
		}

		return (
			<>
				{this.renderAlert()}
				<Row gutter="16">
					<Column>
						<Text weight="bold" size="md">
							{'Author: '}
						</Text>
						<Text className="note-author" weight="normal" size="md">
							<span className="author-first-name">
								{user.getFirstName()}
							</span>{' '}
							<span className="author-last-name">
								{user.getLastName()}
							</span>
						</Text>
					</Column>
				</Row>
				<Row gutter="16">
					<Column>
						<Data
							id="careNoteForm"
							ref={this.dataRef}
							baseValue={initialData}
							onExecuteStart={handleExecuteStart}
							onExecute={this.handleExecute}
							validateOnExecute
						>
							<DataTextAreaProperty
								id="care-note-message-input"
								aria-label="Note Text"
								placeholder="Write your care note here"
								path="text"
								required
								minLength={1}
								focusOnMount
							/>
						</Data>
					</Column>
				</Row>
				<Row gutter>
					<Column width="11">
						<ButtonGroup>
							<Button
								color="secondary"
								variant="fill"
								onClick={this.handleSave}
							>
								Save Note
							</Button>
							<Button
								color="secondary"
								variant="opaque"
								onClick={this.handleCancel}
							>
								Cancel
							</Button>
						</ButtonGroup>
					</Column>
					{!isNil(noteToUpdate) && (
						<Column justify="right" width="1">
							<IconButton
								shape="square"
								variant="fill"
								color="danger"
								id="delete-carenote-btn"
								size="md"
								icon={faTrashCan}
								onClick={this.handleDelete}
							/>
						</Column>
					)}
				</Row>
				{spinnerElement}
			</>
		);
	}
}

CareNoteForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	moveAttemptTime: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	noteToUpdate: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	notificationId: PropTypes.string.isRequired,
	isSaving: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	user: PropTypes.object.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

CareNoteForm.defaultProps = {
	moveAttemptTime: null,
	error: null,
	isSaving: false,
	noteToUpdate: null,
};

export default applyFluxibleContext(CareNoteForm);

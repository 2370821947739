import isNil from 'lodash/isNil';
import retrieveNotifications from './retrieve-notifications';

export function openNotificationDetails(context, notificationId) {
	if (isNil(notificationId)) {
		return;
	}

	const cached = context
		.getStore('NotificationDetailsStore')
		.getNotificationFromCache(notificationId);

	// Open the cached notification if it was previously retrieved.
	// Otherwise retrieve the notification and then store it in cache.
	if (isNil(cached)) {
		context.service.retrieveNotificationDetails({
			options: {
				notificationId,
			},
		});
	} else {
		context.dispatch('SET_OPEN_NOTIFICATION', cached);
	}
}

/**
 * Navigate to the next or previous notification details
 * @param {*} context fluxible context
 * @param {*} getNext if true, open the next notification details in the
 * notification list; if false, open the previous
 */
function notificationDetailsNavigate(context, getNext) {
	const newNotificationId = context
		.getStore('NotificationDetailsStore')
		.getNextOrPreviousNotificationId(getNext);

	if (newNotificationId === null) {
		// If null, this means the new notification is on the next or previous page.
		// In this case load the next page and then open the
		// next or previous notification on that page.
		const notificationStore = context.getStore('NotificationStore');
		const { page } = notificationStore.getPagination();

		context.dispatch('LOAD_NOTIFICATION_DETAILS');

		context.executeAction(retrieveNotifications, {
			resetTimeframe: false,
			newPagination: {
				page: page + (getNext ? 1 : -1),
			},
			callback: () => {
				// Scroll to top or bottom of page and retrieve the new notification details
				window.scrollTo({
					top: getNext ? 0 : document.body.scrollHeight,
					behavior: 'smooth',
				});

				const newPage = notificationStore.getNotifications();
				const newPageNotification = getNext
					? newPage?.[0]
					: newPage?.[newPage.length - 1];

				if (isNil(newPageNotification)) {
					context.dispatch('CLOSE_NOTIFICATION_DETAILS');
				} else {
					openNotificationDetails(context, newPageNotification?.id);
				}
			},
		});
	} else {
		openNotificationDetails(context, newNotificationId);
	}
}

export function openNextNotificationDetails(context) {
	notificationDetailsNavigate(context, true);
}

export function openPreviousNotificationDetails(context) {
	notificationDetailsNavigate(context, false);
}

export function closeNotificationDetails(context) {
	context.dispatch('CLOSE_NOTIFICATION_DETAILS');
}

export function closeDocumentView(context) {
	context.dispatch('CLOSE_DOCUMENT_VIEW');
}

export function openCachedDocument(context, args) {
	context.dispatch('OPEN_CACHED_DOCUMENT', args);
}

export function clearCareNotes(context) {
	context.dispatch('UNLOCK_EVENT_DETAILS');
	context.dispatch('CLEAR_CARE_NOTES');
}

export function startCreateNote(context) {
	context.dispatch('LOCK_EVENT_DETAILS');
	context.dispatch('START_CREATE_CARE_NOTE');
}

export function startEditNote(context, noteId) {
	context.dispatch('LOCK_EVENT_DETAILS');
	context.dispatch('START_EDIT_CARE_NOTE', { noteId, mode: 'edit' });
}

// this is called by the edit form to display the delete confirmation page
export function startDeleteNote(context, noteId) {
	context.dispatch('LOCK_EVENT_DETAILS');
	context.dispatch('START_DELETE_CARE_NOTE', { noteId, mode: 'delete' });
}

// this is called by the table in order to display the delete button in the table row
export function startDeleteNoteInline(context, noteId) {
	context.dispatch('LOCK_EVENT_DETAILS');
	context.dispatch('SET_CARE_NOTE_TO_DELETE', noteId);
}

// called by the table to hide the delete button either after delete is action is dispatached or if the cancel button is pressed
export function unsetNoteToBeDeleted(context) {
	context.dispatch('UNLOCK_EVENT_DETAILS');
	context.dispatch('SET_CARE_NOTE_TO_DELETE', null);
}

// called by the delete confirmation to unset the carenote to be deleted and return to the edit
export function cancelDeleteAndReturnToEdit(context) {
	context.dispatch('UNLOCK_EVENT_DETAILS');
	context.dispatch('CANCEL_DELETE_CARE_NOTE', 'edit');
}

export function cancelCreateNote(context) {
	context.dispatch('UNLOCK_EVENT_DETAILS');
	context.dispatch('CANCEL_CREATE_CARE_NOTE', 'view');
}

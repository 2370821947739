const items = {
	startPeriod: '05/01/2020',
	endPeriod: '08/01/2021',
	notes: [
		{
			date: '08/02',
			age: '52 days ago',
			text:
				'Voicemail left for the patient on 08/02/2020, require a call back within 48 hours.',
			id: 'foo',
			author: {
				firstName: 'Jennifer',
				lastName: 'Simpson',
			},
		},
		{
			date: '08/02',
			age: '52 days ago',
			text:
				'Voicemail left for the patient on 08/02/2020, require a call back within 48 hours.',
			id: 'foo',
			author: {
				firstName: 'Jennifer',
				lastName: 'Simpson',
			},
		},
	],
};

export default function retrieveCareNotesMock({ resolve }) {
	setTimeout(() => {
		resolve(items);
	}, 1000);
}

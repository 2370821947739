/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
import isNil from 'lodash/isNil';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

class CareTeamsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	startLoading() {
		this.setState({
			data: null,
			error: null,
			isLoading: true,
		});
	}

	finishLoading([error, data]) {
		this.setState({
			data,
			error,
			isLoading: false,
		});
	}

	isLoading() {
		return this.state.isLoading;
	}

	isLoaded() {
		return !isNil(this.state.data);
	}

	getData() {
		return this.state.data;
	}

	getError() {
		return this.state.error;
	}
}

CareTeamsStore.storeName = 'CareTeamsStore';
CareTeamsStore.handlers = {
	LOAD_CARE_TEAMS_START: 'startLoading',
	LOAD_CARE_TEAMS_FINISH: 'finishLoading',
};

export default CareTeamsStore;

const operatorLabels = {
	isempty: 'Is Empty',
	contains: 'Contains',
	notcontains: 'Does Not Contain',
	beginswith: 'Begins With',
	equals: 'Is',
	notequals: 'Is Not',
	lessthan: 'Less Than',
	lessthanorequal: 'Lesser or Equal',
	greaterthan: 'Greater Than',
	greaterthanorequal: 'Greater or Equal',
	dateequals: 'Is',
	datebefore: 'Before',
	dateafter: 'After',
	datewithin: 'Within',
};

export default function getOperatorLabel(operatorId) {
	return operatorLabels[operatorId];
}

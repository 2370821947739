import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_QUICK_FILTERS_START');
}

function onSuccess(actionContext, callContext) {
	const {
		response: { data },
	} = callContext;

	actionContext.dispatch('RETRIEVE_QUICK_FILTERS_FINISH', data);
}

function onFailed(actionContext) {
	actionContext.dispatch('RETRIEVE_QUICK_FILTERS_FINISH', null);
}

export default config => ({
	serviceName: 'retrieveQuickFilters',
	authenticate: true,
	config: {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.filters.retrieveQuick'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

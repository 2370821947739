/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import { withRouter } from 'react-router-dom';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { faCalendarLines } from '@audacious/icons/regular/faCalendarLines';
import { faCalendarLinesPen } from '@audacious/icons/regular/faCalendarLinesPen';
import Chip from '@audacious/components/components/Chip';
import Tooltip from '@audacious/components/components/Tooltip';
import IconButton from '@audacious/components/components/IconButton';
import { SimpleTable, RowHeader } from '@audacious/components/components/Table';
import { Text } from '@audacious/components/components/Typography';
import TableMessage from '@audacious/components/components/TableMessage';
import Link from '@audacious/components/components/Link';
import StatusSelector from '../../status-selector';
import { setPatientDetails } from '../../../actions/patient-summary';
import './notifications-table.scss';

class NotificationsTable extends React.PureComponent {
	constructor(props) {
		super(props);

		this.isRowSelected = this.isRowSelected.bind(this);
		this.handleEventDetailsClick = this.handleEventDetailsClick.bind(this);
		this.renderNoNotificationsMessage = this.renderNoNotificationsMessage.bind(
			this,
		);
		this.handleNameClick = this.handleNameClick.bind(this);

		this.columns = [
			{
				headerBody: 'Name',
				cellValuePath: [
					'id',
					'name',
					'genderAndAge',
					'isNew',
					'firstName',
					'lastName',
					'gender',
					'dob',
				],
				width: '208px',
				renderCell: ([
					id,
					name,
					genderAndAge,
					isNew,
					firstName,
					lastName,
					gender,
					dob,
				]) => (
					<>
						<RowHeader
							id={`${id}`}
							headerText={
								<Link
									variant="grey"
									onClick={this.handleNameClick}
									onClickMeta={{
										id,
										firstName,
										lastName,
										gender,
										dob,
									}}
								>
									{name}
								</Link>
							}
							subHeaderText={genderAndAge}
						/>
						{isNew ? (
							<Chip
								key={id}
								id={id}
								className="new-notification-chip fade-out"
								color="danger"
								size="sm"
							>
								New
							</Chip>
						) : null}
					</>
				),
			},
			{
				cellValuePath: ['id', 'hasCareNotes'],
				width: '42px',
				renderCell: ([id, hasCareNotes]) => (
					<Tooltip
						anchor={IconButton}
						anchorProps={{
							id: 'open-details-btn',
							className: 'details-btn',
							icon: hasCareNotes
								? faCalendarLinesPen
								: faCalendarLines,
							size: 'md',
							alignToIcon: true,
						}}
						onAnchorClick={() => this.handleEventDetailsClick(id)}
					>
						Event Details
					</Tooltip>
				),
			},
			{
				headerBody: 'MRN',
				cellValuePath: 'mrn',
				width: '126px',
			},
			{
				headerBody: 'Event Time',
				cellValuePath: ['eventDate', 'eventTime'],
				width: '100px',
				renderCell: ([eventDate, eventTime]) => (
					<>
						<span className="event-date">{eventDate}</span>
						<span className="event-time">{eventTime}</span>
					</>
				),
			},
			{
				headerBody: 'Facility',
				cellValuePath: 'facility',
				width: '116px',
			},
			{
				headerBody: 'Patient Class',
				cellValuePath: ['patientClassName', 'patientClassColor'],
				width: '114px',
				renderCell: ([patientClassName, patientClassColor]) => (
					<Chip color={patientClassColor} size="lg">
						{patientClassName}
					</Chip>
				),
			},
			{
				headerBody: 'Event Type',
				cellValuePath: ['eventName', 'eventColor'],
				width: '114px',
				renderCell: ([eventName, eventColor]) => (
					<Chip color={eventColor} size="lg">
						{eventName}
					</Chip>
				),
			},
			{
				headerBody: 'Alert Type',
				cellValuePath: ['alertTypeName', 'alertColor'],
				width: '93px',
				renderCell: ([alertTypeName, alertColor]) => (
					<Chip color={alertColor} size="lg">
						{alertTypeName}
					</Chip>
				),
			},
			{
				headerBody: 'Status',
				headerClass: 'status-header',
				cellValuePath: ['id', 'taskStatusCode'],
				width: '131px',
				renderCell: ([id, status]) => {
					const { isLoading } = this.props;

					return (
						<StatusSelector
							notificationId={id}
							status={status}
							disabled={isLoading}
						/>
					);
				},
			},
		];
	}

	handleNameClick({ meta: { id, firstName, lastName, gender, dob } }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		const { history } = this.props;

		executeAction(setPatientDetails, {
			firstName,
			lastName,
			gender,
			dob,
		});

		history.push(`/notifications/${id}/patient-summary`);
	}

	handleEventDetailsClick(id) {
		const { onEventDetailsClick, openNotificationId } = this.props;

		if (id !== openNotificationId) {
			onEventDetailsClick(id);
		}
	}

	isRowSelected(item) {
		const { openNotificationId } = this.props;

		return item.id === openNotificationId;
	}

	renderNoNotificationsMessage() {
		const {
			querySearchTerm,
			isLoading,
			hasRetrievedInitial,
			error,
			hasSubscriptions,
		} = this.props;

		if (error) {
			if (!window.navigator.onLine) {
				return (
					<TableMessage header="Connection interrupted. Please check your internet connection." />
				);
			}

			return (
				<TableMessage
					header="Something went wrong..."
					body="Please try again."
				/>
			);
		}

		if (!hasSubscriptions) {
			return (
				<TableMessage
					header="No subscription(s) available currently."
					body="Please contact your administrator."
				/>
			);
		}

		if (isLoading || !hasRetrievedInitial) {
			return null;
		}

		if (querySearchTerm) {
			return (
				<TableMessage
					header="No record matched your search."
					body={
						<ul>
							<li>
								<Text weight="normal" size="md">
									Broaden your search by using partial MRN or
									patient name (min. 3 characters).
								</Text>
							</li>
							<li>
								<Text weight="normal" size="md">
									You can also use the filter.
								</Text>
							</li>
							<li>
								<Text weight="normal" size="md">
									Remember, you can search using MRN or
									patient name only.
								</Text>
							</li>
						</ul>
					}
				/>
			);
		}

		return (
			<TableMessage
				header="No record(s) available at this time."
				body="Try again at a later time."
			/>
		);
	}

	render() {
		const { notifications } = this.props;

		return (
			<>
				<SimpleTable
					items={notifications}
					columns={this.columns}
					minWidth={1044}
					pageTable
					stickyColumns={1}
					isRowSelected={this.isRowSelected}
					alwaysShowHeaders
					useParentScroll
				/>
				{notifications.length === 0
					? this.renderNoNotificationsMessage()
					: null}
			</>
		);
	}
}

NotificationsTable.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
	querySearchTerm: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	hasRetrievedInitial: PropTypes.bool.isRequired,
	onEventDetailsClick: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	openNotificationId: PropTypes.number,
	hasSubscriptions: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

NotificationsTable.defaultProps = {
	error: null,
	openNotificationId: null,
};

export default connectToStores(
	withRouter(applyFluxibleContext(NotificationsTable)),
	['NotificationDetailsStore'],
	context => ({
		openNotificationId: context
			.getStore('NotificationDetailsStore')
			.getOpenNotification()?.id,
	}),
);

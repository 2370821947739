/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import Drawer from '@audacious/components/components/Drawer';
import { DeleteItemDocument } from '@audacious/components/components/Document';
import { filterDataPropType } from '../../../../common/app-prop-types';
import { closeCurrentFilterDrawer } from '../../../../actions/filter-actions';
import DrawerTypes from '../../../../common/drawer-types';
import './delete-filter-drawer.scss';

class DeleteFilterDrawer extends Component {
	constructor(props) {
		super(props);

		this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
		this.handleDeleteCommit = this.handleDeleteCommit.bind(this);
	}

	handleDeleteCancel() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeCurrentFilterDrawer);
	}

	handleDeleteCommit() {
		const {
			fluxibleContext: {
				service: { deleteFilter },
			},
		} = this.props;

		const { filterData, plusMore } = this.props;

		deleteFilter({
			options: {
				filterId: filterData._id,
				plusMore,
			},
		});
	}

	render() {
		const { drawerId, filterData, isSaving } = this.props;

		if (drawerId !== DrawerTypes.DELETE) {
			return null;
		}

		return (
			<Drawer
				id="delete-filter-drawer"
				open
				variant="right"
				size="lg"
				isSaving={isSaving}
			>
				<DeleteItemDocument
					size="lg"
					headerText="Delete Filter"
					itemName={filterData.name}
					handleDelete={this.handleDeleteCommit}
					handleCancel={this.handleDeleteCancel}
				/>
			</Drawer>
		);
	}
}

DeleteFilterDrawer.propTypes = {
	isSaving: PropTypes.bool.isRequired,
	drawerId: PropTypes.string,
	filterData: filterDataPropType,
	plusMore: PropTypes.number,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

DeleteFilterDrawer.defaultProps = {
	drawerId: null,
	filterData: null,
	plusMore: 0,
};

export default connectToStores(
	applyFluxibleContext(DeleteFilterDrawer),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		const currentFilterDrawer = filterStore.getCurrentDrawer();

		return {
			isSaving: filterStore.isSaving(),
			drawerId: get(currentFilterDrawer, 'drawerId'),
			filterData: get(currentFilterDrawer, 'data.filterData'),
			plusMore: get(currentFilterDrawer, 'data.plusMore'),
		};
	},
);

import merge from 'lodash/merge';

import applicationConfiguration from './application-configuration';
import applicationLocalization from './application-localization';

export default applicationContext => {
	const defaults = {
		applicationId: 'prompt',
		applicationName: 'PROMPT',
		applicationConfiguration,
		applicationLocalization,
	};

	return merge(defaults, applicationContext);
};

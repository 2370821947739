import get from 'lodash/get';

function onSuccess(
	actionContext,
	{ response, options: { onSuccessCallback } },
) {
	actionContext.dispatch('RETRIEVE_NOTIFICATION_COUNT_SUCCESS', {
		pendingTotal: response.data.total,
	});

	onSuccessCallback();
}

export default config => ({
	serviceName: 'retrieveNotificationCount',
	authenticate: true,
	config: {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.notifications.count'),
	},
	onSuccess,
});

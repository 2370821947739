import get from 'lodash/get';

function onSuccess(actionContext, callContext) {
	const {
		options: { notificationId },
		data,
	} = callContext;

	actionContext.dispatch('UPDATE_TASK', {
		id: notificationId,
		task: data,
	});
}

export default config => ({
	serviceName: 'updateTask',
	authenticate: true,
	config: {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.notifications.task.update'),
	},
	onSuccess,
});

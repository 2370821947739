import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('EXPORT_DELETE_START');
}

function onSuccess(actionContext, { options }) {
	actionContext.dispatch(
		'EXPORT_DELETE_SUCCESS',
		options?.clearStatus ?? false,
	);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('EXPORT_DELETE_FAILED', error);
}

export default config => ({
	serviceName: 'deleteExportedFile',
	authenticate: true,
	config: {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.export.getTask'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

import Pendo from '@audacious/fluxible-pendo';
import get from 'lodash/get';
import { Client } from '@audacious/client';
import stores from './stores';
import { createServiceConfiguration } from './services';
import { createApplicationContext, createContextConfiguration } from './config';
import Application from './components/root';

export default config => {
	const app = new Client({
		components: {
			Application,
		},
		stores,
		context: createApplicationContext(get(config, 'context', {})),
		services: createServiceConfiguration(get(config, 'services', {})),
	});

	app.plug(Pendo);

	function createContext(contextConfiguration) {
		const d = app.createContext(
			createContextConfiguration(contextConfiguration),
		);

		return d;
	}

	return {
		createContext,
		getComponent: app.getComponent.bind(app),
	};
};

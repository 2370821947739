import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Button from '@audacious/components/components/Button';
import Modal from '@audacious/components/components/Modal';
import Document, { DocumentHeader, DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { SubHeading } from '@audacious/components/components/Typography';
import { closeDownloadExportModal } from '../../actions/export-actions';

import './export-download-modal.scss';

class ExportDownloadModal extends PureComponent {
	constructor(props) {
		super(props);

		this.handleDownloadClick = this.handleDownloadClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
	}

	handleDownloadClick() {
		const {
			fluxibleContext: {
				service: { downloadExportedFile },
			},
		} = this.props;

		downloadExportedFile();
	}

	handleCancelClick() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeDownloadExportModal);
	}

	handleDeleteClick() {
		const {
			fluxibleContext: {
				service: { deleteExportedFile },
			},
		} = this.props;

		deleteExportedFile();
	}

	render() {
		const {
			exportFile: { name, size, rows },
			exportDownloadOpen,
			isSaving,
			onClose,
		} = this.props;

		if (!exportDownloadOpen) {
			return null;
		}

		return (
			<Modal
				id="export-download-modal"
				open
				size="sm"
				isSaving={isSaving}
				onClose={onClose}
				enableCloseOnOffClick
			>
				<Document className="export-download-modal" size="sm">
					<DocumentHeader>
						<SubHeading level="5">
							You have an export file ready to download.
						</SubHeading>
					</DocumentHeader>
					<DocumentBody className="export-download-modal-body">
						<p className="file-name">{name}</p>
						<p className="file-details">
							{`(${rows} records, ${size}MB)`}
						</p>
					</DocumentBody>
					<DocumentFooter sticky={false}>
						<ButtonGroup>
							<Button
								id="download-export-download-btn"
								color="primary"
								variant="fill"
								onClick={this.handleDownloadClick}
							>
								Download
							</Button>
							<Button
								id="cancel-export-download-btn"
								color="primary"
								variant="opaque"
								onClick={this.handleCancelClick}
							>
								Cancel
							</Button>
						</ButtonGroup>
						<Button
							id="delete-export-download-btn"
							className="pull-right"
							color="danger"
							variant="opaque"
							onClick={this.handleDeleteClick}
						>
							Delete File
						</Button>
					</DocumentFooter>
				</Document>
			</Modal>
		);
	}
}

ExportDownloadModal.propTypes = {
	exportFile: PropTypes.objectOf(PropTypes.string).isRequired,
	exportDownloadOpen: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

export default connectToStores(
	applyFluxibleContext(ExportDownloadModal),
	['ExportStore'],
	context => {
		const exportStore = context.getStore('ExportStore');

		return {
			...exportStore.getState(),
		};
	},
);

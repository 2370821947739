import { ObjectCollection } from '@audacious/client';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import FilterGroup from './filter-group';
import FieldPaths from '../common/field-paths';

function getId(item) {
	const { var: path, op: operator } = item;

	return `${path}:${operator}`;
}

function buildQuery(savedFiltersGroup, customFilterGroup) {
	const savedExpressions = !isNil(savedFiltersGroup)
		? savedFiltersGroup.getExpressions()
		: [];
	const customExpressions = !isNil(customFilterGroup)
		? customFilterGroup.getExpressions()
		: [];

	const collection = new ObjectCollection(customExpressions, getId);

	forEach(savedExpressions, savedExpression => {
		const item = collection.getById(getId(savedExpression));

		if (!isNil(item)) {
			item.value.push(...savedExpression.value);
		} else {
			collection.push(savedExpression);
		}
	});

	const expressions = collection.getData();

	if (expressions.length <= 0) {
		return {};
	}

	return {
		expressions,
	};
}

function buildStatus(expressions) {
	return reduce(
		expressions,
		(acc, expression) => {
			if (expression.var === FieldPaths.EVENT_TIME) {
				acc.eventTime = true;
			} else if (expression.var === FieldPaths.RECEIVED_TIME) {
				acc.receivedTime = true;
			} else if (expression.var === FieldPaths.TASK_STATUS) {
				acc.taskStatus = true;
			}

			return acc;
		},
		{},
	);
}

class AppliedFilters {
	constructor() {
		this.savedFilters = null;
		this.customFilters = null;
	}

	setSavedFilters(filterGroup) {
		this.savedFilters =
			filterGroup instanceof FilterGroup ? filterGroup : null;

		if (!isNil(this.savedFilters)) {
			this.savedFilters.setOverride(this.customFilters);
		}

		this.queryCache = null;
		this.expressionStatus = null;
	}

	setCustomFilters(filterGroup) {
		this.customFilters =
			filterGroup instanceof FilterGroup ? filterGroup : null;

		if (!isNil(this.savedFilters)) {
			this.savedFilters.setOverride(this.customFilters);
		}

		this.queryCache = null;
		this.expressionStatus = null;
	}

	clearCustomFilter(key, valueIndex) {
		this.customFilters.removeFilterValue(key, valueIndex);

		if (!isNil(this.savedFilters)) {
			this.savedFilters.setOverride(this.customFilters);
		}

		this.queryCache = null;
		this.expressionStatus = null;
	}

	clearAllFilters() {
		this.savedFilters = null;
		this.customFilters = null;

		this.queryCache = null;
		this.expressionStatus = null;
	}

	toggleSavedFilter(key, valueIndex, enabled) {
		this.savedFilters.toggleFilter(key, valueIndex, enabled);

		this.queryCache = null;
		this.expressionStatus = null;
	}

	getSavedFilters() {
		return this.savedFilters;
	}

	getCustomFilters() {
		return this.customFilters;
	}

	getQuery() {
		if (isNil(this.queryCache)) {
			this.queryCache = buildQuery(this.savedFilters, this.customFilters);

			this.expressionStatus = buildStatus(this.queryCache.expressions);
		}

		return this.queryCache;
	}

	hasEventTime() {
		this.getQuery();
		return this.expressionStatus?.eventTime === true;
	}

	hasReceivedTime() {
		this.getQuery();
		return this.expressionStatus?.receivedTime === true;
	}

	hasTaskStatus() {
		this.getQuery();
		return this.expressionStatus?.taskStatus === true;
	}
}

export default AppliedFilters;

import ExportStatus from '../../common/export-status';

export default function startExportMock({ resolve, db, request }) {
	const { fileName } = request.data;
	const csvFileName = `${fileName}.csv`;

	// Set mock state to be used in getExportTask mock
	db.setHasClickedExport(true);
	db.setExportFileName(csvFileName);

	setTimeout(() => {
		resolve({
			id: '6ec0bd7f-11c0-43da-975e-2a8ad9ebae0b',
			meta: {
				userId: '2ba211d2-8724-4a52-9baf-85f407bda462',
				userName: 'sysadmin',
				userEmail: 'sysadmin@ainq.com',
				tenantId: 'TESTTENANT',
				tenantCode: 'TESTTENANT',
				organizationId: '22c5a2c8-4ca1-451d-b7e0-cddba08b140e',
				organizationCode: 'ORG_CODE_1',
				transactionId: 'e26ebbd7-9f58-40fb-b65e-a9d1766a3c29',
				createdTime: '2021-02-01T17:32:28Z',
				receivedTime: '2021-02-01T17:34:28Z',
				completedTime: '2021-02-01T17:36:28Z',
			},
			file: {
				name: csvFileName,
				size: 3000,
				rows: 203,
				bucketId: 'aiweb-prompt-export',
			},
			args: {
				fileName,
				query: {
					expressions: [
						{
							var: 'subscription.id',
							op: 'equals',
							valueType: 'number',
							value: [16],
						},
						{
							var: 'patient.subscriber.pcpname',
							op: 'equals',
							value: ['Gregory House', 'Greg House'],
						},
					],
				},
				options: {
					timeZone: 'America/New_York',
					weekBeginDay: 0,
					orderBy: 'eventTime',
				},
				nameMrn: 'Ledner',
				include: {
					raw: true,
					columns: [
						'event.visit.dischargedisposition',
						'patient.publisher.insurance',
						'patient.publisher.gender',
					],
				},
			},
			status: ExportStatus.PENDING,
		});
	}, 1000);
}

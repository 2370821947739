import get from 'lodash/get';

export default config => ({
	serviceName: 'retrieveNotificationInitialCount',
	authenticate: true,
	config: {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.notifications.count'),
	},
});

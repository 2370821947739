/* eslint-disable react/no-access-state-in-setstate */
import moment from 'moment';
import map from 'lodash/map';
import join from 'lodash/join';
import round from 'lodash/round';
import toPath from 'lodash/toPath';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import downloadFile from '@audacious/web-common/utilities/downloadFile';
import ExportStatus from '../common/export-status';

const initialState = {
	configuration: null,
	exportOptions: {
		fileName: null,
		dateRangeFrom: null,
		dateRangeTo: null,
		alertInfo: null,
		acknowledged: false,
	},
	exportFile: {
		name: null,
		size: null,
		rows: null,
	},
	exportStatus: null,
	exportOptionsOpen: false,
	exportDownloadOpen: false,
	exportTimeFilterApplied: null,
	orderBy: null,
	isLoading: false,
	isSaving: false,
	error: null,
};

const DATE_FORMAT = 'MM/DD/YYYY';

class ExportStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	startEditingExportOptions({ exportTimeFilterApplied, orderBy, dateRange }) {
		const { fileName, alertInfo, acknowledged } = this.state.exportOptions;

		const curMoment = moment();
		const fromDefault = moment().subtract(dateRange, 'day');

		this.setState({
			exportOptions: {
				fileName:
					fileName ||
					`Prompt-Notifications-${curMoment.format('MM-DD-YYYY')}`,
				dateRangeFrom: fromDefault.format(DATE_FORMAT),
				dateRangeTo: curMoment.format(DATE_FORMAT),
				alertInfo,
				acknowledged,
			},
			exportOptionsOpen: true,
			exportTimeFilterApplied,
			orderBy,
		});
	}

	retrieveConfigStart() {
		this.setState({
			isLoading: true,
		});
	}

	retrieveConfigSuccess(exportConfig) {
		const alertInfo = {};
		const formattedConfig = map(exportConfig, (fieldConfig = {}) => {
			// Replace path dots with dashes in the path set in the data components
			// so that it doesn't deeply nest the data value in the data form
			const pathArray = toPath(fieldConfig.path);
			const alertInfoKey = join(pathArray, '-');

			const dataPath = `alertInfo.${alertInfoKey}`;
			alertInfo[alertInfoKey] = fieldConfig.checked ?? true;

			return {
				dataPath,
				path: fieldConfig.path,
				enabled: fieldConfig.enabled,
				label: `${fieldConfig.name}${fieldConfig.enabled ? '' : '*'}`,
			};
		});

		this.setState({
			exportOptions: {
				...this.state.exportOptions,
				alertInfo,
			},
			configuration: formattedConfig,
			isLoading: false,
			error: null,
		});
	}

	retrieveConfigFail(error) {
		this.setState({
			isLoading: false,
			error,
		});
	}

	cancelExport() {
		this.setState({
			exportOptionsOpen: false,
		});
	}

	startExportStart(exportOptions) {
		this.setState({
			exportStatus: ExportStatus.PENDING,
			exportOptions,
			exportOptionsOpen: false,
			isSaving: true,
			error: null,
		});
	}

	startExportSuccess({ status = ExportStatus.FAILED }) {
		this.setState({
			exportStatus: status,
			isSaving: false,
			error: null,
		});
	}

	startExportFail({ error, status = ExportStatus.FAILED }) {
		this.setState({
			exportStatus: status,
			isSaving: false,
			error,
		});
	}

	getExportTaskSuccess({
		status = ExportStatus.FAILED,
		exportFile,
		showModalIfComplete,
	}) {
		if (!this.isInProgress() && this.state.exportStatus) {
			// Only change task status if existing status is a valid state.
			// This, for example, prevents clearing of the error notification shown
			// after a failed startExport call when the getExportTask call returns 204
			return;
		}

		const newState = {
			exportStatus: status,
			error: null,
		};

		if (status === ExportStatus.COMPLETED) {
			// Backend returns size in Kilobytes but its displayed it in Megabytes, so convert
			const sizeInMegabytes = (exportFile.size ?? 0) / 1024;
			const size = round(sizeInMegabytes, 2);

			newState.exportFile = {
				...exportFile,
				size,
			};

			if (showModalIfComplete) {
				newState.exportDownloadOpen = true;
			}

			this.setState(newState);
		} else if (status !== this.state.exportStatus) {
			this.setState(newState);
		}
	}

	getExportTaskFail({ error, status = ExportStatus.FAILED }) {
		if (!this.isInProgress() && this.state.exportStatus) {
			return;
		}

		this.setState({
			exportStatus: status,
			error,
		});
	}

	openDownloadExportModal() {
		this.setState({
			exportDownloadOpen: true,
		});
	}

	closeDownloadExportModal() {
		this.setState({
			exportDownloadOpen: false,
		});
	}

	deleteOrDownloadStart() {
		this.setState({
			isSaving: true,
			error: null,
		});
	}

	deleteOrDownloadFail(error) {
		this.setState({
			exportStatus: ExportStatus.FAILED,
			isSaving: false,
			exportDownloadOpen: false,
			error,
		});
	}

	exportDownloadSuccess(file) {
		const { exportFile } = this.state;

		downloadFile(file, exportFile.name, 'text/csv');

		this.setState({
			exportStatus: null,
			isSaving: false,
			exportDownloadOpen: false,
			error: null,
		});
	}

	exportDeleteSuccess(clearStatus) {
		this.setState({
			exportStatus: clearStatus ? null : ExportStatus.DELETED,
			isSaving: false,
			exportDownloadOpen: false,
			error: null,
		});
	}

	isInProgress() {
		const { exportStatus } = this.state;

		return (
			exportStatus === ExportStatus.PENDING ||
			exportStatus === ExportStatus.RUNNING
		);
	}

	getExportOptions() {
		return this.state.exportOptions;
	}

	getExportStatus() {
		return this.state.exportStatus;
	}

	getConfiguration() {
		return this.state.configuration;
	}

	resetExportState() {
		this.setState({
			...initialState,
			exportOptions: {
				// Keep export options except for acknowledged checked status after logout
				...this.state.exportOptions,
				acknowledged: false,
			},
		});
	}

	getState() {
		return this.state;
	}
}

ExportStore.storeName = 'ExportStore';
ExportStore.handlers = {
	START_EDITING_EXPORT_OPTIONS: 'startEditingExportOptions',
	RETRIEVE_EXPORT_CONFIG_START: 'retrieveConfigStart',
	RETRIEVE_EXPORT_CONFIG_SUCCESS: 'retrieveConfigSuccess',
	RETRIEVE_EXPORT_CONFIG_FAILED: 'retrieveConfigFail',
	CANCEL_EXPORT: 'cancelExport',
	START_EXPORT_START: 'startExportStart',
	START_EXPORT_SUCCESS: 'startExportSuccess',
	START_EXPORT_FAILED: 'startExportFail',
	GET_EXPORT_TASK_SUCCESS: 'getExportTaskSuccess',
	GET_EXPORT_TASK_FAILED: 'getExportTaskFail',
	OPEN_DOWNLOD_EXPORT_MODAL: 'openDownloadExportModal',
	CLOSE_DOWNLOD_EXPORT_MODAL: 'closeDownloadExportModal',
	EXPORT_DOWNLOAD_START: 'deleteOrDownloadStart',
	EXPORT_DOWNLOAD_SUCCESS: 'exportDownloadSuccess',
	EXPORT_DOWNLOAD_FAILED: 'deleteOrDownloadFail',
	EXPORT_DELETE_START: 'deleteOrDownloadStart',
	EXPORT_DELETE_SUCCESS: 'exportDeleteSuccess',
	EXPORT_DELETE_FAILED: 'deleteOrDownloadFail',
	LOGOUT: 'resetExportState',
};

export default ExportStore;

/* eslint-disable react/jsx-props-no-spreading */
import { DataTextProperty } from '@audacious/components/components/Data';
import React, { Component } from 'react';

class NewFilterSaveProperties extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<DataTextProperty
				id="filter-name-field"
				focusOnMount
				path="name"
				label="Filter Name"
				required={[true, 'Please enter a valid Name']}
				minLength={[3, 'Minimum 3 characters required']}
			/>
		);
	}
}

NewFilterSaveProperties.propTypes = {};

NewFilterSaveProperties.defaultProps = {};

export default NewFilterSaveProperties;

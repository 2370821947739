import '@babel/polyfill';
import 'core-js/stable';
import './styles.scss'; // Must be first

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

// eslint-disable-next-line
import Client from './client';

const app = Client();
const context = app.createContext({
	services: {
		mock: {
			enabled: false,
			include: [],
			exclude: [],
		},
	},
});

const Component = app.getComponent();

ReactDOM.render(
	<AppContainer>
		<Component context={context.getComponentContext()} />
	</AppContainer>,
	document.getElementById('application'),
);

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Data from '@audacious/components/components/Data';
import {
	filterConfigPropType,
	filterDataPropType,
} from '../../../../common/app-prop-types';
import { applyFilter, saveFilter } from '../../../../actions/filter-actions';
import FilterProperties from '../../../filter-properties';
import NewFilterSaveProperties from './new-filter-save-properties';
import {
	loadFilterData,
	convertFilterDataFromMeta,
	getAvailableFilters,
} from '../../../../common/filter-utils';

function handleExecuteStart(_, results) {
	return isEmpty(results);
}

class NewFilterForm extends Component {
	constructor(props) {
		super(props);

		this.formRef = createRef();

		this.handleExecute = this.handleExecute.bind(this);
		this.handleAvailableUpdateFilters = this.handleAvailableUpdateFilters.bind(
			this,
		);

		const { filterConfig, initialData } = this.props;

		const { lookup } = filterConfig;

		this.state = {
			...getAvailableFilters(filterConfig, initialData.expressions),
			initialFilterData: loadFilterData(initialData, lookup),
		};
	}

	handleAvailableUpdateFilters() {
		const { filterConfig } = this.props;

		this.setState({
			...getAvailableFilters(
				filterConfig,
				this.formRef.current.get('expressions'),
			),
		});
	}

	handleExecute(filterData, _, options) {
		const { commit } = options;

		const { willSaveFilter, onNextStep } = this.props;

		const {
			fluxibleContext: { executeAction },
		} = this.props;

		if (commit) {
			const convertedData = convertFilterDataFromMeta(filterData);

			if (willSaveFilter) {
				executeAction(saveFilter, convertedData);
			} else {
				executeAction(applyFilter, convertedData);
			}

			return;
		}

		onNextStep();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	next(commit) {
		this.formRef.current.execute({
			commit,
		});
	}

	renderFormContents() {
		const { willSaveFilter, filterConfig } = this.props;

		const { availableFilters, availableOperatorsLookup } = this.state;

		if (willSaveFilter) {
			return <NewFilterSaveProperties />;
		}

		return (
			<FilterProperties
				filterConfig={filterConfig}
				availableFilters={availableFilters}
				availableOperatorsLookup={availableOperatorsLookup}
				onUpdateAvailableFilters={this.handleAvailableUpdateFilters}
			/>
		);
	}

	render() {
		const { initialFilterData } = this.state;

		return (
			<Data
				ref={this.formRef}
				baseValue={initialFilterData}
				onExecuteStart={handleExecuteStart}
				onExecute={this.handleExecute}
				validateOnBlur
				validateOnExecute
				showResultsOnTouch
				showResultsOnExecute
			>
				{this.renderFormContents()}
			</Data>
		);
	}
}

NewFilterForm.propTypes = {
	filterConfig: filterConfigPropType.isRequired,
	initialData: filterDataPropType.isRequired,
	willSaveFilter: PropTypes.bool.isRequired,
	onNextStep: PropTypes.func.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

NewFilterForm.defaultProps = {};

export default applyFluxibleContext(NewFilterForm);

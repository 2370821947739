import get from 'lodash/get';

function beforeRequest(actionContext, { options: { notificationId } }) {
	actionContext.dispatch(
		'RETRIEVE_NOTIFICATION_DETAILS_START',
		notificationId,
	);
}

function onSuccess(actionContext, { response }) {
	const code = get(response, 'data.code');
	const notification = get(response, 'data.notification');
	if (code !== 'SUCCESS') {
		actionContext.dispatch('RETRIEVE_NOTIFICATION_DETAILS_FAILED', code);
	} else if (!notification) {
		actionContext.dispatch(
			'RETRIEVE_NOTIFICATION_DETAILS_FAILED',
			'MISSING NOTIFICATION',
		);
	} else {
		actionContext.dispatch(
			'RETRIEVE_NOTIFICATION_DETAILS_SUCCESS',
			notification,
		);
	}
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('RETRIEVE_NOTIFICATION_DETAILS_FAILED', error);
}

export default config => ({
	serviceName: 'retrieveNotificationDetails',
	authenticate: true,
	config: {
		method: 'GET',
		url: get(config, 'endpoints.prompt.notifications.details'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

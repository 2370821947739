import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('EXPORT_DOWNLOAD_START');
}

function onSuccess(actionContext, { response: { data } }) {
	actionContext.dispatch('EXPORT_DOWNLOAD_SUCCESS', data);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('EXPORT_DOWNLOAD_FAILED', error);
}

export default config => ({
	serviceName: 'downloadExportedFile',
	authenticate: true,
	config: {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.export.download'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

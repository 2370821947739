const TEXT = {
	A01: 'Admission',
	A02: 'Transfer',
	A03: 'Discharge',
	A04: 'Registration',
	A05: 'Pre-Admit',
	A06: 'Change Outpatient to Inpatient',
	A07: 'Change Inpatient to Outpatient ',
	A08: 'Update',
	A11: 'Cancel Admit',
	A12: 'Cancel Transfer',
	A13: 'Cancel Discharge',
	A28: 'Add Person Information',
	A40: 'Merge Patient',
	EMS: 'Emergency Medical Services',
	R01: 'Test Result',
	IMMUNIZ: 'Vaccination Event',
	DEATH: 'Patient Deceased',
	CIE_DBR: 'Diabetes Registry',
	CIE_PDB: 'Pre-Diabetes',
	CIE_MCC: 'Chronic Care Management',
};

const COLOR = {
	A01: 'green',
	A03: 'purple',
	A04: 'red',
	A05: 'green',
};

const DEFAULT_COLOR = 'yellow';

export function getEventTypeName(eventType) {
	return TEXT[eventType] ?? eventType;
}

export function getEventTypeColor(patientClass) {
	return COLOR[patientClass] ?? DEFAULT_COLOR;
}

import Poller from './poller';
import { commonRequestBody } from '../actions/common';

let pollerInst = null;

function onStop(context) {
	context.dispatch('RETRIEVE_NOTIFICATION_COUNT_RESET');
}

function onPoll(context) {
	const notificationStore = context.getStore('NotificationStore');
	const filterStore = context.getStore('FilterStore');

	const session = context.getStore('Session');

	if (!session.isAuthenticated()) {
		return;
	}

	const retrieveArgs = {
		data: commonRequestBody(notificationStore, filterStore, {
			orderBy: 'receivedTime',
		}),
		params: {
			from: notificationStore.getRetrievalTime(),
		},
		options: {
			onSuccessCallback: () => {
				const pendingTotal = notificationStore.getPendingTotal();

				if (pendingTotal <= 0) {
					this.interval = 10000; // 10 seconds
				} else if (pendingTotal < 99) {
					this.interval = 30000; // 30 seconds
				} else {
					return;
				}

				this.startPoll();
			},
		},
	};

	context.service.retrieveNotificationCount(retrieveArgs);
}

function getNotificationPoller(context) {
	if (pollerInst !== null) {
		return pollerInst;
	}

	pollerInst = new Poller(context, 10000, onStop, onPoll);

	return pollerInst;
}

export default getNotificationPoller;

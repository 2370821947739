import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { SubMenu } from '@audacious/components/components/Menu';
import { Text } from '@audacious/components/components/Typography';
import SingleSavedFilter from './single-saved-filter';
import FilterLabel from './filter-label';
import getOperatorLabel from '../../../../common/get-operator-labels';

class MultipleSavedFilters extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange() {
		const { filterId, onToggle, totalEnabled, values } = this.props;

		const enabled = totalEnabled !== values.length;

		onToggle(filterId, null, enabled);
	}

	render() {
		const {
			id,
			name,
			values,
			filterId,
			onToggle,
			totalEnabled,
			isOverridden,
			operator,
		} = this.props;

		const count = values.length;
		const counter =
			totalEnabled === count ? `${count}` : `${totalEnabled}/${count}`;

		const rootCheckElement = (
			<>
				<Text className="filter-name" weight="bold">
					{name}
				</Text>{' '}
				<Text className="filter-operator">
					{getOperatorLabel(operator)}
				</Text>{' '}
				<Text className="filter-count">{`(${counter})`}</Text>
			</>
		);

		const checkedGroup = {
			value: totalEnabled > 0,
			disabled: isOverridden,
			onChange: this.handleChange,
			variant: totalEnabled < values.length ? 'indeterminate' : 'check',
		};

		return (
			<SubMenu
				id={`${id}-menu-item`}
				className="saved-menu-item"
				content={rootCheckElement}
				checkbox={checkedGroup}
			>
				{map(values, (item, valueIndex) => {
					const { displayValue, enabled, overridden } = item;

					return (
						<SingleSavedFilter
							id={`${id}-menu-value-${valueIndex}`}
							key={displayValue}
							label={
								<span>
									<FilterLabel
										operator={operator}
										value={displayValue}
									/>
								</span>
							}
							enabled={enabled}
							overridden={overridden}
							filterId={filterId}
							valueIndex={valueIndex}
							onToggle={onToggle}
						/>
					);
				})}
			</SubMenu>
		);
	}
}

MultipleSavedFilters.propTypes = {
	id: PropTypes.string.isRequired,
	filterId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	values: PropTypes.array.isRequired,
	onToggle: PropTypes.func.isRequired,
	totalEnabled: PropTypes.number.isRequired,
	isOverridden: PropTypes.bool.isRequired,
	operator: PropTypes.string.isRequired,
};

export default MultipleSavedFilters;

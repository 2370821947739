import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DocumentHeader } from '@audacious/components/components/Document';
import { Heading, SubHeading } from '@audacious/components/components/Typography';
import StatusSelector from '../status-selector';
import EventDetailsNav from './event-details-nav';

class EventDetailsHeader extends PureComponent {
	render() {
		const {
			notificationId,
			firstName,
			lastName,
			gender,
			status,
			disabled,
			isPreviousDisabled,
			isNextDisabled,
		} = this.props;

		return (
			<DocumentHeader>
				<Heading className="details-header-primary" level="5" inline>
					{`${firstName} ${lastName} `}
				</Heading>
				<SubHeading level="5" inline>
					{`(${gender})`}
				</SubHeading>
				<StatusSelector
					id="details"
					className="details-status"
					notificationId={notificationId}
					status={status}
					onChange={() => {}}
					disabled={disabled}
				/>
				<EventDetailsNav
					isPreviousDisabled={isPreviousDisabled}
					isNextDisabled={isNextDisabled}
				/>
			</DocumentHeader>
		);
	}
}

EventDetailsHeader.propTypes = {
	notificationId: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	gender: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	status: PropTypes.string,
	isPreviousDisabled: PropTypes.bool.isRequired,
	isNextDisabled: PropTypes.bool.isRequired,
};

EventDetailsHeader.defaultProps = {
	status: null,
};

export default EventDetailsHeader;

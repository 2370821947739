/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import StatusSelector from './status-selector';

class StatusSelectorFlexWrap extends Component {
	constructor(props) {
		super(props);

		this.handleStatusChange = this.handleStatusChange.bind(this);
	}

	handleStatusChange(newStatus) {
		const { notificationId } = this.props;

		const {
			fluxibleContext: {
				service: { updateTask },
			},
		} = this.props;

		updateTask({
			options: {
				notificationId,
			},
			data: {
				statusCode: newStatus,
			},
		});
	}

	render() {
		return (
			<StatusSelector
				{...this.props}
				onChange={this.handleStatusChange}
			/>
		);
	}
}

StatusSelectorFlexWrap.propTypes = {
	notificationId: PropTypes.number.isRequired,
	fluxibleContext: {
		service: {
			updateTask: PropTypes.func.isRequired,
		}.isRequired,
	}.isRequired,
};

export default applyFluxibleContext(StatusSelectorFlexWrap);

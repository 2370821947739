import noop from 'lodash/noop';
import Poller from './poller';

let pollerInst = null;

function onPoll(context) {
	context.service.getExportTask();
}

function getExportPoller(context) {
	if (pollerInst !== null) {
		return pollerInst;
	}

	pollerInst = new Poller(context, 3000, noop, onPoll);

	return pollerInst;
}

export default getExportPoller;

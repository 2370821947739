/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import slice from 'lodash/slice';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import AppliedFilters from '../models/applied-filters';
import FilterGroup from '../models/filter-group';

const initialState = {
	isSaving: false,
	configuration: null,
	data: null,
	savedFilterData: null,
	openDrawers: [],
	appliedFilters: new AppliedFilters(),
	selectedSavedFilter: null,
};

const operatorMap = {
	text: [
		'equals',
		'notequals',
		'contains',
		'notcontains',
		'beginswith',
		'isempty',
	],
	number: [
		'equals',
		'notequals',
		'lessthan',
		'lessthanorequal',
		'greaterthan',
		'greaterthanorequal',
		'isempty',
	],
	date: ['dateequals', 'datebefore', 'dateafter', 'datewithin', 'isempty'],
};

function processSavedFilter(savedFilter) {
	const { _id, name, query } = savedFilter;

	return {
		_id,
		name,
		expressions: query?.expressions ?? [],
	};
}

function createSavedFilterData(savedFilters) {
	return reduce(
		savedFilters,
		(acc, savedFilter) => {
			const processed = processSavedFilter(savedFilter);

			acc.list.push(processed);
			acc.lookup[processed._id] = processed;

			return acc;
		},
		{
			list: [],
			lookup: {},
		},
	);
}

class FilterStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	filterRequestStart() {
		this.setState({
			isSaving: true,
		});
	}

	filterRequestFinish() {
		this.setState({
			isSaving: false,
		});
	}

	setConfiguration(configurationData) {
		let configuration = configurationData;
		let totalPossible = 0;

		if (!(configurationData instanceof Error)) {
			let filterConfig = map(configurationData, filterDefinition => {
				const { type, valueOptions } = filterDefinition;

				const config = {
					...filterDefinition,
					operators: [...operatorMap[type]],
				};

				totalPossible += config.operators.length;

				if (!isNil(valueOptions)) {
					config.optionsLookup = keyBy(valueOptions, 'value');
				}

				return config;
			});

			filterConfig = sortBy(filterConfig, 'name');

			configuration = {
				list: filterConfig,
				lookup: keyBy(filterConfig, 'path'),
				totalPossible,
			};
		}

		this.setState({
			configuration,
		});
	}

	setSavedQueries(savedFilters) {
		const savedFilterData = !(savedFilters instanceof Error)
			? createSavedFilterData(savedFilters)
			: savedFilters;

		this.setState({
			savedFilterData,
		});

		this.emitChange();
	}

	appendSavedQuery(newSavedFilter) {
		const { list, lookup } = this.state.savedFilterData;

		const processed = processSavedFilter(newSavedFilter);

		this.setState({
			savedFilterData: {
				list: [...list, processed],
				lookup: {
					...lookup,
					[processed._id]: processed,
				},
			},
		});

		this.emitChange();
	}

	applySavedQuery(filterId) {
		let filterGroup = null;

		if (!isNil(filterId)) {
			const savedQuery = this.state.savedFilterData.lookup[filterId];

			if (!isNil(savedQuery)) {
				filterGroup = new FilterGroup(
					this.state.configuration,
					savedQuery.name,
					savedQuery.expressions,
				);
			}
		}

		this.state.appliedFilters.setSavedFilters(filterGroup);

		this.state.selectedSavedFilter = filterId;

		this.emitChange();
	}

	applyCustomFilters(filters) {
		let filterData = null;

		if (!isNil(filters)) {
			filterData = new FilterGroup(
				this.state.configuration,
				filters.name,
				filters.expressions,
			);
		}

		this.state.appliedFilters.setCustomFilters(filterData);

		this.emitChange();
	}

	updateSavedQuery(filterData) {
		if (this.state.selectedSavedFilter === filterData._id) {
			this.state.appliedFilters.setSavedQuery(filterData);
		}

		const { lookup } = this.state.savedFilterData;

		const updatedFilter = lookup[filterData._id];

		if (isNil(updatedFilter)) {
			throw new Error('Filter does not exist');
		}

		updatedFilter.name = filterData.name;
		updatedFilter.expressions = filterData?.query?.expressions ?? [];

		this.emitChange();
	}

	removeCustomFilter({ filterId, valueIndex }) {
		this.state.appliedFilters.clearCustomFilter(filterId, valueIndex);

		this.emitChange();
	}

	clearAllFilters() {
		this.state.appliedFilters.clearAllFilters();

		this.state.selectedSavedFilter = null;

		this.emitChange();
	}

	removeSavedQuery(filterId) {
		if (this.state.selectedSavedFilter === filterId) {
			this.state.appliedFilters.setSavedFilters(null);
		}

		const { list, lookup } = this.state.savedFilterData;

		const newList = filter(list, filterItem => filterItem._id !== filterId);

		const { [filterId]: removedFilter, ...newLookup } = lookup;

		this.setState({
			savedFilterData: {
				list: newList,
				lookup: newLookup,
			},
		});
	}

	toggleSavedFilter(payload) {
		const { filterId, valueIndex, enabled } = payload;

		this.state.appliedFilters.toggleSavedFilter(
			filterId,
			valueIndex,
			enabled,
		);

		this.emitChange();
	}

	openDrawer(drawerInfo) {
		this.setState({
			openDrawers: this.state.openDrawers.concat(drawerInfo),
		});
	}

	closeCurrentDrawer(plusMore = 0) {
		if (this.state.openDrawers.length <= 0) {
			return;
		}

		const dropCount = plusMore + 1;

		this.setState({
			isSaving: false,
			openDrawers: slice(
				this.state.openDrawers,
				0,
				this.state.openDrawers.length - dropCount,
			),
		});
	}

	isSaving() {
		return this.state.isSaving;
	}

	getCurrentDrawer() {
		if (!this.state.openDrawers || this.state.openDrawers.length <= 0) {
			return null;
		}

		return this.state.openDrawers[this.state.openDrawers.length - 1];
	}

	getConfiguration() {
		return this.state.configuration;
	}

	getSavedFilterData() {
		return this.state.savedFilterData;
	}

	getSavedFilterDataById(id) {
		return get(this.state.savedFilterData, ['lookup', id]);
	}

	getData() {
		return this.state.data;
	}

	getSavedData() {
		return this.state.appliedFilters?.getSavedData();
	}

	getCustomData() {
		return this.state.appliedFilters?.getCustomData();
	}

	getQuery() {
		return this.state.appliedFilters?.getQuery();
	}

	getCustomFilterGroup() {
		return this.state.appliedFilters?.getCustomFilters();
	}

	getSavedFilterGroup() {
		return this.state.appliedFilters?.getSavedFilters();
	}

	getSavedFilterId() {
		return this.state.selectedSavedFilter;
	}

	hasEventTime() {
		return this.state.appliedFilters.hasEventTime();
	}

	hasReceivedTime() {
		return this.state.appliedFilters.hasReceivedTime();
	}

	hasTaskStatus() {
		return this.state.appliedFilters.hasTaskStatus();
	}

	hasFilters() {
		return !isNil(this.getQuery()?.expressions);
	}
}

FilterStore.storeName = 'FilterStore';
FilterStore.handlers = {
	FILTER_REQUEST_START: 'filterRequestStart',
	FILTER_REQUEST_FINISH: 'filterRequestFinish',
	SET_FILTER_CONFIGURATION: 'setConfiguration',
	SET_SAVED_QUERIES: 'setSavedQueries',
	REMOVE_SAVED_QUERY: 'removeSavedQuery',
	APPEND_SAVED_QUERY: 'appendSavedQuery',
	UPDATE_SAVED_QUERY: 'updateSavedQuery',
	CLOSE_CURRENT_FILTER_DRAWER: 'closeCurrentDrawer',
	OPEN_FILTER_DRAWER: 'openDrawer',

	APPLY_CUSTOM_FILTERS: 'applyCustomFilters',
	APPLY_SAVED_FILTERS: 'applySavedQuery',
	CLEAR_ALL_FILTERS: 'clearAllFilters',

	REMOVE_CUSTOM_FILTER: 'removeCustomFilter',
	TOGGLE_SAVED_FILTER: 'toggleSavedFilter',
};

export default FilterStore;

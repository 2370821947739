import Promise from 'bluebird';
import moment from 'moment';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import getNotificationPoller from '../common/notification-poller';
import { commonRequestBody } from './common';
import NOTIFICATION_PAGE_STATE from '../common/notification-page-state';

/**
 * Calls the retrieveNotifications service
 * @param {*} context fluxible context
 * @param {boolean} [options.resetTimeframe] if true, issue a query with new "to" and "from" times,
 * with "to" set to the current time (ex. on initial load of the notifications page or reload
 * we need to reset to find newer notifications); set to false when page or page size changes
 * when only pagination changes
 * @param {object} [options.newPagination] object containing page and/or pageSize if these
 * values should change
 * @param {string} [options.searchInput] the search term the user has input; should be a name or MRN
 */
export default function retrieveNotifications(
	context,
	{
		resetTimeframe = true,
		refreshed,
		newPagination = {},
		searchQuery,
		orderBy,
		subscriptionKey,
		dateRange,
		taskStatus,
		sortOrder,
		callback,
	} = {},
) {
	getNotificationPoller(context).stop();

	const { getStore, service } = context;

	const notificationStore = getStore('NotificationStore');
	const filterStore = getStore('FilterStore');
	const sessionStore = context.getStore('Session');

	const activeSubscriptions = sessionStore
		.getSubscriptions()
		.getActiveSubscriptions();
	const hasSubscriptions = activeSubscriptions.length > 0;

	const pageState = notificationStore.getPageState();
	const selectedSubscriptionKey = notificationStore.getSelectedSubscriptionKey();
	const selectedSubscription = notificationStore.getSelectedSubscription();

	if (
		pageState === NOTIFICATION_PAGE_STATE.loadingNotifications ||
		!hasSubscriptions ||
		(!selectedSubscriptionKey && !subscriptionKey)
	) {
		context.dispatch('STOP_LOADING');
		return;
	}

	const curDate = new Date();

	const pagination = notificationStore.getPagination();
	const initialRetrievalTime = resetTimeframe
		? curDate.getTime()
		: notificationStore.getRetrievalTime();
	const finalDateRange =
		dateRange ?? notificationStore.getSelectedDateRange();

	let to;
	let from;

	if (!filterStore.hasEventTime() && !filterStore.hasReceivedTime()) {
		to = initialRetrievalTime;
		from = moment(to)
			.subtract(finalDateRange, 'day')
			.valueOf();
	}

	const requestBody = commonRequestBody(notificationStore, filterStore, {
		searchQuery,
		orderBy,
		subscriptionKey: subscriptionKey ?? selectedSubscriptionKey,
		sortOrder,
		dateRange: finalDateRange,
		taskStatus,
	});

	const retrieveNotificationsArgs = {
		data: {
			...requestBody,
			include: {
				params: false,
				dbQuery: false,
				careNotesMeta: true,
			},
		},
		params: {
			page: newPagination.page || pagination.page,
			pagesize: newPagination.pageSize || pagination.pageSize,
			to,
			from,
		},
		options: {
			initialRetrievalTime,
			refreshed:
				refreshed ?? pageState === NOTIFICATION_PAGE_STATE.refreshed,
			selectedSubscription,
			dateRange: finalDateRange,
			orderBy: orderBy ?? notificationStore.getOrderBy(),
			sortOrder: sortOrder ?? notificationStore.getSortOrder(),
			taskStatus: taskStatus ?? notificationStore.getTaskStatus(),
		},
	};

	if (!isNil(subscriptionKey)) {
		// If a different subscription was selected then set the new subscription
		// in the store using the new selected subscription key
		const newSubscription = find(
			activeSubscriptions,
			s => s.key === subscriptionKey,
		);

		if (!isNil(selectedSubscription)) {
			retrieveNotificationsArgs.options.selectedSubscription = newSubscription;
		}
	}

	const notificationPromise = Promise.fromCallback(resolve =>
		service.retrieveNotifications(retrieveNotificationsArgs, resolve),
	);

	const retrieveCount = () => {
		const retrieveCountArgs = {
			data: requestBody,
			params: {
				to,
				from,
				include: 'since',
			},
		};

		return Promise.fromCallback(resolve =>
			service.retrieveNotificationInitialCount(
				retrieveCountArgs,
				resolve,
			),
		);
	};

	const retrieveThresholdState = () => {
		const retrieveThresholdStateArgs = {
			data: requestBody,
			params: {
				to,
				from,
			},
		};

		return Promise.fromCallback(resolve =>
			service.retrieveNotificationThresholdState(
				retrieveThresholdStateArgs,
				resolve,
			),
		);
	};

	const retrieveAllCounts = () => {
		if (!resetTimeframe) {
			return null;
		}

		context.dispatch('RETRIEVE_NOTIFICATION_INITIAL_COUNT_START');

		return Promise.all([retrieveCount(), retrieveThresholdState()])
			.then(([countServiceContext, thresholdStateServiceContext]) => {
				if (isNil(countServiceContext)) {
					return;
				}

				const { total, since } = countServiceContext.response.data;

				context.dispatch(
					'RETRIEVE_NOTIFICATION_INITIAL_COUNT_SUCCEEDED',
					{
						total,
						since,
						thresholdExceeded:
							thresholdStateServiceContext.response.data.exceeded,
					},
				);
			})
			.catch(error => {
				context.dispatch(
					'RETRIEVE_NOTIFICATION_INITIAL_COUNT_FAILED',
					error ?? new Error('Failed to get counts'),
				);
			});
	};

	Promise.all([notificationPromise, retrieveAllCounts()]).then(res => {
		if (resetTimeframe) {
			getNotificationPoller(context).reset();
		}

		if (!isNil(callback)) {
			callback(res);
		}
	});
}

import React from 'react';
import PropTypes from 'prop-types';
import slice from 'lodash/slice';
import isNil from 'lodash/isNil';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { faUpRightFromSquare } from '@audacious/icons/solid/faUpRightFromSquare';
import { faUserGroup } from '@audacious/icons/solid/faUserGroup';
import Card from '@audacious/components/components/Card';
import Avatar from '@audacious/components/components/Avatar';
import Button from '@audacious/components/components/Button';
import { SubHeading } from '@audacious/components/components/Typography';
import Icon from '@audacious/components/components/Icon';
import IconButton from '@audacious/components/components/IconButton';
import Modal from '@audacious/components/components/Modal';
import Document, { DocumentBody } from '@audacious/components/components/Document';
import { SimpleTable } from '@audacious/components/components/Table';
import Spinner from '@audacious/components/components/Spinner';
import TableMessage from '@audacious/components/components/TableMessage';
import { isEmpty } from 'lodash';

const columns = [
	{
		headerBody: 'Care Program',
		cellValuePath: 'careProgramName',
	},
	{
		headerBody: 'Organization',
		cellValuePath: 'organization',
	},
	{
		headerBody: 'Org Phone',
		cellValuePath: 'organizationPhone',
	},
	{
		headerBody: 'Care Manager',
		cellValuePath: 'careManager',
	},
	{
		headerBody: 'Care Mngr Phone',
		cellValuePath: 'careManagerPhone',
	},
	{
		headerBody: 'PCP',
		cellValuePath: 'pcp',
	},
];

class CareTeams extends React.PureComponent {
	constructor(props) {
		super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.retrieveCareTeams = this.retrieveCareTeams.bind(this);

		this.state = {
			open: false,
		};
	}

	componentDidMount() {
		this.retrieveCareTeams();
	}

	handleOpenModal() {
		this.setState({
			open: true,
		});
	}

	handleCloseModal() {
		this.setState({
			open: false,
		});
	}

	retrieveCareTeams() {
		const {
			fluxibleContext: {
				service: { retrieveCareTeams },
			},
		} = this.props;

		const { notificationId } = this.props;

		retrieveCareTeams({
			options: {
				notificationId,
			},
		});
	}

	// eslint-disable-next-line class-methods-use-this
	renderCardTitle(withOpen) {
		const { items, isWorking, error } = this.props;

		let openButton = null;

		if (withOpen) {
			openButton = (
				<IconButton
					icon={faUpRightFromSquare}
					size="lg"
					onClick={this.handleOpenModal}
					disabled={isEmpty(items) || isWorking || !isNil(error)}
				/>
			);
		}

		return (
			<div className="summary-card-title">
				<Avatar color="green" size="lg">
					<Icon icon={faUserGroup} size="md" />
				</Avatar>
				<SubHeading level="5" color="green">
					Care team
				</SubHeading>
				{openButton}
			</div>
		);
	}

	// eslint-disable-next-line class-methods-use-this
	renderTable(numberItems) {
		const { items, isWorking, error } = this.props;

		let finalItems = null;
		let message = null;

		if (!isNil(error)) {
			message = (
				<TableMessage
					header="Something went wrong..."
					body="Please try again."
					footer={
						<Button
							color="secondary"
							size="sm"
							variant="fill"
							onClick={this.retrieveCareTeams}
						>
							Try Again
						</Button>
					}
				/>
			);
		} else if (isWorking) {
			message = <Spinner size="lg" variant="overlay" />;
		} else if (items.length <= 0) {
			message = (
				<TableMessage
					header="No record(s) available at this time."
					body="Try again at later time."
				/>
			);
		} else {
			finalItems = slice(items, 0, numberItems);
		}

		return (
			<>
				<SimpleTable
					items={finalItems}
					columns={columns}
					stickyColumns={1}
					alwaysShowHeaders
				/>
				<div className="summary-table-message">{message}</div>
			</>
		);
	}

	renderModal() {
		const { open } = this.state;

		if (!open) {
			return null;
		}

		return (
			<Modal
				id="care-teams-modal"
				open={open}
				variant="modal"
				size="xl"
				onClose={this.handleCloseModal}
				enableCloseOnOffClick
				enableCloseButton
			>
				<Document size="lg">
					<DocumentBody>
						{this.renderCardTitle(false)}
						{this.renderTable(10)}
					</DocumentBody>
				</Document>
			</Modal>
		);
	}

	render() {
		return (
			<Card id="care-team-card" className="summary-card" size="sm">
				{this.renderCardTitle(true)}
				{this.renderTable(5)}
				{this.renderModal()}
			</Card>
		);
	}
}

CareTeams.propTypes = {
	notificationId: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({})),
	isWorking: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	fluxibleContext: {
		service: {
			retrieveCareTeams: PropTypes.isRequired,
		}.isRequired,
	}.isRequired,
};

CareTeams.defaultProps = {
	items: null,
	isWorking: false,
	error: null,
};

export default connectToStores(
	applyFluxibleContext(CareTeams),
	['CareTeamsStore'],
	context => {
		const careTeamsStore = context.getStore('CareTeamsStore');

		return {
			isWorking: !careTeamsStore.isLoaded() || careTeamsStore.isLoading(),
			error: careTeamsStore.getError(),
			items: careTeamsStore.getData(),
		};
	},
);

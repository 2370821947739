import isNil from 'lodash/isNil';
import moment from 'moment';

function formatDate(date) {
	if (isNil(date) || date === '') {
		return '- -';
	}

	return moment(date).format('MM/DD/YYYY');
}

export default formatDate;

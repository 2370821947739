import get from 'lodash/get';

function onSuccess(actionContext, callContext) {
	const {
		response: { data },
	} = callContext;

	actionContext.dispatch('SET_FILTER_CONFIGURATION', data);
}

function onFailed(actionContext, callContext) {
	const { error } = callContext;

	actionContext.dispatch('SET_FILTER_CONFIGURATION', error);
}

export default config => ({
	serviceName: 'retrieveFilterConfig',
	authenticate: true,
	config: {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.configuration.filter.retrieve'),
	},
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

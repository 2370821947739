import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('FILTER_REQUEST_START');
}

function onSuccess(actionContext, callContext) {
	const {
		options: { filterId },
		data,
	} = callContext;

	actionContext.dispatch('UPDATE_SAVED_QUERY', {
		_id: filterId,
		...data,
	});

	actionContext.dispatch('CLOSE_CURRENT_FILTER_DRAWER');
	actionContext.dispatch('FILTER_REQUEST_FINISH', []);
}

function onFailed(actionContext, callContext) {
	const {
		response: { error },
	} = callContext;

	actionContext.dispatch('FILTER_REQUEST_FINISH', [error]);
}

export default config => ({
	serviceName: 'updateFilter',
	authenticate: true,
	config: {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.filters.filter.update'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
});

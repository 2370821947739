import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Text } from '@audacious/components/components/Typography';
import Menu, { MenuRemovable } from '@audacious/components/components/Menu';
import { AnchorChip } from '@audacious/components/components/DropDown';
import FilterLabel from './filter-label';
import getOperatorLabel from '../../../../common/get-operator-labels';

class CombinedFiltersMenu extends PureComponent {
	render() {
		const { name, values, onRemove, filterId, operator } = this.props;

		return (
			<Menu
				size="sm"
				Anchor={AnchorChip}
				anchorProps={{
					variant: 'pill',
					color: 'orange',
					size: 'lg',
					children: (
						<>
							<Text className="filter-name" weight="bold">
								{name}
							</Text>{' '}
							<Text className="filter-operator">
								{getOperatorLabel(operator)}
							</Text>
						</>
					),
				}}
			>
				{map(values, (value, valueIndex) => (
					<MenuRemovable
						key={value.displayValue}
						onRemove={onRemove}
						onRemoveMeta={{
							filterId,
							valueIndex,
						}}
					>
						<FilterLabel
							operator={operator}
							value={value.displayValue}
						/>
					</MenuRemovable>
				))}
			</Menu>
		);
	}
}

CombinedFiltersMenu.propTypes = {
	name: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.string).isRequired,
	onRemove: PropTypes.func.isRequired,
	filterId: PropTypes.string.isRequired,
	operator: PropTypes.string.isRequired,
};

CombinedFiltersMenu.defaultProps = {};

export default CombinedFiltersMenu;

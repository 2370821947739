import get from 'lodash/get';

function beforeRequest(actionContext, { options = {} }) {
	actionContext.dispatch('START_EXPORT_START', options);
}

function onSuccess(actionContext, { response: { data } }) {
	actionContext.dispatch('START_EXPORT_SUCCESS', {
		status: data?.status,
	});
}

function onFailed(actionContext, { response: { data }, error }) {
	actionContext.dispatch('START_EXPORT_FAILED', {
		error,
		status: data?.status,
	});
}

export default config => ({
	serviceName: 'startExport',
	authenticate: true,
	config: {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.export.create'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

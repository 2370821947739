import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Drawer from '@audacious/components/components/Drawer';
import Document from '@audacious/components/components/Document';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import EventDetailsHeader from './event-details-header';
import EventDetailsBody from './event-details-body';
import { closeNotificationDetails } from '../../actions/notification-details-actions';

import './event-details-modal.scss';

const offClickWhitelist = ['.details-btn.ai-icon-btn'];

class EventDetailsModal extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.handleMoveAttempt = this.handleMoveAttempt.bind(this);

		this.state = {
			moveAttemptTime: null,
		};
	}

	static getDerivedStateFromProps(nextProps) {
		const { moveLocked } = nextProps;

		if (moveLocked) {
			return null;
		}

		return {
			moveAttemptTime: null,
		};
	}

	handleClose() {
		this.handleMoveAttempt();

		const { moveLocked } = this.props;

		if (moveLocked) {
			return;
		}

		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeNotificationDetails);
	}

	handleMoveAttempt() {
		const { moveLocked } = this.props;

		if (moveLocked) {
			this.setState({
				moveAttemptTime: new Date(),
			});
		}
	}

	render() {
		const {
			open,
			isLoading,
			notification,
			error,
			moveLocked,
			hasCareNotesEntitlement,
			documentsEnabled,
			isPreviousDisabled,
			isNextDisabled,
		} = this.props;

		const { moveAttemptTime } = this.state;

		if (!open) {
			return null;
		}

		let mainRender = null;

		if (error) {
			mainRender = <div>{get(error, 'message', '')}</div>;
		} else if (!isNil(notification)) {
			const firstName = get(
				notification,
				'patient.publisher.firstname',
				'',
			);
			const lastName = get(
				notification,
				'patient.publisher.lastname',
				'',
			);
			const gender = get(notification, 'formatted.gender', '');

			mainRender = (
				<>
					<EventDetailsHeader
						firstName={firstName}
						lastName={lastName}
						gender={gender}
						notificationId={notification?.id}
						status={notification?.task?.statusCode}
						disabled={isLoading}
						isPreviousDisabled={isPreviousDisabled}
						isNextDisabled={isNextDisabled}
					/>
					<EventDetailsBody
						notification={notification}
						moveLocked={moveLocked}
						moveAttemptTime={moveAttemptTime}
						onMoveAttempt={this.handleMoveAttempt}
						hasCareNotesEntitlement={hasCareNotesEntitlement}
						documentsEnabled={documentsEnabled}
					/>
				</>
			);
		}

		return (
			<Drawer
				id="event-details-modal"
				open
				variant="right"
				size="lg"
				isLoading={isLoading}
				onClose={this.handleClose}
				enableCloseButton
				enableCloseOnOffClick={offClickWhitelist}
				disableBackdrop
			>
				<Document className="event-details-modal" size="lg">
					{mainRender}
				</Document>
			</Drawer>
		);
	}
}

EventDetailsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	notification: PropTypes.shape({
		id: PropTypes.number.isRequired,
		event: PropTypes.shape({
			date: PropTypes.string,
			adttype: PropTypes.string,
			adtpatientclass: PropTypes.string,
			alerttypecode: PropTypes.string,
		}).isRequired,
		metadata: PropTypes.shape({
			erdischargecount: PropTypes.number.isRequired,
			ipdischargecount: PropTypes.number.isRequired,
			receiveddate: PropTypes.string.isRequired,
		}).isRequired,
		patient: PropTypes.shape({
			publisher: PropTypes.shape({
				firstname: PropTypes.string.isRequired,
				lastname: PropTypes.string.isRequired,
				gender: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
		task: PropTypes.shape({
			statusCode: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	error: PropTypes.string.isRequired,
	moveLocked: PropTypes.bool.isRequired,
	hasCareNotesEntitlement: PropTypes.bool.isRequired,
	documentsEnabled: PropTypes.bool.isRequired,
	isPreviousDisabled: PropTypes.bool.isRequired,
	isNextDisabled: PropTypes.bool.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

export default connectToStores(
	applyFluxibleContext(EventDetailsModal),
	[
		'NotificationDetailsStore',
		'SettingsStore',
		'NotificationStore',
		'Session',
	],
	context => {
		const notificationDetailsStore = context.getStore(
			'NotificationDetailsStore',
		);
		const sessionStore = context.getStore('Session');
		const notificationStore = context.getStore('NotificationStore');

		const {
			openNotification,
			openNotificationIndex,
			isLoading,
			error,
			open,
			moveLocked,
		} = notificationDetailsStore.getState();
		const {
			notifications,
			selectedSubscription,
			total: { count },
			pagination: { page, pageSize },
		} = notificationStore.getState();

		const hasCareNotesEntitlement = sessionStore.hasEntitlement(
			'carenotes',
		);

		const settings = context.getStore('SettingsStore').getSettings();

		const documentsEnabled =
			settings?.documents?.enabled === true &&
			selectedSubscription?.raw?.Attributes?.IsArcherSubscriber ===
				'true';

		// If the open notification is the first on this page and we're on
		// the first page of results then disable the previous button
		const isPreviousDisabled = openNotificationIndex <= 0 && page === 1;
		// If the open notification is the last on this page and we're on
		// the last page of results then disable the next button
		const isNextDisabled =
			openNotificationIndex >= notifications.length - 1 &&
			page === Math.ceil(count / pageSize);

		return {
			open,
			isLoading,
			notification: openNotification,
			openNotificationIndex,
			error,
			moveLocked,
			hasCareNotesEntitlement,
			documentsEnabled,
			isPreviousDisabled,
			isNextDisabled,
		};
	},
);

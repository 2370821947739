import get from 'lodash/get';

function beforeRequest(actionContext, { options = {} }) {
	actionContext.dispatch('CARE_NOTE_REQUEST_START', options);
}

function onSuccess(actionContext, { options }) {
	actionContext.dispatch('CARE_NOTE_REQUEST_FINISH', [null]);
	actionContext.dispatch('UNLOCK_EVENT_DETAILS');

	const { retrieveCareNotes } = actionContext.service;

	retrieveCareNotes({
		options,
	});
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('CARE_NOTE_REQUEST_FINISH', [error]);
}

export default config => ({
	serviceName: 'deleteCareNote',
	config: {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.careNotes.update'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

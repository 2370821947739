/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { Text } from '@audacious/components/components/Typography';
import Chip from '@audacious/components/components/Chip';
import Popover from '@audacious/components/components/Popover';
import Icon from '@audacious/components/components/Icon';
import { Cell } from '@audacious/components/components/Table';
import { faCircleInfo } from '@audacious/icons/regular/faCircleInfo';
import { connectToStores } from 'fluxible-addons-react';
import EventDetailItem from '../event-detail-item';
import EventDetailsTableView from '../common/event-details-table-view';

function renderRowChild(item) {
	const { diagnosisCode, diagnosisDescription, dischargeDisposition } = item;

	return (
		<Cell className="second-row-cell" colSpan={6}>
			<EventDetailItem label="Diagnosis Code" value={diagnosisCode} />
			<EventDetailItem
				label="Diagnosis Desc"
				value={diagnosisDescription}
			/>
			<EventDetailItem
				label="Discharge Disposition"
				value={dischargeDisposition}
			/>
		</Cell>
	);
}

class EventHistoryTabContent extends PureComponent {
	constructor(props) {
		super(props);

		this.renderTopContent = this.renderTopContent.bind(this);

		this.columns = [
			{
				headerBody: 'Event Time',
				cellValuePath: ['formatted.eventDate', 'formatted.eventTime'],
				width: '104px',
				renderCell: ([eventDate, eventTime]) => (
					<>
						<Text
							weight="semi-bold"
							size="sm"
							style={{
								display: 'block',
							}}
						>
							{eventDate}
						</Text>
						<Text
							weight="normal"
							size="sm"
							style={{
								display: 'block',
							}}
						>
							{eventTime}
						</Text>
					</>
				),
			},
			{
				headerBody: 'Patient Class',
				cellValuePath: [
					'formatted.patientClassName',
					'formatted.patientClassColor',
				],
				width: '114px',
				renderCell: ([patientClassName, patientClassColor]) => (
					<Chip color={patientClassColor} size="md">
						{patientClassName}
					</Chip>
				),
			},
			{
				headerBody: 'Event Type',
				cellValuePath: ['formatted.eventType', 'formatted.eventColor'],
				width: '114px',
				renderCell: ([eventType, eventColor]) => (
					<Chip color={eventColor} size="md">
						{eventType}
					</Chip>
				),
			},
			{
				headerBody: 'Complaint',
				cellValuePath: 'admitReason',
				width: '190px',
			},
			{
				headerBody: 'Facility',
				cellValuePath: 'formatted.facilityName',
				width: '100px',
				renderCell: facility => <Text>{facility}</Text>,
			},
		];
	}

	componentDidMount() {
		const { notificationId, hasLoadedSuccessfully, isLoading } = this.props;

		if (hasLoadedSuccessfully || isLoading) {
			return;
		}

		const {
			fluxibleContext: {
				service: { retrieveEvents },
			},
		} = this.props;

		retrieveEvents({ options: { notificationId } });
	}

	renderTopContent() {
		const { fromDate, toDate } = this.props;

		return (
			<>
				<Text size="lg" weight="bold">
					Events from
				</Text>
				<Text size="lg" weight="semi-bold">
					{`${fromDate} - ${toDate}`}
				</Text>
				<Popover
					anchor={Icon}
					anchorProps={{
						icon: faCircleInfo,
						size: 'sm',
					}}
					dark
					maxDrawerWidth={260}
					openOnHover
					openOnClick={false}
				>
					Event alerts are from registered facilities.
				</Popover>
			</>
		);
	}

	render() {
		const { eventHistoryEvents, error, isLoading } = this.props;

		return (
			<EventDetailsTableView
				items={eventHistoryEvents}
				tableColumns={this.columns}
				error={error}
				isLoading={isLoading}
				renderTopContent={this.renderTopContent}
				renderRowChild={renderRowChild}
			/>
		);
	}
}

EventHistoryTabContent.propTypes = {
	notificationId: PropTypes.string.isRequired,
	error: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	hasLoadedSuccessfully: PropTypes.bool.isRequired,
	eventHistoryEvents: PropTypes.array.isRequired,
	fromDate: PropTypes.string.isRequired,
	toDate: PropTypes.string.isRequired,
	fluxibleContext: {
		service: {
			retrieveEvents: PropTypes.isRequired,
		}.isRequired,
	}.isRequired,
};

EventHistoryTabContent.defaultProps = {
	error: null,
};

export default connectToStores(
	applyFluxibleContext(EventHistoryTabContent),
	['PatientEventsStore'],
	context => {
		const patientEventsStore = context.getStore('PatientEventsStore');

		return {
			...patientEventsStore.getState(),
		};
	},
);

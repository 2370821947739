import merge from 'lodash/merge';
import retrieveNotifications from './retrieve-notifications.mock';
import retrieveFilterConfig from './retrieve-filter-config.mock';
import startExport from './start-export.mock';
import getExportTask from './get-export-task.mock';
import deleteExportedFile from './delete-exported-file.mock';
import downloadExportedFile from './download-exported-file.mock';
import retrieveEvents from './retrieve-events.mock';
import retrieveCareTeams from './retrieve-care-teams.mock';
import retrieveCareNotes from './retrieve-care-notes.mock';
import createCareNote from './create-care-note.mock';

const DEFAULT_MOCK_SERVICES = {
	retrieveNotifications,
	retrieveFilterConfig,
	startExport,
	getExportTask,
	deleteExportedFile,
	downloadExportedFile,
	retrieveEvents,
	retrieveCareTeams,
	retrieveCareNotes,
	createCareNote,
};

export default ({
	enabled = false,
	// include = [],
	exclude = [],
	immediate = true,
	services = {},
}) => ({
	enabled,
	include: [
		// 'startExport',
		// 'getExportTask',
		// 'downloadExportedFile',
		// 'deleteExportedFile',
		// 'retrieveEvents',
		// 'retrieveCareTeams',
		// 'retrieveCareNotes',
		// 'createCareNote',
	],
	exclude,
	immediate,
	services: merge(DEFAULT_MOCK_SERVICES, services),
	db: {
		hasClickedExport: false,
		exportFileName: '',
		setHasClickedExport(hasClickedExport) {
			this.hasClickedExport = hasClickedExport;
		},
		setExportFileName(name) {
			this.exportFileName = name;
		},
	},
});

import Promise from 'bluebird';
import set from 'lodash/set';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import getExportPoller from '../common/export-poller';
import { commonRequestBody } from './common';
import FieldPaths from '../common/field-paths';

function getExportTimeFieldPath(orderBy) {
	return orderBy === 'receivedTime'
		? FieldPaths.RECEIVED_TIME
		: FieldPaths.EVENT_TIME;
}

export function startEditingExportOptions(context) {
	const { getStore, service } = context;

	const filterStore = getStore('FilterStore');
	const exportStore = getStore('ExportStore');
	const notificationStore = getStore('NotificationStore');

	if (!exportStore.getConfiguration()) {
		service.retrieveExportConfig();
	}

	let orderBy = null;
	let exportTimeFilterApplied = false;

	if (filterStore.hasEventTime()) {
		exportTimeFilterApplied = true;
		orderBy = 'eventTime';
	} else if (filterStore.hasReceivedTime()) {
		exportTimeFilterApplied = true;
		orderBy = 'receivedTime';
	} else {
		orderBy = notificationStore.getOrderBy();
	}

	context.dispatch('START_EDITING_EXPORT_OPTIONS', {
		exportTimeFilterApplied,
		orderBy,
		dateRange: notificationStore.getSelectedDateRange(),
	});
}

export function exportStart(
	context,
	{ exportOptions, configuration, exportTimeFilterApplied, orderBy },
) {
	const { getStore, service } = context;
	const {
		fileName,
		dateRangeFrom,
		dateRangeTo,
		alertInfo,
		acknowledged,
	} = exportOptions;

	const notificationStore = getStore('NotificationStore');
	const filterStore = getStore('FilterStore');

	const columns = reduce(
		alertInfo,
		(acc, value, key) => {
			if (value) {
				// Change Data path alert info keys back to dot delimited paths to send in request
				const fieldConfig = find(
					configuration,
					config => config.dataPath === `alertInfo.${key}`,
				);
				const path = fieldConfig?.path;

				if (path) {
					acc.push(path);
				}
			}

			return acc;
		},
		[],
	);

	const startArgs = {
		data: {
			...commonRequestBody(notificationStore, filterStore),
			fileName,
			include: {
				columns,
			},
			userAgreedToTerms: acknowledged,
		},
		options: exportOptions,
	};

	if (!exportTimeFilterApplied) {
		// If an event time filter isn't applied then add the filter set
		// in the modal to the expressions list
		const expressions = get(startArgs, 'data.query.expressions', []);

		expressions.push({
			var: getExportTimeFieldPath(orderBy),
			op: 'datewithin',
			value: [dateRangeFrom, dateRangeTo],
			valueType: 'dateRange',
		});

		set(startArgs, 'data.query.expressions', expressions);
	}

	Promise.fromCallback(resolve => service.startExport(startArgs, resolve))
		.then(() => {
			getExportPoller(context).reset();
		})
		.catch(() => {
			getExportPoller(context).stop();
		});
}

export function openDownloadExportModal(context) {
	context.dispatch('OPEN_DOWNLOD_EXPORT_MODAL');
}

export function closeDownloadExportModal(context) {
	context.dispatch('CLOSE_DOWNLOD_EXPORT_MODAL');
}

export function cancelExport(context, cb) {
	context.dispatch('CANCEL_EXPORT');

	if (cb) {
		cb();
	}
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import { Text } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';

import './event-details-group.scss';

function renderItem({ label, value, subfieldItems }, idx) {
	const hasSubfields = !isNil(subfieldItems);

	return (
		<Row
			key={idx}
			className={classnames('event-details-group-row', {
				'has-subfields': hasSubfields,
			})}
		>
			<Column width={[null, '4']}>
				<Text
					className="label"
					weight="semi-bold"
					color="grey"
					size="md"
				>
					{label}
				</Text>
			</Column>
			{hasSubfields ? (
				map(subfieldItems, (subfields, subfieldsIndex) => {
					if (!subfields || subfields.length === 0) {
						return null;
					}

					return (
						<Column key={subfieldsIndex} className="subfield-item">
							{map(subfields, (subfield, subfieldIndex) => (
								<Row key={subfieldIndex} className="subfield">
									<Column width={[null, '4']}>
										<Text
											className="subfield-label"
											weight="normal"
											size="md"
										>
											{subfield.label}
										</Text>
									</Column>
									<Column width={[null, '8']}>
										<Text
											className="subfield-label"
											weight="normal"
											size="md"
										>
											{subfield.value}
										</Text>
									</Column>
								</Row>
							))}
						</Column>
					);
				})
			) : (
				<Column width={[null, '8']}>
					<Text className="value" weight="normal" size="md">
						{value}
					</Text>
				</Column>
			)}
		</Row>
	);
}

class EventDetailsGroup extends PureComponent {
	render() {
		const { eventDetailsGroup } = this.props;

		return (
			<div className="event-details-group secondary">
				{map(eventDetailsGroup.fields, renderItem)}
			</div>
		);
	}
}

EventDetailsGroup.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	eventDetailsGroup: PropTypes.object.isRequired,
};

export default EventDetailsGroup;

import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_EVENTS_START');
}

function onSuccess(actionContext, { response }) {
	const settings = actionContext.getStore('SettingsStore').getSettings();
	actionContext.dispatch('RETRIEVE_EVENTS_FINISH', [
		null,
		{
			events: response.data,
			numLookbackDays: settings?.events?.numLookbackDays,
		},
	]);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('RETRIEVE_EVENTS_FINISH', [error, null]);
}

export default config => ({
	serviceName: 'retrieveEvents',
	authenticate: true,
	config: {
		method: 'GET',
		url: get(config, 'endpoints.prompt.events.retrieve'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

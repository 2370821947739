/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import get from 'lodash/get';
import padStart from 'lodash/padStart';
import { faXmark } from '@audacious/icons/solid/faXmark';
import { faCircleInfo } from '@audacious/icons/regular/faCircleInfo';
import {
	Container,
	Row,
	Column,
} from '@audacious/components/components/Grid';
import { Text } from '@audacious/components/components/Typography';
import Icon from '@audacious/components/components/Icon';
import TextList, {
	TextListItem,
} from '@audacious/components/components/TextList';
import Popover from '@audacious/components/components/Popover';
import {
	DataAccess,
	DataIconButton,
	DataTextProperty,
	DataNumericProperty,
	DataDateProperty,
	DataSelectProperty,
	DataMultiSelectProperty,
} from '@audacious/components/components/Data';
import {
	calculateDateValue,
	getDateSubOperators,
	getDateSubOperatorType,
	roundDateTime,
	numberOperators,
} from '../../common/filter-utils';
import getOperatorLabel from '../../common/get-operator-labels';

const TIME_OPTIONS = [];

for (let counter = 0; counter < 24; counter += 1) {
	const hour24 = padStart(counter, 2, '0');
	let hour12 = counter;
	let dayHalf = 'a.m.';

	if (hour12 === 0) {
		hour12 = 12;
	} else if (hour12 > 11) {
		dayHalf = 'p.m.';

		if (hour12 > 12) {
			hour12 -= 12;
		}
	}

	TIME_OPTIONS.push({
		text: `${hour12}:00 ${dayHalf}`,
		key: `${hour24}:00:00`,
	});

	TIME_OPTIONS.push({
		text: `${hour12}:30 ${dayHalf}`,
		key: `${hour24}:30:00`,
	});
}

function handleSubOperatorChange(newSubOperator, dataContext) {
	const {
		form: { set, get: formGet },
		parent: {
			absolutePath,
			stats: { index },
		},
	} = dataContext;

	const parentPath = absolutePath.concat(index);

	const { op: operator } = formGet(parentPath);

	const newValueType = getDateSubOperatorType(newSubOperator);

	const newMeta = {
		subOperator: newSubOperator,
	};

	const newValue = calculateDateValue(newSubOperator, null);

	const roundValue = roundDateTime(operator, newSubOperator);

	set(newMeta, [...parentPath, 'meta']);
	set(newValueType, [...parentPath, 'valueType']);
	set(newValue, [...parentPath, 'value']);
	set(roundValue, [...parentPath, 'options', 'roundDateTime']);
}

function renderText(baseId, disabled = false) {
	return (
		<Column width={[null, null, '5']}>
			<DataTextProperty
				id={`${baseId}-text-value`}
				path="value"
				required={!disabled}
				disabled={disabled}
				placeholder="Value"
			/>
		</Column>
	);
}

function renderSelect(baseId, allowCustomValue, options) {
	return (
		<Column width={[null, null, '5']}>
			<DataSelectProperty
				id={`${baseId}-single-value`}
				path="value"
				allowCustomValue={allowCustomValue ? 'insensitive' : false}
				options={options}
				keyPath="value"
				optionTextPath="label"
				required
			/>
		</Column>
	);
}

function renderMultiSelect(baseId, allowCustomValue, options) {
	return (
		<Column width={[null, null, '5']}>
			<DataMultiSelectProperty
				id={`${baseId}-multi-value`}
				path="value"
				allowCustomValue={allowCustomValue ? 'insensitive' : false}
				options={options}
				keyPath="value"
				optionTextPath="label"
				required
			/>
		</Column>
	);
}

function renderDate(baseId, filterOperator, filterDefinition) {
	const valueOptions = getDateSubOperators(filterOperator, filterDefinition);

	return (
		<Column width={[null, null, '5']}>
			<DataSelectProperty
				id={`${baseId}-date-value`}
				path="meta.subOperator"
				options={valueOptions}
				onChange={handleSubOperatorChange}
				required
				nullable={false}
			/>
		</Column>
	);
}

function renderMainValueProperty(baseId, filterDefinition, filterOperator) {
	if (isNil(filterDefinition) || filterOperator === 'isempty') {
		return renderText(baseId, true);
	}

	const {
		type,
		allowMultipleValues,
		allowCustomValue,
		valueOptions,
	} = filterDefinition;

	if (type === 'date') {
		return renderDate(baseId, filterOperator, filterDefinition);
	}

	if (!allowMultipleValues || includes(numberOperators, filterOperator)) {
		if (isNil(valueOptions) || valueOptions.length <= 0) {
			return renderText(baseId);
		}

		return renderSelect(baseId, allowCustomValue, valueOptions);
	}

	return renderMultiSelect(baseId, allowCustomValue, valueOptions);
}

function renderAdditionalProperties(
	baseId,
	filterDefinition,
	subOperator,
	fromDateValue,
) {
	const filterDefinitionValueType = !isNil(filterDefinition)
		? filterDefinition.type
		: null;

	let properties = null;

	if (filterDefinitionValueType === 'date') {
		const subOperatorType = getDateSubOperatorType(subOperator);

		if (subOperatorType === 'date') {
			properties = (
				<Column
					width={[null, null, '5']}
					leftOffset={[null, null, '7']}
				>
					<DataDateProperty
						id={`${baseId}-date-sub-value`}
						path="value"
						required
						validDate
					/>
				</Column>
			);
		} else if (subOperatorType === 'dateTime') {
			properties = (
				<>
					<Column
						width={[null, null, '5']}
						leftOffset={[null, null, '4']}
					>
						<DataDateProperty
							id={`${baseId}-date-sub-value`}
							path="value.0"
							required
							validDate
						/>
					</Column>
					<Column width={[null, null, '3']}>
						<DataSelectProperty
							id={`${baseId}-date-time-sub-value`}
							path="value.1"
							required
							options={TIME_OPTIONS}
						/>
					</Column>
				</>
			);
		} else if (subOperatorType === 'dateRange') {
			properties = (
				<>
					<Column
						width={[null, null, '3']}
						leftOffset={[null, null, '6']}
					>
						<DataDateProperty
							id={`${baseId}-date-from-value`}
							path="value.0"
							required
							validDate
						/>
					</Column>
					<Column width={[null, null, '3']}>
						<DataDateProperty
							id={`${baseId}-date-to-value`}
							path="value.1"
							required
							validDate
							minDate={fromDateValue}
						/>
					</Column>
				</>
			);
		} else if (subOperatorType === 'dateTimeRange') {
			properties = (
				<>
					<Column width={[null, null, '3']}>
						<DataDateProperty
							id={`${baseId}-date-from-value`}
							path="value.0"
							required
							validDate
						/>
					</Column>
					<Column width={[null, null, '3']}>
						<DataSelectProperty
							id={`${baseId}-date-time-from-sub-value`}
							path="value.1"
							required
							options={TIME_OPTIONS}
						/>
					</Column>
					<Column width={[null, null, '3']}>
						<DataDateProperty
							id={`${baseId}-date-to-value`}
							path="value.2"
							required
							validDate
							minDate={fromDateValue}
						/>
					</Column>
					<Column width={[null, null, '3']}>
						<DataSelectProperty
							id={`${baseId}-date-time-to-sub-value`}
							path="value.3"
							required
							options={TIME_OPTIONS}
						/>
					</Column>
				</>
			);
		} else if (subOperatorType !== 'text') {
			properties = (
				<Column
					width={[null, null, '5']}
					leftOffset={[null, null, '7']}
				>
					<DataNumericProperty
						id={`${baseId}-date-num-value`}
						path="value"
						required
						min={1}
						placeholder={`Enter # of ${subOperatorType}`}
						step={1}
					/>
				</Column>
			);
		}
	}

	if (isNil(properties)) {
		return null;
	}

	return <Row gutter="16">{properties}</Row>;
}

class FilterEditProperty extends PureComponent {
	constructor(props) {
		super(props);

		this.handleFilterTypeChanged = this.handleFilterTypeChanged.bind(this);
		this.handleOperatorChanged = this.handleOperatorChanged.bind(this);
		this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
	}

	handleRemoveFilter(_, dataContext) {
		const {
			form: { validate, remove },
			property: { absolutePath },
		} = dataContext;

		const { onUpdateAvailableFilters } = this.props;

		remove(absolutePath);

		validate();

		onUpdateAvailableFilters();
	}

	handleFilterTypeChanged(newVar, dataContext) {
		const {
			filterLookUp,
			availableOperatorsLookup,
			onUpdateAvailableFilters,
		} = this.props;

		const {
			form: { set, remove },
			parent: {
				absolutePath,
				stats: { index },
			},
		} = dataContext;

		let newOperator = null;
		let newValueType = null;
		let newValue = null;
		let newMeta = null;
		let newOptions = null;

		const parentPath = absolutePath.concat(index);

		if (!isNil(newVar)) {
			[newOperator] = availableOperatorsLookup[newVar];
			const filterDefinition = filterLookUp?.[newVar];

			newValueType = filterDefinition?.type;

			if (newValueType === 'date') {
				const subOperator =
					getDateSubOperators(newOperator, filterDefinition)[0]
						?.key || null;

				newMeta = {
					subOperator,
				};

				newValue = calculateDateValue(subOperator, newValue);
				newValueType = getDateSubOperatorType(subOperator);
				newOptions = {
					roundDateTime: roundDateTime(
						newOperator,
						newMeta.subOperator,
					),
				};
			}
		}

		set(newOperator, [...parentPath, 'op']);
		set(newValue, [...parentPath, 'value']);
		set(newValueType, [...parentPath, 'valueType']);
		set(newMeta, [...parentPath, 'meta']);

		if (isNil(newOptions)) {
			remove([...parentPath, 'options']);
		} else {
			set(newOptions, [...parentPath, 'options']);
		}

		onUpdateAvailableFilters();
	}

	handleOperatorChanged(newOperator, dataContext) {
		const { filterLookUp, onUpdateAvailableFilters } = this.props;

		const {
			form: { set, get: formGet },
			parent: {
				absolutePath,
				stats: { index },
			},
		} = dataContext;

		const parentPath = absolutePath.concat(index);

		const { var: filterVariable, value } = formGet(parentPath);

		let newValueType = null;
		let newValue = null;
		let newMeta = null;
		let newOptions = null;

		const filterDefinition = filterLookUp?.[filterVariable];

		const filterDefinitionValueType = filterDefinition?.type;

		if (filterDefinitionValueType === 'date') {
			const subOperator =
				getDateSubOperators(newOperator, filterDefinition)[0]?.key ||
				null;

			newValueType = getDateSubOperatorType(subOperator);

			newMeta = {
				subOperator,
			};

			newValue = calculateDateValue(subOperator);

			newOptions = {
				roundDateTime: roundDateTime(newOperator, newMeta.subOperator),
			};

			set(newMeta, [...parentPath, 'meta']);
			set(newValue, [...parentPath, 'value']);
			set(newValueType, [...parentPath, 'valueType']);
			set(newOptions, [...parentPath, 'options']);
		} else if (newOperator === 'isempty') {
			set(null, [...parentPath, 'value']);
		} else if (filterDefinitionValueType === 'number') {
			// Convert between array and string values when switching between multi values
			// and number operators which don't allow multiple values
			if (includes(numberOperators, newOperator)) {
				if (isArray(value)) {
					set(value[0], [...parentPath, 'value']);
				}
			} else {
				const { allowMultipleValues } = filterLookUp[filterVariable];

				if (!isArray(value) && !isNil(value) && allowMultipleValues) {
					set([value], [...parentPath, 'value']);
				}
			}
		}

		onUpdateAvailableFilters();
	}

	render() {
		const {
			filterLookUp,
			availableFilters,
			availableOperatorsLookup,
		} = this.props;

		return (
			<DataAccess>
				{context => {
					const {
						property: {
							value: filterListing,
							results,
							absolutePath,
						},
					} = context;

					const baseId = join(absolutePath, '-');

					if (isNil(filterListing)) {
						throw new Error('Invalid Filter object');
					}

					const {
						var: filterVariable,
						op: filterOperator,
						meta,
						value,
					} = filterListing;

					const filterDefinition = !isNil(filterVariable)
						? filterLookUp[filterVariable]
						: null;

					const availableOperators = get(availableOperatorsLookup, [
						filterVariable,
					]);

					const subOperator = get(meta, 'subOperator');

					let fromDate = null;
					const fromDateResults = get(results, 'value.0.$');

					if (isNil(fromDateResults)) {
						fromDate = get(value, '0');

						if (!isNil(fromDate)) {
							fromDate = new Date(fromDate);
						}
					}

					const { targeted, basic } = availableFilters;

					let filterMessage = null;

					if (
						filterVariable === 'event.date' ||
						filterVariable === 'metadata.receiveddate'
					) {
						filterMessage = (
							<div className="filter-time-message">
								<Text>Event Time vs. Received Time</Text>
								<Popover
									anchor={Icon}
									anchorProps={{
										icon: faCircleInfo,
										size: 'xs',
									}}
									maxDrawerWidth={335}
									openOnClick
								>
									<Text>
										For the best filter results. We only
										allow either Event Time or Received Time
										to use in a filter.
									</Text>
									<TextList className="filter-time-message-outline">
										<TextListItem>
											<Text weight="bold">
												Event Time:
											</Text>
											{' Time of the Event'}
										</TextListItem>
										<TextListItem>
											<Text weight="bold">
												Received Time:
											</Text>
											{
												' Time when a notification was received.'
											}
										</TextListItem>
									</TextList>
								</Popover>
							</div>
						);
					}

					return (
						<>
							<DataIconButton
								id="filter-remove-btn"
								className="remove-filter-btn"
								icon={faXmark}
								size="md"
								onClick={this.handleRemoveFilter}
								hidden={({ parent }) => parent.stats.length < 2}
							/>
							<Container gutter="16">
								<Row gutter="16">
									<Column width={[null, null, '4']}>
										<DataSelectProperty
											id={`${baseId}-var`}
											path="var"
											options={
												targeted[filterVariable] ??
												basic
											}
											optionTextPath={v =>
												filterLookUp[v].name
											}
											selectedTextPath={v =>
												filterLookUp[v].name
											}
											keyPath="path"
											placeholder="Filter By"
											onChanged={
												this.handleFilterTypeChanged
											}
											required
										/>
									</Column>
									<Column width={[null, null, '3']}>
										<DataSelectProperty
											id={`${baseId}-op`}
											path="op"
											options={availableOperators}
											selectedTextPath={getOperatorLabel}
											optionTextPath={getOperatorLabel}
											onChanged={
												this.handleOperatorChanged
											}
											nullable={false}
											placeholder="Condition"
											disabled={isNil(filterDefinition)}
											required={!isNil(filterDefinition)}
										/>
									</Column>
									{renderMainValueProperty(
										baseId,
										filterDefinition,
										filterOperator,
									)}
								</Row>
								{renderAdditionalProperties(
									baseId,
									filterDefinition,
									subOperator,
									fromDate,
								)}
								{filterMessage}
							</Container>
						</>
					);
				}}
			</DataAccess>
		);
	}
}

const configItemPropType = PropTypes.shape({
	path: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	operators: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.string.isRequired,
	allowMultipleValues: PropTypes.bool.isRequired,
	allowCustomValue: PropTypes.bool.isRequired,
	options: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string.isRequired,
				label: PropTypes.string.isRequired,
			}),
		),
	]),
});

FilterEditProperty.propTypes = {
	filterLookUp: PropTypes.objectOf(configItemPropType).isRequired,
	availableFilters: PropTypes.shape({
		targeted: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
			.isRequired,
		basic: PropTypes.arrayOf(PropTypes.string).isRequired,
	}).isRequired,
	availableOperatorsLookup: PropTypes.objectOf(
		PropTypes.arrayOf(PropTypes.string),
	).isRequired,
	onUpdateAvailableFilters: PropTypes.func.isRequired,
};

FilterEditProperty.defaultProps = {};

export default FilterEditProperty;

import React, { createRef, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { faCircleInfo } from '@audacious/icons/regular/faCircleInfo';
import { faCircleExclamation } from '@audacious/icons/solid/faCircleExclamation';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faArrowDownToLine } from '@audacious/icons/regular/faArrowDownToLine';
import { faRotateRight } from '@audacious/icons/regular/faRotateRight';
import {
	PageContainer,
	PageContainerGroup,
	PageTitle,
} from '@audacious/components/components/Page';
import {
	Paragraph,
	Text,
} from '@audacious/components/components/Typography';
import ActionSelector from '@audacious/components/components/ActionSelector';
import Chip from '@audacious/components/components/Chip';
import RemovableChip from '@audacious/components/components/RemovableChip';
import IconButton from '@audacious/components/components/IconButton';
import Icon from '@audacious/components/components/Icon';
import Badge from '@audacious/components/components/Badge';
import Popover from '@audacious/components/components/Popover';
import BannerMessage from '@audacious/components/components/BannerMessage';
import Spinner from '@audacious/components/components/Spinner';
import {
	Container,
	Row,
	Column,
} from '@audacious/components/components/Grid';
import Data, { DataTextProperty } from '@audacious/components/components/Data';
import {
	RecordsPerPageSelector,
	PageNavigation,
} from '@audacious/components/components/Pagination';
import SubscriptionSelector from '@audacious/cca-components/components/SubscriptionSelector';
import NotificationsTable from './notifications-table';
import EventDetailsModal from '../../event-details/event-details-modal';
import {
	startEditingExportOptions,
	openDownloadExportModal,
} from '../../../actions/export-actions';
import retrieveNotifications from '../../../actions/retrieve-notifications';
import ExportDownloadModal from '../../export/export-download-modal';
import ExportStartModal from '../../export/export-start-modal';
import FilterMenu from './filter-menu';
import NewFilterDrawer from './new-filter-drawer';
import EditFilterDrawer from './edit-filter-drawer';
import EditSavedFiltersDrawer from './edit-saved-filters-drawer';
import DeleteFilterDrawer from './delete-filter-drawer';
import AppliedFilters from './applied-filters';
import ExportStatus from '../../../common/export-status';
import { STATUS_SELECTOR_OPTIONS } from '../../../common/status-options';
import {
	applyFilter,
	clearThresholdMessage,
} from '../../../actions/filter-actions';
import { filterConfigPropType } from '../../../common/app-prop-types';
import NOTIFICATION_PAGE_STATE from '../../../common/notification-page-state';

import './notifications-page.scss';
import { openNotificationDetails } from '../../../actions/notification-details-actions';

const ORDER_BY_OPTIONS = [
	{ key: 'receivedTime', text: 'Received Time' },
	{ key: 'eventTime', text: 'Event Time' },
];
const ORDER_BY_MAP = keyBy(ORDER_BY_OPTIONS, 'key');
const SORT_ORDER_OPTIONS = [
	{ key: 'desc', text: 'Newest First', selectedText: 'Newest' },
	{ key: 'asc', text: 'Oldest First', selectedText: 'Oldest' },
];
const DATE_RANGE_OPTIONS = [
	{ key: 1, text: 'Last 24 Hours' },
	{ key: 7, text: 'Last 7 Days' },
	{ key: 30, text: 'Last 30 Days' },
	{ key: 90, text: 'Last 90 Days' },
	{ key: 180, text: 'Last 180 Days' },
];

const PAGE_SIZE_OPTIONS = [20, 50, 100, 200];
const MIN_SEARCH_INPUT = 3;

function handleExecuteStart(value, results) {
	if (!isNil(results)) {
		return false;
	}

	return true;
}

function getFilterData() {
	// return {"name":null,"expressions":[{"var":"patient.subscriber.aco","op":"equals","valueType":"text","value":"uuuu"},{"var":"raw.AACode","op":"equals","valueType":"text","value":["kkkk"]}]};
	if (!isNil(window.location.search)) {
		const searchParams = new URLSearchParams(window.location.search);
		try {
			return JSON.parse(searchParams.get('filter'));
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
}

function getThresholdExceededText(count, threshold, orderByText, sinceDate) {
	return [
		<Fragment key="1">
			<Text weight="semi-bold">
				{`The total number of results might be higher than ${count}.`}
			</Text>
			{` The list shows the filtered results of the first ${threshold ??
				'-'} notifications based on the latest ${orderByText}. `}
		</Fragment>,
		<Fragment key="2">
			{sinceDate === '- -' ? (
				''
			) : (
				<>
					<Text weight="semi-bold">{sinceDate}</Text>
					{` is the ${orderByText} of the earliest notification within the results. `}
				</>
			)}
			You can change the date range to see other notifications.
		</Fragment>,
	];
}

class NotificationsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.dataRef = createRef();

		this.handleDismissNewAlerts = this.handleDismissNewAlerts.bind(this);
		this.handleDismissExportFailed = this.handleDismissExportFailed.bind(
			this,
		);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.handleExportIconClick = this.handleExportIconClick.bind(this);
		this.handleClearSearch = this.handleClearSearch.bind(this);
		this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
		this.handleSearchIconClick = this.handleSearchIconClick.bind(this);
		this.handleExecuteSearch = this.handleExecuteSearch.bind(this);
		this.notificationSearch = this.notificationSearch.bind(this);
		this.sortChange = this.sortChange.bind(this);
		this.sortOrderChange = this.sortOrderChange.bind(this);
		this.dateRangeChange = this.dateRangeChange.bind(this);
		this.taskStatusChange = this.taskStatusChange.bind(this);
		this.handleEventDetailsClick = this.handleEventDetailsClick.bind(this);
		this.handleChangePageSize = this.handleChangePageSize.bind(this);
		this.handleGotoPage = this.handleGotoPage.bind(this);
		this.handleThresholdMetMessageClear = this.handleThresholdMetMessageClear.bind(
			this,
		);
		this.renderTopPaginationControls = this.renderTopPaginationControls.bind(
			this,
		);
		this.renderBottomPaginationControls = this.renderBottomPaginationControls.bind(
			this,
		);
		this.renderNotificationChip = this.renderNotificationChip.bind(this);
		this.handleChangeSubscription = this.handleChangeSubscription.bind(
			this,
		);

		this.state = {
			newAlertsDismissed: false,
			exportFailedDismissed: false,
		};

		this.defaultSearch = {
			searchQuery: props.querySearchTerm,
		};
	}

	// Wait to pefrom the initial notification load until cached quick filters are
	// retrieved. If query params are present then also wait until the filter
	// config is retrieved to apply the filter from query params.
	componentDidUpdate() {
		const { history, filterConfig, pageState } = this.props;

		if (pageState !== NOTIFICATION_PAGE_STATE.quickFiltersLoaded) {
			return;
		}

		const filterData = getFilterData();
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		if (isNil(filterData)) {
			executeAction(retrieveNotifications);
		} else if (!isNil(filterConfig)) {
			executeAction(applyFilter, filterData);

			history.replace({
				search: '',
			});
		}
	}

	handleDismissNewAlerts() {
		this.setState({
			newAlertsDismissed: true,
		});
	}

	handleDismissExportFailed() {
		const {
			fluxibleContext: {
				service: {
					deleteExportedFile,
				},
			},
		} = this.props;

		// Delete the export task when the user closes the failure export
		// notification, but don't show the successful deletion chip
		deleteExportedFile({ options: { clearStatus: true } });

		this.setState({
			exportFailedDismissed: true,
		});
	}

	handleRefresh() {
		const { error } = this.props;

		this.notificationSearch({
			refreshed: isNil(error),
		});
	}

	handleExecuteSearch({ searchQuery }) {
		this.notificationSearch({ searchQuery });
	}

	handleExportIconClick() {
		const { exportStatus } = this.props;
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		if (exportStatus === ExportStatus.COMPLETED) {
			executeAction(openDownloadExportModal);
		} else {
			executeAction(startEditingExportOptions);
		}
	}

	handleClearSearch() {
		this.notificationSearch({ searchQuery: null });

		this.dataRef.current.setValidationState();
	}

	/**
	 * Issue notification search when enter is pressed
	 */
	handleSearchKeyDown(event) {
		// 13 is the key code for Enter
		if (event.which === 13) {
			this.dataRef.current.execute();
		}
	}

	handleSearchIconClick() {
		this.dataRef.current.execute();
	}

	handleEventDetailsClick(notificationId) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(openNotificationDetails, notificationId);
	}

	handleChangePageSize(newPageSize, newPage) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(retrieveNotifications, {
			resetTimeframe: false,
			newPagination: {
				pageSize: newPageSize,
				page: newPage,
			},
		});
	}

	handleGotoPage(newPage) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(retrieveNotifications, {
			resetTimeframe: false,
			newPagination: {
				page: newPage,
			},
		});
	}

	handleChangeSubscription(subscriptionKey) {
		this.notificationSearch({ subscriptionKey });
	}

	handleThresholdMetMessageClear() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(clearThresholdMessage);
	}

	notificationSearch({
		searchQuery,
		sortOrder,
		orderBy,
		subscriptionKey,
		dateRange,
		taskStatus,
		refreshed,
	}) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(retrieveNotifications, {
			newPagination: {
				page: 1,
			},
			refreshed,
			orderBy,
			subscriptionKey,
			dateRange,
			taskStatus,
			searchQuery,
			sortOrder,
		});

		this.setState({
			newAlertsDismissed: false,
		});
	}

	sortChange(orderBy) {
		this.notificationSearch({ orderBy });
	}

	sortOrderChange(sortOrder) {
		this.notificationSearch({ sortOrder });
	}

	dateRangeChange(dateRange) {
		this.notificationSearch({ dateRange });
	}

	taskStatusChange(taskStatus) {
		this.notificationSearch({ taskStatus });
	}

	renderTopPaginationControls() {
		const {
			orderByText,
			pagination,
			error,
			isLoading: isLoadingList,
			pendingTotal,
			exportStatus,
			exportInProgress,
			hasSubscriptions,
			total: {
				count,
				isLoading: isLoadingCount,
				sinceDate,
				thresholdExceeded,
			},
			threshold,
		} = this.props;

		let pendingLabel = null;

		if (pendingTotal > 0) {
			pendingLabel = pendingTotal >= 99 ? `99+` : `${pendingTotal}`;
		}

		let exportStatusIcon = null;

		if (exportInProgress) {
			exportStatusIcon = <Spinner color="red" size="xs" />;
		} else if (exportStatus === ExportStatus.COMPLETED) {
			exportStatusIcon = (
				<Icon icon={faCircleExclamation} size="md" color="danger" />
			);
		}

		let thresholdExceededPopover = null;

		if (thresholdExceeded) {
			const thresholdText = getThresholdExceededText(
				count,
				threshold,
				orderByText,
				sinceDate,
			);

			thresholdExceededPopover = (
				<Popover
					className="threshold-as-of-date-anchor"
					anchor={Icon}
					anchorProps={{
						icon: faCircleInfo,
						size: 'sm',
						color: 'danger',
					}}
					maxDrawerWidth={400}
					openOnHover
					openOnClick={false}
					heading="Notification Limit"
				>
					<Paragraph>{thresholdText[0]}</Paragraph>
					<Paragraph>{thresholdText[1]}</Paragraph>
				</Popover>
			);
		}

		return (
			<Row justify={['center', 'right']} align="center">
				<Column
					width={['fill', 'content']}
					leftOffset={[null, 'fill']}
					justify={['left', 'right']}
					align="center"
				>
					<RecordsPerPageSelector
						id="top-records-selector"
						className={classnames('notifications-page-selector', {
							'threshold-exceeded': thresholdExceeded,
						})}
						numRecords={count}
						currentPage={pagination.page}
						recordsPerPage={pagination.pageSize}
						recordsPerPageOptions={PAGE_SIZE_OPTIONS}
						disabled={
							isLoadingList ||
							isLoadingCount ||
							!isNil(error) ||
							!hasSubscriptions
						}
						onChangeRecordsPerPage={this.handleChangePageSize}
						status={isLoadingCount ? 'loading' : null}
						tooltipText={`As of ${sinceDate}`}
					/>
					{thresholdExceededPopover}
				</Column>
				<Column width={[null, 'content']} order={['2', '0']}>
					<PageNavigation
						id="top-pagination-control"
						className="notifications-page-navigation"
						// size="md"
						numRecords={count}
						currentPage={pagination.page}
						recordsPerPage={pagination.pageSize}
						onGotoPage={this.handleGotoPage}
						disabled={
							isLoadingList ||
							isLoadingCount ||
							!isNil(error) ||
							!hasSubscriptions
						}
						firstNav
					/>
				</Column>
				<Column width="content" order={['1', '0']}>
					<IconButton
						id="refresh-notifications-btn"
						className="notification-refresh-btn"
						size="sm"
						disabled={isLoadingList || !hasSubscriptions}
						onClick={this.handleRefresh}
					>
						<Badge value={pendingLabel} color="danger">
							<Icon icon={faRotateRight} size="md" />
						</Badge>
					</IconButton>
					<IconButton
						id="export-btn"
						size="sm"
						disabled={
							isLoadingList ||
							exportInProgress ||
							!hasSubscriptions
						}
						onClick={this.handleExportIconClick}
					>
						<Badge
							position="bottom-right"
							value={exportStatusIcon}
							color="danger"
						>
							<Icon icon={faArrowDownToLine} size="md" />
						</Badge>
					</IconButton>
				</Column>
			</Row>
		);
	}

	renderBottomPaginationControls() {
		const {
			pagination,
			isLoading: isLoadingList,
			hasSubscriptions,
			notifications,
			total: { count, isLoading: isLoadingCount },
		} = this.props;

		if (notifications.length <= 0) {
			return null;
		}

		return (
			<Container>
				<Row gutter="16">
					<Column width="content" leftOffset="fill">
						<PageNavigation
							id="bottom-pagination-control"
							numRecords={count}
							currentPage={pagination.page}
							recordsPerPage={pagination.pageSize}
							onGotoPage={this.handleGotoPage}
							disabled={
								isLoadingList ||
								isLoadingCount ||
								!hasSubscriptions
							}
							numJumpPages={7}
							firstNav
						/>
					</Column>
					<Column width="content" rightOffset="fill">
						<RecordsPerPageSelector
							id="bottom-records-selector"
							numRecords={count}
							currentPage={pagination.page}
							recordsPerPage={pagination.pageSize}
							recordsPerPageOptions={PAGE_SIZE_OPTIONS}
							disabled={
								isLoadingList ||
								isLoadingCount ||
								!hasSubscriptions
							}
							onChangeRecordsPerPage={this.handleChangePageSize}
							status={isLoadingCount ? 'loading' : null}
						/>
					</Column>
				</Row>
			</Container>
		);
	}

	// eslint-disable-next-line class-methods-use-this
	renderExportStatusChip(key, text) {
		return (
			<Chip
				key={key}
				className="notifications-alert fade-out"
				color="grey"
				shade="darker"
				variant="pill"
				size="xl"
			>
				{text}
			</Chip>
		);
	}

	renderNotificationChip() {
		const {
			pendingTotal,
			isLoading,
			showSpinner,
			exportStatus,
			exportInProgress,
			hasSubscriptions,
		} = this.props;

		const {
			eventDetailsIsOpen,
			newAlertsDismissed,
			exportFailedDismissed,
		} = this.state;

		if (eventDetailsIsOpen || showSpinner) {
			return null;
		}

		if (exportStatus) {
			if (exportStatus !== ExportStatus.FAILED && exportFailedDismissed) {
				// Reset dismissed state
				this.setState({
					exportFailedDismissed: false,
				});
			}

			if (
				exportStatus === ExportStatus.FAILED &&
				!exportFailedDismissed
			) {
				return (
					<RemovableChip
						className="notifications-alert"
						color="danger"
						variant="pill"
						size="xl"
						clearType="inverse"
						onClear={this.handleDismissExportFailed}
					>
						Export Failed. Try Again Later
					</RemovableChip>
				);
			}

			if (exportInProgress) {
				return this.renderExportStatusChip(
					'exportInProgress',
					'Exporting...',
				);
			}

			if (exportStatus === ExportStatus.COMPLETED) {
				return this.renderExportStatusChip(
					'exportCompleted',
					'Export Completed',
				);
			}

			if (exportStatus === ExportStatus.DELETED) {
				return this.renderExportStatusChip(
					'exportDeleted',
					'Exported File Deleted',
				);
			}
		}

		if (isLoading && hasSubscriptions) {
			return (
				<Chip
					className="notifications-alert"
					color="grey"
					shade="darker"
					variant="pill"
					size="xl"
				>
					Loading...
				</Chip>
			);
		}

		if (pendingTotal > 0 && !newAlertsDismissed) {
			return (
				<RemovableChip
					className="notifications-alert"
					color="danger"
					variant="pill"
					size="xl"
					clearType="inverse"
					onClear={this.handleDismissNewAlerts}
				>
					New Alerts
				</RemovableChip>
			);
		}

		return null;
	}

	render() {
		const {
			notifications,
			orderBy,
			orderByText,
			sortOrder,
			dateRange,
			taskStatus,
			querySearchTerm,
			isLoading,
			showSpinner,
			error,
			hasSubscriptions,
			activeSubscriptions,
			defaultSubscription,
			selectedSubscriptionKey,
			initialRetrievalTime,
			disableTimeSort,
			disableTaskStatus,
			threshold,
			thresholdMessageCleared,
			hasFilters,
			total: { count, thresholdExceeded, sinceDate },
		} = this.props;

		const thresholdMessage =
			thresholdExceeded &&
			!thresholdMessageCleared &&
			(hasFilters ||
				(!isNil(this.dataRef.current) &&
					!isEmpty(this.dataRef.current.get('searchQuery')))) ? (
				<Row gutter="16">
					<Column>
						<BannerMessage
							id="threshold-met-message"
							message={getThresholdExceededText(
								count,
								threshold,
								orderByText,
								sinceDate,
							)}
							color="warn"
							showIcon
							clearButtonOnClick={
								this.handleThresholdMetMessageClear
							}
						/>
					</Column>
				</Row>
			) : null;

		return (
			<>
				<PageTitle pageName="Notifications" />
				<PageContainer
					className="notifications-page-body"
					asCard
					allowScroll
				>
					<PageContainerGroup>
						<Container gutter="16">
							{thresholdMessage}
							<Row gutter="8">
								<Column width={['6', 'content']}>
									<ActionSelector
										id="order-notifications-selector"
										className={classnames(
											'order-by-selector',
											{
												overridden: disableTimeSort,
											},
										)}
										value={orderBy}
										options={ORDER_BY_OPTIONS}
										textWhenOpen="Order by"
										size="xs"
										disabled={
											isLoading ||
											!hasSubscriptions ||
											disableTimeSort
										}
										onChange={this.sortChange}
									/>
								</Column>
								<Column
									width={['fill', 'content']}
									justify={['right', 'left']}
								>
									<ActionSelector
										id="sort-order-selector"
										className="sort-order-selector"
										value={sortOrder}
										options={SORT_ORDER_OPTIONS}
										selectedTextPath="selectedText"
										textWhenOpen="Sort Order"
										size="xs"
										disabled={
											isLoading || !hasSubscriptions
										}
										onChange={this.sortOrderChange}
									/>
								</Column>
								<Column width={['6', 'content']}>
									<ActionSelector
										id="date-range-selector"
										className={classnames(
											'date-range-selector',
											{
												overridden: disableTimeSort,
											},
										)}
										value={dateRange}
										options={DATE_RANGE_OPTIONS}
										textWhenOpen="Date Range"
										size="xs"
										disabled={
											isLoading ||
											!hasSubscriptions ||
											disableTimeSort
										}
										onChange={this.dateRangeChange}
									/>
								</Column>
								<Column
									width={['6', 'content']}
									justify={['right', 'left']}
								>
									<FilterMenu
										isLoading={isLoading}
										hasSubscriptions={hasSubscriptions}
									/>
								</Column>
								<Column
									width={[null, 'static:240']}
									leftOffset={[null, 'fill']}
									order={['-1', '0']}
								>
									<Data
										id="notificationSearchForm"
										ref={this.dataRef}
										baseValue={this.defaultSearch}
										onExecuteStart={handleExecuteStart}
										onExecute={this.handleExecuteSearch}
										validateOnExecute
									>
										<DataTextProperty
											id="notificationSearch"
											placeholder="Search MRN or Name"
											leftIcon={{
												icon: faMagnifyingGlass,
												size: 'xs',
												onClick: this
													.handleSearchIconClick,
											}}
											size="sm"
											path="searchQuery"
											minLength={[
												MIN_SEARCH_INPUT,
												'Minimum 3 characters required.',
											]}
											onKeyDown={this.handleSearchKeyDown}
											onClear={this.handleClearSearch}
											disabled={
												isLoading || !hasSubscriptions
											}
										/>
									</Data>
								</Column>
							</Row>
							<AppliedFilters />
							<Row
								className="subscriber-row"
								gutter={{ v: '16', h: '16' }}
								enableBefore
								align="center"
							>
								<Column width="content">
									<SubscriptionSelector
										activeSubscriptions={
											activeSubscriptions
										}
										defaultSubscription={
											defaultSubscription
										}
										selectedSubscriptionKey={
											selectedSubscriptionKey
										}
										disabled={
											isLoading || !hasSubscriptions
										}
										handleChangeSubscription={
											this.handleChangeSubscription
										}
									/>
								</Column>
								<Column
									width={['fill', 'content']}
									justify="right"
								>
									<ActionSelector
										id="task-status-selector"
										className={classnames(
											'task-status-selector',
											{
												overridden: disableTaskStatus,
											},
										)}
										value={taskStatus}
										options={STATUS_SELECTOR_OPTIONS}
										textWhenOpen="Select Status"
										selectedTextPath="selectedText"
										size="xs"
										disabled={
											isLoading ||
											!hasSubscriptions ||
											disableTaskStatus
										}
										onChange={this.taskStatusChange}
									/>
								</Column>
								<Column width={['12', 'fill']}>
									{this.renderTopPaginationControls()}
								</Column>
							</Row>
						</Container>
						{this.renderNotificationChip()}
					</PageContainerGroup>
					<NotificationsTable
						notifications={notifications}
						querySearchTerm={querySearchTerm}
						isLoading={isLoading}
						hasRetrievedInitial={!isNil(initialRetrievalTime)}
						onEventDetailsClick={this.handleEventDetailsClick}
						error={error}
						hasSubscriptions={hasSubscriptions}
					/>
					<PageContainerGroup>
						{this.renderBottomPaginationControls()}
					</PageContainerGroup>
				</PageContainer>
				<div>
					<ExportStartModal />
					<ExportDownloadModal />
					<EventDetailsModal />
					<NewFilterDrawer />
					<EditFilterDrawer />
					<EditSavedFiltersDrawer />
					<DeleteFilterDrawer />
					{showSpinner ? <Spinner size="lg" variant="page" /> : null}
				</div>
			</>
		);
	}
}

NotificationsPage.defaultProps = {
	filterConfig: null,
	threshold: null,
};

NotificationsPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
	pagination: PropTypes.objectOf(PropTypes.string).isRequired,
	orderBy: PropTypes.string.isRequired,
	orderByText: PropTypes.string.isRequired,
	sortOrder: PropTypes.string.isRequired,
	dateRange: PropTypes.string.isRequired,
	taskStatus: PropTypes.string.isRequired,
	querySearchTerm: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	showSpinner: PropTypes.bool.isRequired,
	pendingTotal: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object.isRequired,
	exportStatus: PropTypes.string.isRequired,
	exportInProgress: PropTypes.bool.isRequired,
	hasSubscriptions: PropTypes.bool.isRequired,
	pageState: PropTypes.string.isRequired,
	total: PropTypes.shape({
		count: PropTypes.number,
		isLoading: PropTypes.bool,
		// eslint-disable-next-line react/forbid-prop-types
		error: PropTypes.any,
		sinceDate: PropTypes.string,
		thresholdExceeded: PropTypes.bool,
	}).isRequired,
	initialRetrievalTime: PropTypes.number.isRequired,
	activeSubscriptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
		.isRequired,
	defaultSubscription: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any))
		.isRequired,
	selectedSubscriptionKey: PropTypes.string.isRequired,
	disableTimeSort: PropTypes.bool.isRequired,
	disableTaskStatus: PropTypes.bool.isRequired,
	filterConfig: filterConfigPropType,
	history: PropTypes.shape({
		replace: PropTypes.func,
	}).isRequired,
	thresholdMessageCleared: PropTypes.bool.isRequired,
	hasFilters: PropTypes.bool.isRequired,
	threshold: PropTypes.number,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

export default connectToStores(
	withRouter(applyFluxibleContext(NotificationsPage)),
	[
		'NotificationStore',
		'ExportStore',
		'Session',
		'FilterStore',
		'SettingsStore',
	],
	context => {
		const notificationStore = context.getStore('NotificationStore');
		const notificationState = notificationStore.getState();
		const exportStore = context.getStore('ExportStore');
		const sessionStore = context.getStore('Session');
		const filterStore = context.getStore('FilterStore');
		const settingsStore = context.getStore('SettingsStore');

		const exportStatus = exportStore.getExportStatus();
		const exportInProgress = exportStore.isInProgress();

		const subscriptions = sessionStore.getSubscriptions();
		const activeSubscriptions = subscriptions.getActiveSubscriptions();
		const defaultSubscription = subscriptions.getDefaultSubscription();
		const orderByText = ORDER_BY_MAP[notificationState.orderBy]?.text;

		return {
			...notificationState,
			exportStatus,
			exportInProgress,
			hasSubscriptions: activeSubscriptions.length > 0,
			activeSubscriptions,
			defaultSubscription,
			disableTimeSort:
				filterStore.hasEventTime() || filterStore.hasReceivedTime(),
			disableTaskStatus: filterStore.hasTaskStatus(),
			filterConfig: filterStore.getConfiguration(),
			thresholdMessageCleared: notificationStore.getThresholdMessageCleared(),
			hasFilters: filterStore.hasFilters(),
			threshold: settingsStore.getThreshold(),
			orderByText,
		};
	},
);

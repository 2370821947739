/* eslint-disable no-underscore-dangle */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Drawer from '@audacious/components/components/Drawer';
import Button from '@audacious/components/components/Button';
import Document, { DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import EditFilterForm from './edit-filter-form';
import {
	filterDataPropType,
	filterConfigPropType,
} from '../../../../common/app-prop-types';
import {
	closeCurrentFilterDrawer,
	startDeleteFilterAction,
} from '../../../../actions/filter-actions';
import DrawerTypes from '../../../../common/drawer-types';

class EditFilterDrawer extends Component {
	constructor(props) {
		super(props);

		this.formRef = createRef();

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleCancel() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeCurrentFilterDrawer);
	}

	handleSave() {
		this.formRef.current.next(true);
	}

	handleDelete() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		const { initialData } = this.props;

		executeAction(startDeleteFilterAction, {
			filterData: initialData,
			plusMore: 1,
		});
	}

	renderBodyContent() {
		const { filterConfig, initialData } = this.props;

		if (filterConfig instanceof Error) {
			return <div>Failed to load filter configuration</div>;
		}

		if (initialData instanceof Error) {
			return <div>Failed to load filter</div>;
		}

		return (
			<EditFilterForm
				ref={this.formRef}
				filterConfig={filterConfig}
				initialData={initialData}
			/>
		);
	}

	render() {
		const { drawerId, filterConfig, initialData, isSaving } = this.props;

		if (drawerId !== DrawerTypes.EDIT) {
			return null;
		}

		return (
			<Drawer
				id="edit-filter-drawer"
				open
				variant="right"
				size="lg"
				isLoading={isNil(filterConfig) || isNil(initialData)}
				isSaving={isSaving}
			>
				<Document size="lg">
					<DocumentBody className="filter-drawer-body">
						{this.renderBodyContent()}
					</DocumentBody>
					<DocumentFooter>
						<ButtonGroup>
							<Button
								id="save-edit-filter-btn"
								color="primary"
								variant="fill"
								onClick={this.handleSave}
							>
								Save
							</Button>
							<Button
								id="cancel-edit-filter-btn"
								color="primary"
								variant="opaque"
								onClick={this.handleCancel}
							>
								Cancel
							</Button>
							<Button
								id="delete-edit-filter-btn"
								className="pull-right"
								color="danger"
								variant="opaque"
								onClick={this.handleDelete}
							>
								Delete
							</Button>
						</ButtonGroup>
					</DocumentFooter>
				</Document>
			</Drawer>
		);
	}
}

EditFilterDrawer.propTypes = {
	isSaving: PropTypes.bool.isRequired,
	drawerId: PropTypes.string,
	filterConfig: filterConfigPropType,
	initialData: filterDataPropType,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

EditFilterDrawer.defaultProps = {
	filterConfig: null,
	initialData: null,
	drawerId: null,
};

export default connectToStores(
	applyFluxibleContext(EditFilterDrawer),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		const currentFilterDrawer = filterStore.getCurrentDrawer();

		return {
			isSaving: filterStore.isSaving(),
			filterConfig: filterStore.getConfiguration(),
			drawerId: get(currentFilterDrawer, 'drawerId'),
			initialData: get(currentFilterDrawer, 'data'),
		};
	},
);

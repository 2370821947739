import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_CARE_NOTES_START');
}

function onSuccess(actionContext, { response }) {
	actionContext.dispatch('RETRIEVE_CARE_NOTES_FINISH', [null, response.data]);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('RETRIEVE_CARE_NOTES_FINISH', [error, null]);
}

export default config => ({
	serviceName: 'retrieveCareNotes',
	config: {
		method: 'GET',
		url: get(config, 'endpoints.prompt.careNotes.retrieve'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Text } from '@audacious/components/components/Typography';

class EventDetailItem extends PureComponent {
	render() {
		const { label, value, labelWidth } = this.props;

		return (
			<div className="event-item-detail">
				<div
					className="event-item-detail-text"
					style={{ width: labelWidth }}
				>
					<Text size="lg" weight="bold">
						{label}
					</Text>
				</div>
				<div className="event-item-detail-text">
					<Text size="lg" weight="normal">
						{!isNil(value) && value.length > 0 ? value : '-'}
					</Text>
				</div>
			</div>
		);
	}
}

EventDetailItem.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	labelWidth: PropTypes.string,
};

EventDetailItem.defaultProps = {
	value: null,
	labelWidth: '200px',
};

export default EventDetailItem;

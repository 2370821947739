import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import CareNoteTable from './care-note-table';
import CareNoteForm from './care-note-form';
import {
	clearCareNotes,
	startCreateNote,
	startEditNote,
	startDeleteNote,
} from '../../../actions/notification-details-actions';
import CareNoteDeleteConfirmation from './care-note-delete-confirmation';

class CareNoteTabContent extends PureComponent {
	constructor(props) {
		super(props);

		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.handleEditNote = this.handleEditNote.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.loadNotes = this.loadNotes.bind(this);
	}

	componentDidMount() {
		this.loadNotes();
	}

	componentWillUnmount() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(clearCareNotes);
	}

	handleCreateNew() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startCreateNote);
	}

	handleEditNote(id) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startEditNote, id);
	}

	handleDeleteNote(id) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startDeleteNote, id);
	}

	loadNotes() {
		const {
			fluxibleContext: {
				service: { retrieveCareNotes },
			},
		} = this.props;

		const { notificationId } = this.props;

		retrieveCareNotes({
			options: {
				notificationId,
			},
		});
	}

	render() {
		const {
			moveAttemptTime,
			error,
			isLoading,
			isSaving,
			noteToUpdate,
			noteToDelete,
			notes,
			startPeriod,
			endPeriod,
			mode,
			notificationId,
			user,
		} = this.props;

		if (mode === 'create' || mode === 'edit') {
			return (
				<CareNoteForm
					onClose={this.handleCloseForm}
					moveAttemptTime={moveAttemptTime}
					notificationId={notificationId}
					isSaving={isSaving}
					noteToUpdate={noteToUpdate}
					error={error}
					user={user}
				/>
			);
		}

		if (mode === 'delete') {
			return (
				<CareNoteDeleteConfirmation
					notificationId={notificationId}
					noteToDelete={noteToDelete}
					onCancel={this.handleCloseForm}
				/>
			);
		}

		return (
			<CareNoteTable
				user={user}
				notificationId={notificationId}
				noteToDelete={noteToDelete}
				onCreateNew={this.handleCreateNew}
				onEditNote={this.handleEditNote}
				onDeleteNote={this.handleDeleteNote}
				error={error}
				isLoading={isLoading}
				notes={notes}
				startPeriod={startPeriod}
				endPeriod={endPeriod}
				onReload={this.loadNotes}
			/>
		);
	}
}

CareNoteTabContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	moveAttemptTime: PropTypes.object,
	notificationId: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	notes: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
			age: PropTypes.string,
			text: PropTypes.string,
			id: PropTypes.string,
			author: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
			}),
		}),
	),
	startPeriod: PropTypes.string.isRequired,
	endPeriod: PropTypes.string.isRequired,
	mode: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	user: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	noteToDelete: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	noteToUpdate: PropTypes.object.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

CareNoteTabContent.defaultProps = {
	moveAttemptTime: null,
	error: null,
	isSaving: false,
	isLoading: false,
	notes: null,
};

export default connectToStores(
	applyFluxibleContext(CareNoteTabContent),
	['CareNotesStore'],
	context => {
		const careNotesStore = context.getStore('CareNotesStore');
		const sessionStore = context.getStore('Session');

		const user = sessionStore.getUser();

		return {
			notes: careNotesStore.getNotes(),
			isLoading: careNotesStore.isLoading(),
			isSaving: careNotesStore.isSaving(),
			noteToUpdate: careNotesStore.getNoteToUpdate(),
			noteToDelete: careNotesStore.getNoteToDelete(),
			error: careNotesStore.getError(),
			startPeriod: careNotesStore.getStartPeriod(),
			endPeriod: careNotesStore.getEndPeriod(),
			mode: careNotesStore.getMode(),
			user,
		};
	},
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import Popover from '@audacious/components/components/Popover';
import Button from '@audacious/components/components/Button';
import { Text } from '@audacious/components/components/Typography';

import './patient-info-tab-content.scss';

function renderEmergencyContacts(contacts) {
	if (!contacts || contacts.length === 0) {
		return <div>-</div>;
	}
	return map(contacts, (contact, index) => {
		const name = get(contact, 'name', '-');
		const relationship = get(contact, 'relationship');
		const relationshipRender = relationship ? (
			<span className="pi-sub-header">{relationship}</span>
		) : null;

		const phoneNumber = get(contact, 'phoneNumber');
		const address = get(contact, 'address');

		const addressRender = address ? (
			<Popover
				anchor={Button}
				anchorProps={{
					className: 'address-btn',
					children: 'Address',
					variant: 'opaque',
					color: 'primary',
				}}
				openOnClick
				openOnHover={false}
			>
				{address}
			</Popover>
		) : null;

		return (
			<div className="emergency-contact" key={index}>
				<div className="name half">
					<span className="pi-bold">{name}</span>
					{relationshipRender}
				</div>
				<div className="secondary half">
					<div className="half">{phoneNumber}</div>
					<div className="half">{addressRender}</div>
				</div>
			</div>
		);
	});
}

class PatientInfoTabContent extends PureComponent {
	render() {
		const { notification } = this.props;

		const emergencyContacts = get(
			notification,
			'formatted.emergencyContacts',
		);

		const race = get(notification, 'formatted.race');
		const ethnicity = get(notification, 'formatted.ethnicity');

		let ethnicityRender = <div>-</div>;

		if (race || ethnicity) {
			ethnicityRender = (
				<>
					<div>{ethnicity}</div>
					<div>{race}</div>
				</>
			);
		}

		return (
			<div className="patient-info-content secondary">
				<div className="pi-section">
					<div className="pi-column half">
						<div className="pi-item">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								SUBSCRIBER MRN
							</Text>
							<div>
								{get(notification, 'formatted.subscriberMRN')}
							</div>
						</div>
						<div className="pi-item">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								FACILITY MRN
							</Text>
							<div>
								{get(notification, 'formatted.facilityMRN')}
							</div>
						</div>
						<div className="pi-item">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								ETHNICITY & RACE
							</Text>
							{ethnicityRender}
						</div>
					</div>
					<div className="pi-column half">
						<div className="pi-item">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								DOB
							</Text>
							<div>
								{get(notification, 'formatted.dateOfBirth')}
							</div>
						</div>
						<div className="pi-item">
							<div>{get(notification, 'formatted.address1')}</div>
							<div>{get(notification, 'formatted.address2')}</div>
							<div>
								{get(notification, 'formatted.cityState')}
							</div>
						</div>
						<div className="pi-item">
							<div>
								<Text
									className="text-full phone-header"
									size="sm"
									weight="bold"
									applyMargin
								>
									H
								</Text>
								{get(notification, 'formatted.homePhone')}
							</div>
							<div>
								<Text
									className="text-full phone-header"
									size="sm"
									weight="bold"
									applyMargin
								>
									W
								</Text>
								{get(notification, 'formatted.workPhone')}
							</div>
						</div>
					</div>
				</div>
				<div className="pi-section">
					<div className="emergency-contacts">
						<div className="pi-section-header">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								EMERGENCY CONTACT
							</Text>
						</div>
						{renderEmergencyContacts(emergencyContacts)}
					</div>
				</div>
				<div className="pi-section">
					<div className="pcp-section">
						<div className="pi-section-header">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								PRIMARY CARE PROVIDER
							</Text>
						</div>
						<div className="pcp">
							<div className="name half">
								<span className="pi-bold">
									{get(notification, 'formatted.pcpName')}
								</span>
							</div>
							<div className="half">
								{get(notification, 'formatted.pcpEmail')}
							</div>
						</div>
					</div>
				</div>
				<div className="pi-section">
					<div className="care-manager-section">
						<div className="pi-section-header">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								CARE MANAGER
							</Text>
						</div>
						<div className="care-manager">
							<div className="pi-bold">
								{get(notification, 'formatted.careManagerName')}
							</div>
							<div>
								{get(
									notification,
									'formatted.careManagerPhone',
								)}
							</div>
							<div>
								{get(
									notification,
									'formatted.careManagerEmail',
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="pi-section">
					<div>
						<div className="pi-section-header">
							<Text
								className="text-full"
								size="sm"
								weight="bold"
								applyMargin
							>
								INSURANCE
							</Text>
						</div>
						<div>
							{get(
								notification,
								'patient.subscriber.insurance',
								'-',
							)}
						</div>
					</div>
				</div>
				<div className="pi-section">
					<div className="patient-enrolled-date">
						<Text
							className="text-full"
							size="sm"
							weight="bold"
							applyMargin
						>
							PATIENT ENROLLED DATE
						</Text>
						<div className="date">
							{get(notification, 'formatted.enrolledDate')}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

PatientInfoTabContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	notification: PropTypes.object.isRequired,
};

export default PatientInfoTabContent;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import formatAge from './common/format-age';
import formatDate from './common/format-date';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

function formatData(data) {
	const patient = get(data, 'patient.publisher');

	return {
		firstName: patient.firstname,
		lastName: patient.lastname,
		gender: genderFormatter(patient.gender)[0],
		age: formatAge(patient.dateofbirth),
		dob: formatDate(patient.dateofbirth),
	};
}

class PatientDetailsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	startLoading() {
		this.setState({
			data: null,
			error: null,
			isLoading: true,
		});
	}

	finishLoading([error, data]) {
		this.setState({
			data: formatData(data),
			error,
			isLoading: false,
		});
	}

	setPatientDetails(details) {
		this.setState({
			data: {
				firstName: details.firstName,
				lastName: details.lastName,
				gender: details.gender,
				dob: formatDate(details.dob),
				age: formatAge(details.dob),
			},
		});
	}

	isLoading() {
		return this.state.isLoading;
	}

	isLoaded() {
		return !isNil(this.state.data);
	}

	getData() {
		return this.state.data;
	}

	getError() {
		return this.state.error;
	}
}

PatientDetailsStore.storeName = 'PatientDetailsStore';
PatientDetailsStore.handlers = {
	RETRIEVE_PATIENT_DETAILS_START: 'startLoading',
	RETRIEVE_PATIENT_DETAILS_FINISH: 'finishLoading',
	SET_PATIENT_DETAILS_FINISH: 'setPatientDetails',
};

export default PatientDetailsStore;

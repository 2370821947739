import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from '@audacious/components/components/Chip';
import { Text } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';
import {
	getEventTypeName,
	getEventTypeColor,
} from '../../../common/event-type-mapping';
import {
	getPatientClassColor,
	getPatientClassName,
} from '../../../common/patient-class-mapping';
import { getAlertTypeName, getAlertColor } from '../../../common/alert-mapping';
import './event-detail.scss';

class EventDetail extends PureComponent {
	render() {
		const {
			eventDate,
			receivedDate,
			eventType,
			patientClass,
			alertType,
			ipVisits,
			erVisits,
		} = this.props;

		const eventTypeName = getEventTypeName(eventType);
		const eventColor = getEventTypeColor(eventType);
		const patientClassName = getPatientClassName(patientClass);
		const patientClassColor = getPatientClassColor(patientClass);
		const alertName = getAlertTypeName(alertType);
		const alertColor = getAlertColor(alertType);

		return (
			<div className="event-detail">
				<Row gutter="16">
					<Column width={[null, '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							EVENT TIME
						</Text>
						<Text size="md">{eventDate}</Text>
					</Column>
					<Column width={[null, '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							RECEIVED TIME
						</Text>
						<Text size="md" weight="normal">
							{receivedDate}
						</Text>
					</Column>
				</Row>
				<Row gutter="16">
					<Column width={[null, '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							EVENT TYPE
						</Text>
						<Chip color={eventColor} size="lg">
							{eventTypeName}
						</Chip>
					</Column>
					<Column width={[null, '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							PATIENT CLASS
						</Text>
						<Chip color={patientClassColor} size="lg">
							{patientClassName}
						</Chip>
					</Column>
					<Column width={[null, '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							ALERT TYPE
						</Text>
						<Chip color={alertColor} size="lg">
							{alertName}
						</Chip>
					</Column>
				</Row>
				<Row gutter="16">
					<Column width={['6', '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							IP VISITS
						</Text>
						<Text size="md" weight="bold">
							{ipVisits}
						</Text>
					</Column>
					<Column width={['6', '4']}>
						<Text
							className="text-full"
							size="md"
							weight="bold"
							applyMargin
						>
							ER VISITS
						</Text>
						<Text size="md" weight="bold">
							{erVisits}
						</Text>
					</Column>
				</Row>
			</div>
		);
	}
}

EventDetail.propTypes = {
	eventDate: PropTypes.string.isRequired,
	receivedDate: PropTypes.string.isRequired,
	eventType: PropTypes.string.isRequired,
	patientClass: PropTypes.string.isRequired,
	alertType: PropTypes.string.isRequired,
	ipVisits: PropTypes.number.isRequired,
	erVisits: PropTypes.number.isRequired,
};

EventDetail.defaultProps = {};

export default EventDetail;

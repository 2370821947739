export default function retrieveNotificationsMock({ resolve }) {
	setTimeout(() => {
		resolve({
			dbExecTime: 200,
			notifications: [
				{
					id: '1819744',
					firstName: 'John',
					lastName: 'Doe',
					middleName: 'E',
					dob: '1926-10-03',
					gender: 'male',
					mrn: 's7YIK4wOZH',
					eventTime: '2020-11-13T20:43:00.000-05:00',
					facility: null,
					eventCode: 'A01',
					ptClass: 'I',
					alertType: 'ADTALERT',
					alertDetailLabel: 'Absences in 30 days',
					alertDetailValue: '5 days',
				},
				{
					id: '1234567',
					firstName: 'Jane',
					lastName: 'Doe',
					middleName: '',
					dob: '1951-05-20',
					gender: 'female',
					mrn: 'qqqIr2u4Wv',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'A02',
					ptClass: 'I',
					alertType: 'COVID-19',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Heart failure',
				},
				{
					id: '7894561',
					firstName: 'Steve',
					lastName: 'White',
					middleName: 'Lee',
					dob: '1980-10-01',
					gender: 'female',
					mrn: '123qweVCCX',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'A03',
					ptClass: 'E',
					alertType: 'HEDIS: FUH',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Alcohol abuse',
				},
				{
					id: '6549873',
					firstName: 'Stacy',
					lastName: 'Smith',
					middleName: 'Q',
					dob: '1992-12-05',
					gender: 'female',
					mrn: 'CSUBS_0481976',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'EMS',
					ptClass: 'AE',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
				{
					id: '1597535',
					firstName: 'Adam',
					lastName: 'Smith',
					middleName: 'B',
					dob: '1991-11-22',
					gender: 'male',
					mrn: 'CSUBS_1587642',
					eventTime: '2020-11-01T01:01:00.000-05:00',
					facility: '',
					eventCode: 'A08',
					ptClass: 'E',
					alertType: 'HEDIS: FUH',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Alcohol abuse',
				},
				{
					id: '9852364',
					firstName: 'Pete',
					lastName: 'Peterson',
					middleName: '',
					dob: '1977-11-05',
					gender: 'male',
					mrn: 'CSUBS_1675892',
					eventTime: '2020-11-13T16:11:00.000-06:00',
					facility: 'test facility',
					eventCode: 'A02',
					ptClass: 'O',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
				{
					id: '1684568',
					firstName: 'Michele',
					lastName: 'Adams',
					middleName: 'N',
					dob: '1970-11-16',
					gender: 'female',
					mrn: 'CSUBS_1658264',
					eventTime: '2019-03-23T09:01:00.000-10:00',
					facility: 'test facility',
					eventCode: 'A01',
					ptClass: 'I',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
				{
					id: '1819744',
					firstName: 'John',
					lastName: 'Doe',
					middleName: 'E',
					dob: '1926-10-03',
					gender: 'male',
					mrn: 's7YIK4wOZH',
					eventTime: '2020-11-13T20:43:00.000-05:00',
					facility: null,
					eventCode: 'A01',
					ptClass: 'I',
					alertType: 'ADTALERT',
					alertDetailLabel: 'Absences in 30 days',
					alertDetailValue: '5 days',
				},
				{
					id: '1234567',
					firstName: 'Jane',
					lastName: 'Doe',
					middleName: '',
					dob: '1951-05-20',
					gender: 'female',
					mrn: 'qqqIr2u4Wv',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'A02',
					ptClass: 'I',
					alertType: 'COVID-19',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Heart failure',
				},
				{
					id: '7894561',
					firstName: 'Steve',
					lastName: 'White',
					middleName: 'Lee',
					dob: '1980-10-01',
					gender: 'female',
					mrn: '123qweVCCX',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'A03',
					ptClass: 'E',
					alertType: 'HEDIS: FUH',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Alcohol abuse',
				},
				{
					id: '6549873',
					firstName: 'Stacy',
					lastName: 'Smith',
					middleName: 'Q',
					dob: '1992-12-05',
					gender: 'female',
					mrn: 'CSUBS_0481976',
					eventTime: '2020-11-13T18:10:00.000-05:00',
					facility: 'test facility',
					eventCode: 'EMS',
					ptClass: 'AE',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
				{
					id: '1597535',
					firstName: 'Adam',
					lastName: 'Smith',
					middleName: 'B',
					dob: '1991-11-22',
					gender: 'male',
					mrn: 'CSUBS_1587642',
					eventTime: '2020-11-01T01:01:00.000-05:00',
					facility: '',
					eventCode: 'A08',
					ptClass: 'E',
					alertType: 'HEDIS: FUH',
					alertDetailLabel: 'Primary Diagnosis',
					alertDetailValue: 'Alcohol abuse',
				},
				{
					id: '9852364',
					firstName: 'Pete',
					lastName: 'Peterson',
					middleName: '',
					dob: '1977-11-05',
					gender: 'male',
					mrn: 'CSUBS_1675892',
					eventTime: '2020-11-13T16:11:00.000-06:00',
					facility: 'test facility',
					eventCode: 'A02',
					ptClass: 'O',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
				{
					id: '1684568',
					firstName: 'Michele',
					lastName: 'Adams',
					middleName: 'N',
					dob: '1970-11-16',
					gender: 'female',
					mrn: 'CSUBS_1658264',
					eventTime: '2019-03-23T09:01:00.000-10:00',
					facility: 'test facility',
					eventCode: 'A01',
					ptClass: 'I',
					alertType: 'High Utilizer',
					alertDetailLabel: 'Patient Complaint',
					alertDetailValue: 'Abdominal Pain',
				},
			],
			total: 3214,
			since: '2020-05-26T20:18:19.000-04:00',
		});
	}, 1000);
}

import get from 'lodash/get';

function onSuccess(actionContext, { response }) {
	actionContext.dispatch('SET_SETTINGS', response.data);
}

export default config => ({
	serviceName: 'retrieveSettings',
	authenticate: true,
	config: {
		method: 'GET',
		url: get(config, 'endpoints.prompt.settings.retrieve'),
	},
	onSuccess,
});

/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Drawer from '@audacious/components/components/Drawer';
import Document, { DocumentHeader, DocumentBody } from '@audacious/components/components/Document';
import { Heading, Text } from '@audacious/components/components/Typography';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import get from 'lodash/get';
import { closeCurrentFilterDrawer } from '../../../../actions/filter-actions';
import SavedFilterListing from './saved-filter-listing';
import './edit-saved-filters-drawer.scss';
import DrawerTypes from '../../../../common/drawer-types';

class EditFilterDrawer extends Component {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeCurrentFilterDrawer);
	}

	render() {
		const { drawerId, savedFilterData, savedFilterId } = this.props;

		if (drawerId !== DrawerTypes.MANAGE) {
			return null;
		}

		if (isNil(savedFilterData) || savedFilterData instanceof Error) {
			return null;
		}

		const message = !isNil(savedFilterId) ? (
			<div className="edit-disabled-message">
				<Text weight="semi-bold" size="md">
					The editing and deleting feature will be disabled for
					filters currently in use.
				</Text>
			</div>
		) : null;

		return (
			<Drawer
				id="edit-saved-filter-drawer"
				className="edit-saved-filters-drawer"
				open
				variant="right"
				size="lg"
				enableCloseButton
				onClose={this.handleClose}
			>
				<Document size="lg">
					<DocumentHeader enableDivider>
						<Heading level="5">Saved Filters</Heading>
					</DocumentHeader>
					<DocumentBody className="filter-drawer-body">
						{message}
						{map(savedFilterData.list, filterData => (
							<SavedFilterListing
								key={filterData._id}
								filterData={filterData}
								savedFilterId={savedFilterId}
							/>
						))}
					</DocumentBody>
				</Document>
			</Drawer>
		);
	}
}

EditFilterDrawer.propTypes = {
	drawerId: PropTypes.string,
	savedFilterData: PropTypes.shape({
		list: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		),
	}),
	savedFilterId: PropTypes.string,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

EditFilterDrawer.defaultProps = {
	savedFilterData: null,
	drawerId: null,
	savedFilterId: null,
};

export default connectToStores(
	applyFluxibleContext(EditFilterDrawer),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		const currentFilterDrawer = filterStore.getCurrentDrawer();

		return {
			savedFilterData: filterStore.getSavedFilterData(),
			savedFilterId: filterStore.getSavedFilterId(),
			drawerId: get(currentFilterDrawer, 'drawerId'),
		};
	},
);

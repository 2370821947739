import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import moment from 'moment';

const timeUnits = ['years', 'months', 'days'];

function formatAge(dob) {
	if (isNil(dob) || dob === '') {
		return '- -';
	}

	let age = null;
	let unit = null;

	forEach(timeUnits, timeUnit => {
		age = moment().diff(dob, timeUnit);

		if (age > 0) {
			unit = timeUnit;
		} else if (timeUnit === 'days') {
			unit = timeUnit;
			age = 1;
		}

		return isNil(unit);
	});

	if (age === 1) {
		unit = unit.substr(0, unit.length - 1);
	}

	return `${age} ${unit}`;
}

export default formatAge;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@audacious/components/components/Button';
import { DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { Paragraph } from '@audacious/components/components/Typography';
import { DataCheckProperty, DataAccess } from '@audacious/components/components/Data';

class ExportAcknowledgement extends PureComponent {
	render() {
		const {
			showAcknowledgeCheck,
			handleCompleteAcknowledge,
			handleCancelClick,
		} = this.props;

		return (
			<>
				<DocumentBody className="export-start-modal-body">
					<div className="acknowledge-paragraph">
						<Paragraph size="sm">
							You are about to download a file containing
							privileged and confidential and/or protected health
							information (PHI) and may be subject to protection
							under the law, including the Health Insurance
							Portability and Accountability Act of 1996, as
							amended (HIPAA).
						</Paragraph>
					</div>
					<div className="acknowledge-paragraph">
						<Paragraph size="sm">
							By clicking the &apos;Download&apos; button, you are
							stating that you are authorized to view this file.
							An audit record will be saved with the information
							below.
						</Paragraph>
					</div>
					<div className="acknowledge-paragraph-bold">
						<Paragraph size="sm" weight="bold">
							Note: If you are a systems administrator, remember
							that downloading this file to your personal device
							is not allowed. You must use an approved device to
							download this file.
						</Paragraph>
					</div>
					{showAcknowledgeCheck ? (
						<div className="acknowledge-check-container">
							<DataCheckProperty
								id="with-label"
								path="acknowledged"
							/>
							<Paragraph
								className="acknowledge-check-label"
								size="sm"
							>
								I have read and understood the terms of
								downloading protected patient information.
							</Paragraph>
						</div>
					) : null}
				</DocumentBody>
				<DocumentFooter sticky={false}>
					{showAcknowledgeCheck ? (
						<ButtonGroup>
							<DataAccess>
								{({
									property: {
										value: { acknowledged },
									},
								}) => (
									<Button
										id="export-export-btn"
										color="primary"
										variant="fill"
										disabled={!acknowledged}
										onClick={handleCompleteAcknowledge}
									>
										Next
									</Button>
								)}
							</DataAccess>
							<Button
								id="cancel-export-btn"
								color="primary"
								variant="opaque"
								onClick={handleCancelClick}
							>
								Cancel
							</Button>
						</ButtonGroup>
					) : (
						<ButtonGroup>
							<Button
								id="export-export-btn"
								color="primary"
								variant="fill"
								onClick={handleCompleteAcknowledge}
							>
								Back
							</Button>
						</ButtonGroup>
					)}
				</DocumentFooter>
			</>
		);
	}
}

ExportAcknowledgement.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	showAcknowledgeCheck: PropTypes.bool.isRequired,
	handleCompleteAcknowledge: PropTypes.func.isRequired,
	handleCancelClick: PropTypes.func.isRequired,
};

export default ExportAcknowledgement;

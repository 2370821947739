/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import inRange from 'lodash/inRange';
import get from 'lodash/get';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import phoneFormatter from '@audacious/web-common/formatters/phoneFormatter';
import formatAge from './common/format-age';
import FieldPaths from '../common/field-paths';

const initialState = {
	openNotification: null,
	openNotificationIndex: null,
	cache: null,
	open: false,
	moveLocked: false,
	isLoading: false,
	error: null,
};

const dateFormatOpts = {
	outFormat: 'MM/DD/YYYY, h:mm a',
	defaultValue: '-',
};

const validDateFormats = [
	moment.ISO_8601,
	'MM/DD/YYYY',
	'MM-DD-YYYY',
	'YYYY-MM-DD',
	'YYYY/MM/DD',
];

function formatEmergencyContacts(contacts) {
	return map(contacts, contact => {
		let phoneNumber = get(contact, 'homephone');
		if (!phoneNumber) {
			phoneNumber = get(contact, 'workphone');
		}
		if (!phoneNumber) {
			phoneNumber = '-';
		}

		phoneNumber = phoneFormatter(phoneNumber, true);
		return {
			...contact,
			phoneNumber,
		};
	});
}

/**
 * Formats a field or subfield. Formats the value if it is a date or empty.
 * @param {Object} field field containing the value to format
 * @returns field including the formatted value
 */
function formatDetailGroupFields(field) {
	const { label, value, subfieldItems } = field;
	let formattedValue = value;

	if (value === '') {
		formattedValue = '-';
	} else if (
		value.length > 6 &&
		moment(value, validDateFormats, true).isValid()
	) {
		// Format as a date if the value matches a valid date format and is long enough
		// (to prevent moment from thinking short numbers are ISO 8601 dates)
		formattedValue = dateFormatter(value, dateFormatOpts);
	}

	const formattedField = {
		label,
		value: formattedValue,
	};

	if (subfieldItems) {
		if (subfieldItems.length > 0) {
			formattedField.subfieldItems = map(subfieldItems, subfields =>
				map(subfields, subfield => formatDetailGroupFields(subfield)),
			);
		} else {
			formattedField.value = '-';
		}
	}

	return formattedField;
}

/**
 * Format configured event details.
 * @param {Array} eventDetailsGroups array of groups of fields to show in the event detail modal
 * @returns eventDetailGroups with formatted values
 */
function formatDetailGroups(eventDetailsGroups) {
	const formatted = map(eventDetailsGroups, group => {
		const fields = map(group.fields, field =>
			formatDetailGroupFields(field),
		);

		return {
			...group,
			fields,
		};
	});

	return formatted;
}

function formatNotificationDetails(notification) {
	const newNotification = cloneDeep(notification);

	const formatted = {};

	formatted.facilityType = get(notification, 'publisher.name', '-');
	formatted.hospitalService = get(
		notification,
		'event.visit.hospitalservice',
		'-',
	);
	formatted.admitSource = get(notification, 'event.visit.admitsource', '-');
	formatted.admitDate = dateFormatter(
		get(notification, 'event.visit.admittime'),
		dateFormatOpts,
	);
	formatted.patientComplaints = get(
		notification,
		'event.visit.patientcomplaint',
		'-',
	);
	formatted.diagnosisCode = get(
		notification,
		'event.visit.diagnosis.0.diagnosiscode',
		'-',
	);
	formatted.diagnosisDesc = get(
		notification,
		'event.visit.diagnosis.0.diagnosisdescription',
		'-',
	);
	formatted.dischargeLocation = get(
		notification,
		'event.visit.dischargetolocation',
		'-',
	);
	formatted.dischargeDisposition = get(
		notification,
		'event.visit.dischargedisposition',
		'-',
	);
	formatted.dischargeDate = dateFormatter(
		get(notification, 'event.visit.dischargetime'),
		dateFormatOpts,
	);
	formatted.deathIndicator = get(
		notification,
		'patient.publisher.deathindicator',
		'-',
	);
	formatted.emergencyContacts = formatEmergencyContacts(
		get(notification, 'patient.publisher.nextofkin', []),
	);
	// formatted.emergencyContacts = formatEmergencyContacts([
	//     {
	//         name: 'John Doe',
	//         relationship: 'father',
	//         address: '555 Main St. Baltimore, MD 21201',
	//         homephone: '5555555555',
	//         workphone: '1234567890',
	//     },
	//     {
	//         name: 'Jane Doe',
	//         relationship: 'mother',
	//         address: '555 Main St. Baltimore, MD 21201',
	//         homephone: '5555555555',
	//         workphone: '1234567890',
	//     },
	// ]);

	formatted.dateOfBirth = get(notification, 'patient.publisher.dateofbirth');
	if (formatted.dateOfBirth) {
		const formattedDate = moment
			.utc(formatted.dateOfBirth)
			.format('MM/DD/YYYY');
		const age = formatAge(formatted.dateOfBirth);
		formatted.dateOfBirth = `${formattedDate}, ${age}`;
	} else {
		formatted.dateOfBirth = '-';
	}

	formatted.race = get(notification, 'patient.publisher.race');
	formatted.ethnicity = get(notification, 'patient.publisher.ethnicgroup');

	const pcpFirstName = get(notification, 'patient.subscriber.pcpfirstname');
	const pcpLastName = get(notification, 'patient.subscriber.pcplastname');

	formatted.pcpName = null;

	if (pcpFirstName || pcpLastName) {
		formatted.pcpName = `${pcpFirstName ?? ''}${
			pcpFirstName && pcpLastName ? ' ' : ''
		}${pcpLastName ?? ''}`;
	}

	formatted.pcpEmail = get(notification, 'patient.subscriber.pcpemail');

	if (formatted.pcpName && !formatted.pcpEmail) {
		formatted.pcpEmail = '-';
	}

	if (!formatted.pcpName) {
		formatted.pcpName = '-';
	}

	let careManagerName = get(
		notification,
		'patient.subscriber.caremanagername',
	);

	let careManagerPhone = get(
		notification,
		'patient.subscriber.caremanagerphone',
	);

	let careManagerEmail = get(
		notification,
		'patient.subscriber.caremanageremail',
	);

	if (careManagerName || careManagerPhone || careManagerEmail) {
		careManagerName = careManagerName || '-';
		careManagerPhone = careManagerPhone || '-';
		careManagerEmail = careManagerEmail || '-';
	} else {
		careManagerName = '-';
	}

	formatted.careManagerName = careManagerName;
	formatted.careManagerPhone = careManagerPhone;
	formatted.careManagerEmail = careManagerEmail;

	formatted.enrolledDate = get(
		notification,
		'patient.subscriber.empanelmentdate',
	);

	if (formatted.enrolledDate) {
		formatted.enrolledDate = dateFormatter(formatted.enrolledDate, {
			outFormat: 'MM/DD/YYYY',
			defaultValue: '-',
		});
	} else {
		formatted.enrolledDate = '-';
	}

	formatted.subscriberMRN = get(
		notification,
		'patient.subscriber.patientid',
		'-',
	);

	formatted.facilityMRN = get(
		notification,
		'patient.publisher.patientid',
		'-',
	);

	formatted.address1 = get(notification, 'patient.publisher.addr1');
	formatted.address2 = get(notification, 'patient.publisher.addr2');

	const city = get(notification, 'patient.publisher.city');
	const state = get(notification, 'patient.publisher.state');
	const zip = get(notification, 'patient.publisher.zipcode');

	formatted.cityState = null;

	if (city && state) {
		formatted.cityState = `${city}, ${state}`;
	} else if (city) {
		formatted.cityState = city;
	} else if (state) {
		formatted.cityState = state;
	}

	if (!isNil(zip) && zip.length > 0) {
		if (!isNil(formatted.cityState)) {
			formatted.cityState = `${formatted.cityState} ${zip}`;
		} else {
			formatted.cityState = zip;
		}
	}

	if (isNil(formatted.cityState)) {
		formatted.cityState = '-';
	}

	formatted.homePhone = phoneFormatter(
		get(notification, 'patient.publisher.homephone', '-'),
		true,
	);

	formatted.workPhone = phoneFormatter(
		get(notification, 'patient.publisher.workphone', '-'),
		true,
	);

	formatted.receivedDate = dateFormatter(
		get(notification, FieldPaths.RECEIVED_TIME),
		dateFormatOpts,
	);
	formatted.eventDate = dateFormatter(
		get(notification, FieldPaths.EVENT_TIME),
		dateFormatOpts,
	);

	const gender = genderFormatter(
		get(notification, 'patient.publisher.gender', ''),
	);
	formatted.gender = gender && gender.length > 0 ? gender[0] : gender;

	formatted.eventDetailsGroups = formatDetailGroups(
		notification.eventDetailsGroups,
	);

	newNotification.formatted = formatted;
	newNotification.isCached = true;

	return newNotification;
}

class NotificationDetailsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	retrieveNotificationsFinish({ results: { notifications } }) {
		const cache = map(notifications, notif => ({
			id: notif?.id,
		}));

		this.setState({ cache });
	}

	findNotificationIndex(notificationId) {
		return findIndex(
			this.state.cache,
			notif => notif.id === notificationId,
		);
	}

	setOpenNotification(notification) {
		this.setState({
			open: true,
			isLoading: false,
			openNotification: notification,
			openNotificationIndex: this.findNotificationIndex(notification.id),
		});
	}

	retrieveNotificationStart(notificationId) {
		this.setState({
			open: true,
			isLoading: true,
			openNotification: null,
			openNotificationIndex: this.findNotificationIndex(notificationId),
		});
	}

	retrieveNotificationFinish(notification) {
		const formattedNotification = formatNotificationDetails(notification);

		formattedNotification.id = toNumber(formattedNotification._id);
		let openNotificationIndex = null;

		const cache = map(this.state.cache, (notif, idx) => {
			if (formattedNotification.id === notif.id) {
				openNotificationIndex = idx;
				return formattedNotification;
			}

			return notif;
		});

		this.setState({
			isLoading: false,
			openNotification: formattedNotification,
			openNotificationIndex,
			cache,
		});
	}

	retrieveNotificationFail(error) {
		this.setState({
			isLoading: false,
			error,
		});
	}

	updateTask({ id, task }) {
		const cachedNotification = this.getNotificationFromCache(id);

		if (!isNil(cachedNotification)) {
			cachedNotification.task = task;
		}

		const { openNotification } = this.state;

		if (isNil(openNotification) || openNotification.id !== id) {
			return;
		}

		this.setState({
			openNotification: {
				...openNotification,
				task,
			},
		});
	}

	setLocked() {
		this.setState({
			moveLocked: true,
		});
	}

	setUnlocked() {
		this.setState({
			moveLocked: false,
		});
	}

	closeNotificationDetails() {
		this.setState({
			...initialState,
			cache: this.state.cache,
		});
	}

	loadNotificationDetails() {
		this.setState({ isLoading: true });
	}

	getNotificationFromCache(notificationId) {
		const cachedDetails = find(
			this.state.cache,
			notif => notif.id === notificationId,
		);

		if (cachedDetails?.isCached) {
			return cachedDetails;
		}

		return null;
	}

	getNextOrPreviousNotificationId(getNext) {
		if (isNil(this.state.openNotification)) {
			return null;
		}

		const newIndex = this.state.openNotificationIndex + (getNext ? 1 : -1);

		if (!inRange(newIndex, this.state.cache?.length)) {
			return null;
		}

		return this.state.cache?.[newIndex]?.id;
	}

	getOpenNotification() {
		return this.state.openNotification;
	}

	getOpenNotificationIndex() {
		return this.state.openNotificationIndex;
	}
}

NotificationDetailsStore.storeName = 'NotificationDetailsStore';
NotificationDetailsStore.handlers = {
	RETRIEVE_NOTIFICATIONS_SUCCESS: 'retrieveNotificationsFinish',
	SET_OPEN_NOTIFICATION: 'setOpenNotification',
	RETRIEVE_NOTIFICATION_DETAILS_START: 'retrieveNotificationStart',
	RETRIEVE_NOTIFICATION_DETAILS_SUCCESS: 'retrieveNotificationFinish',
	RETRIEVE_NOTIFICATION_DETAILS_FAILED: 'retrieveNotificationFail',
	CLOSE_NOTIFICATION_DETAILS: 'closeNotificationDetails',
	LOAD_NOTIFICATION_DETAILS: 'loadNotificationDetails',
	LOCK_EVENT_DETAILS: 'setLocked',
	UNLOCK_EVENT_DETAILS: 'setUnlocked',
	UPDATE_TASK: 'updateTask',
	LOGOUT: 'resetState',
};

export default NotificationDetailsStore;

const items = [
	{
		organization: 'Foo A1',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A2',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A3',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A4',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A5',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A6',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A7',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A8',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A9',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A10',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
	{
		organization: 'Foo A11',
		organizationPhone: 'Foo B',
		careManager: 'Foo C',
		careManagerPhone: 'Foo D',
		pcp: 'Foo E',
	},
];

export default function retrieveCareTeamsMock({ resolve }) {
	setTimeout(() => {
		resolve(items);
	}, 1000);
}

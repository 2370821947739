import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('FILTER_REQUEST_START');
}

function onSuccess(actionContext, callContext) {
	const {
		data,
		response: { data: _id },
	} = callContext;

	actionContext.dispatch('APPEND_SAVED_QUERY', {
		...data,
		_id,
	});

	actionContext.dispatch('FILTER_REQUEST_FINISH', []);
}

function onFailed(actionContext, callContext) {
	const {
		response: { error },
	} = callContext;

	actionContext.dispatch('FILTER_REQUEST_FINISH', [error]);
}

export default config => ({
	serviceName: 'createFilter',
	authenticate: true,
	config: {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.filters.create'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Document, { DocumentBody } from '@audacious/components/components/Document';
import Button from '@audacious/components/components/Button';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import {
	cancelDeleteAndReturnToEdit,
	unsetNoteToBeDeleted,
} from '../../../actions/notification-details-actions';

class CareNoteDeleteConfirmation extends PureComponent {
	constructor(props) {
		super(props);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.state = {};
	}

	handleCancel() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(cancelDeleteAndReturnToEdit);
	}

	handleDelete() {
		const { noteToDelete, notificationId } = this.props;
		const {
			fluxibleContext: {
				service: { deleteCareNote },
			},
		} = this.props;

		deleteCareNote({
			options: {
				careNoteId: noteToDelete.id,
				notificationId,
			},
			data: {},
		});

		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(unsetNoteToBeDeleted);
	}

	render() {
		return (
			<Document className="care-delete-confirmation" size="sm">
				<DocumentBody>
					<div className="care-delete-margin">
						<h3>Are you sure?</h3>
						<p>Once the note is deleted, you can’t undo it.</p>
					</div>
					<ButtonGroup>
						<>
							<Button
								id="deleteYes"
								aria-label="Proceed to delete care note"
								color="danger"
								variant="fill"
								onClick={this.handleDelete}
							>
								Delete
							</Button>
							<Button
								id="deleteNo"
								color="primary"
								variant="opaque"
								className="modal-cancel"
								textOnly
								aria-label="Cancel delete carenote"
								onClick={this.handleCancel}
							>
								Cancel
							</Button>
						</>
					</ButtonGroup>
				</DocumentBody>
			</Document>
		);
	}
}

CareNoteDeleteConfirmation.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	noteToDelete: PropTypes.object,
	notificationId: PropTypes.string.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

CareNoteDeleteConfirmation.defaultProps = {
	noteToDelete: null,
};

export default applyFluxibleContext(CareNoteDeleteConfirmation);

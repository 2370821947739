import clone from 'lodash/clone';
import map from 'lodash/map';

export const STATUS_MESSAGES = {
	none: 'Notification Status',
	notStarted: 'Not Started',
	inProgress: 'In Progress',
	completed: 'Completed',
};

export const STATUS_OPTIONS = [
	{ key: 'notStarted', text: STATUS_MESSAGES.notStarted, color: 'secondary' },
	{ key: 'inProgress', text: STATUS_MESSAGES.inProgress, color: 'warn' },
	{ key: 'completed', text: STATUS_MESSAGES.completed, color: 'success' },
];

export const STATUS_SELECTOR_OPTIONS = map(STATUS_OPTIONS, opt => ({
	...opt,
	selectedText: opt.text,
}));

STATUS_SELECTOR_OPTIONS.unshift({
	key: 'all',
	text: 'All',
	selectedText: 'Status: All',
});

export const STATUS_OPTIONS_NOT_SET = clone(STATUS_OPTIONS);
STATUS_OPTIONS_NOT_SET.unshift({ key: 'none', text: 'None' });

export const NOTIF_STATUS_CODE_MAPPING = {
	notStarted: 0,
	inProgress: 1,
	completed: 2,
};

import get from 'lodash/get';

export default config => ({
	serviceName: 'retrieveFilter',
	authenticate: true,
	config: {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.filters.filter.retrieve'),
	},
});

/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { SubMenu, MenuButton } from '@audacious/components/components/Menu';
import { applySavedFilter } from '../../../../actions/filter-actions';

class SavedFiltersMenuItem extends Component {
	constructor(props) {
		super(props);

		this.handleSavedFilterClick = this.handleSavedFilterClick.bind(this);
	}

	handleSavedFilterClick({ meta }) {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(applySavedFilter, meta._id);
	}

	render() {
		const { savedFilterList } = this.props;

		return (
			<SubMenu
				id="saved-filters-menu-item"
				content="Saved Filters"
				borderBottom
			>
				{map(savedFilterList, savedFilter => (
					<MenuButton
						id={`saved-filter-${savedFilter._id}-menu-item`}
						key={savedFilter._id}
						onClick={this.handleSavedFilterClick}
						onClickMeta={savedFilter}
					>
						{savedFilter.name}
					</MenuButton>
				))}
			</SubMenu>
		);
	}
}

SavedFiltersMenuItem.propTypes = {
	savedFilterList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	),
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

SavedFiltersMenuItem.defaultProps = {
	savedFilterList: null,
};

export default applyFluxibleContext(SavedFiltersMenuItem);

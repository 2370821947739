import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('FILTER_REQUEST_START');
}

function onSuccess(actionContext, callContext) {
	const {
		options: { filterId, plusMore },
	} = callContext;

	actionContext.dispatch('REMOVE_SAVED_QUERY', filterId);
	actionContext.dispatch('CLOSE_CURRENT_FILTER_DRAWER', plusMore);

	actionContext.dispatch('FILTER_REQUEST_FINISH', []);
}

function onFailed(actionContext, callContext) {
	const {
		response: { error },
	} = callContext;

	actionContext.dispatch('FILTER_REQUEST_FINISH', [error]);
}

export default config => ({
	serviceName: 'deleteFilter',
	authenticate: true,
	config: {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.filters.filter.delete'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
});

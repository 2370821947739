import isNil from 'lodash/isNil';
import find from 'lodash/find';
import moment from 'moment';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

const initialState = {
	notes: null,
	error: null,
	isLoading: false,
	isSaving: false,
	startPeriod: null,
	endPeriod: null,
	noteToUpdate: null,
	noteToDelete: null,
	mode: 'view',
};

function processNotes(notes) {
	const sorted = orderBy(notes, ['createdAt'], ['desc']);

	return map(sorted, note => {
		const {
			createdAt,
			createdBy,
			noteContent,
			id,
			author: { firstName, lastName },
			deletedAt,
			updatedAt,
		} = note;

		const deleted = !isNil(deletedAt);
		const updated =
			isNil(deletedAt) && !isNil(updatedAt) && updatedAt !== createdAt;
		const today = moment().startOf('day');
		const noteDay = moment(createdAt).startOf('day');

		let age = today.diff(noteDay, 'days');

		if (age < 1) {
			age = 'Today';
		} else if (age === 1) {
			age = `${age} day`;
		} else {
			age = `${age} days`;
		}

		return {
			date: noteDay.format('MM/DD/YYYY'),
			age,
			text: noteContent,
			id,
			author: {
				firstName,
				lastName,
			},
			createdBy,
			updated,
			updatedDate: moment(createdAt).format('MM/DD/YYYY'),
			deleted,
		};
	});
}

class CareNotesStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	retrieveStart() {
		this.setState({
			notes: null,
			startPeriod: null,
			endPeriod: null,
			error: null,
			isLoading: true,
			isSaving: false,
			mode: 'view',
		});
	}

	retrieveFinish([error, notes]) {
		// const { notes, startPeriod, endPeriod } = noteData || {};

		const now = new Date();
		const endPeriod = moment(now).format('MM/DD/YYYY');

		now.setMonth(now.getMonth() - 3);
		const startPeriod = moment(now).format('MM/DD/YYYY');

		this.setState({
			notes: processNotes(notes),
			startPeriod,
			endPeriod,
			error: !isNil(error) ? new Date() : null,
			isLoading: false,
			isSaving: false,
		});
	}

	createStart() {
		this.setState({
			error: null,
			isLoading: false,
			isSaving: true,
		});
	}

	createFinish([error]) {
		if (!isNil(error)) {
			// eslint-disable-next-line no-param-reassign
			error.timestamp = new Date();
		}

		const newState = {
			error,
			isLoading: false,
			isSaving: false,
		};

		if (isNil(error)) {
			newState.notes = null;
			newState.startPeriod = null;
			newState.endPeriod = null;
			newState.mode = 'view';
		}

		this.setState(newState);
	}

	setMode(mode) {
		this.setState({
			mode,
			error: null,
		});
	}

	setNoteToUpdate(noteId) {
		const noteToUpdate = find(this.state.notes, ({ id }) => id === noteId);
		this.setState({
			noteToUpdate,
		});
	}

	setNoteToDelete(noteId) {
		const noteToDelete = find(this.state.notes, ({ id }) => id === noteId);
		this.setState({
			noteToDelete,
		});
	}

	startCreateCareNote() {
		this.setNoteToUpdate(null);
		this.setMode('create');
	}

	startEditCareNote({ noteId, mode }) {
		this.setNoteToUpdate(noteId);
		this.setMode(mode);
	}

	startDeleteCareNote({ noteId, mode }) {
		this.setNoteToDelete(noteId);
		this.setMode(mode);
	}

	cancelDeleteCareNote(mode) {
		this.setNoteToDelete(null);
		this.setMode(mode);
	}

	cancelCreateCareNote(mode) {
		this.setNoteToUpdate(null);
		this.setMode(mode);
	}

	getNoteToDelete() {
		return this.state.noteToDelete;
	}

	getNoteToUpdate() {
		return this.state.noteToUpdate;
	}

	isLoading() {
		return this.state.isLoading;
	}

	isSaving() {
		return this.state.isSaving;
	}

	isLoaded() {
		return !isNil(this.state.notes);
	}

	getNotes() {
		return this.state.notes;
	}

	getError() {
		return this.state.error;
	}

	getStartPeriod() {
		return this.state.startPeriod;
	}

	getEndPeriod() {
		return this.state.endPeriod;
	}

	getMode() {
		return this.state.mode;
	}
}

CareNotesStore.storeName = 'CareNotesStore';
CareNotesStore.handlers = {
	RETRIEVE_CARE_NOTES_START: 'retrieveStart',
	RETRIEVE_CARE_NOTES_FINISH: 'retrieveFinish',
	CARE_NOTE_REQUEST_START: 'createStart',
	CARE_NOTE_REQUEST_FINISH: 'createFinish',
	CLEAR_CARE_NOTES: 'resetState',
	START_CREATE_CARE_NOTE: 'startCreateCareNote',
	SET_CARE_NOTE_TO_DELETE: 'setNoteToDelete',
	START_EDIT_CARE_NOTE: 'startEditCareNote',
	START_DELETE_CARE_NOTE: 'startDeleteCareNote',
	CANCEL_DELETE_CARE_NOTE: 'cancelDeleteCareNote',
	CANCEL_CREATE_CARE_NOTE: 'cancelCreateCareNote',
	LOGOUT: 'resetState',
};

export default CareNotesStore;

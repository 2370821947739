/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DocumentBody } from '@audacious/components/components/Document';
import TabGroup from '@audacious/components/components/TabGroup';
import EventDetailsTabContent from './event-details-tab-content';
import PatientInfoTabContent from './patient-info-tab-content/patient-info-tab-content';
import EventHistoryTabContent from './event-history/event-history-tab-content';
import CareNoteTabContent from './care-note-tab/care-note-tab-content';
import DocumentsTabContent from './documents/documents-tab-content';

class EventDetailsBody extends PureComponent {
	constructor(props) {
		super(props);

		this.handleMove = this.handleMove.bind(this);
	}

	handleMove() {
		const { moveLocked, onMoveAttempt } = this.props;

		if (moveLocked) {
			onMoveAttempt();

			return false;
		}

		return true;
	}

	render() {
		const {
			notification,
			moveAttemptTime,
			hasCareNotesEntitlement,
			documentsEnabled,
		} = this.props;

		const tabs = [
			{
				id: 'details',
				heading: 'Event Details',
				body: <EventDetailsTabContent notification={notification} />,
			},
			{
				id: 'info',
				heading: 'Patient Info',
				body: <PatientInfoTabContent notification={notification} />,
			},
			{
				id: 'history',
				heading: 'Event History',
				body: (
					<EventHistoryTabContent notificationId={notification._id} />
				),
			},
		];

		if (hasCareNotesEntitlement)
			tabs.splice(2, 0, {
				id: 'notes',
				heading: 'CARE NOTE',
				body: (
					<CareNoteTabContent
						moveAttemptTime={moveAttemptTime}
						notificationId={notification._id}
					/>
				),
			});

		if (documentsEnabled) {
			tabs.push({
				id: 'documents',
				heading: 'Documents',
				body: <DocumentsTabContent notificationId={notification._id} />,
			});
		}

		return (
			<DocumentBody className="event-details-body">
				<TabGroup
					key={notification?.id}
					tabs={tabs}
					border
					onMove={this.handleMove}
				/>
			</DocumentBody>
		);
	}
}

EventDetailsBody.propTypes = {
	notification: PropTypes.shape({
		id: PropTypes.number,
		_id: PropTypes.string,
		event: PropTypes.shape({
			date: PropTypes.string,
			adttype: PropTypes.string,
			adtpatientclass: PropTypes.string,
			alerttypecode: PropTypes.string,
		}).isRequired,
		metadata: PropTypes.shape({
			erdischargecount: PropTypes.number.isRequired,
			ipdischargecount: PropTypes.number.isRequired,
			receiveddate: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	moveLocked: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	moveAttemptTime: PropTypes.object,
	onMoveAttempt: PropTypes.func.isRequired,
	hasCareNotesEntitlement: PropTypes.bool.isRequired,
	documentsEnabled: PropTypes.bool.isRequired,
};

EventDetailsBody.defaultProps = {
	moveAttemptTime: null,
};

export default EventDetailsBody;

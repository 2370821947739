import PropTypes from 'prop-types';

export const filterConfigItemPropType = PropTypes.shape({
	path: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	operators: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.string.isRequired,
	allowMultipleValues: PropTypes.bool,
	allowCustomValue: PropTypes.bool,
	valueOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	),
});

export const filterConfigPropType = PropTypes.shape({
	list: PropTypes.arrayOf(filterConfigItemPropType),
	lookup: PropTypes.objectOf(filterConfigItemPropType),
	totalPossible: PropTypes.number,
});

export const filterDataPropType = PropTypes.arrayOf(
	PropTypes.shape({
		var: PropTypes.string,
		op: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.arrayOf(PropTypes.string),
		]),
		valueType: PropTypes.string,
	}),
);

import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_NOTIFICATIONS_START');
}

function onSuccess(
	actionContext,
	{ response, params = {}, options = {}, data = {} },
) {
	const wrapper = document.getElementById('page-wrapper');
	if (wrapper) {
		wrapper.scrollTo(0, 0);
	}

	actionContext.dispatch('RETRIEVE_NOTIFICATIONS_SUCCESS', {
		...options,
		results: response.data,
		page: params.page,
		pageSize: params.pagesize,
		querySearchTerm: data.nameMrn,
		selectedSubscriptionKey: data.query.expressions[0].value[0],
	});
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('RETRIEVE_NOTIFICATIONS_FAILED', error);
}

export default config => ({
	serviceName: 'retrieveNotifications',
	authenticate: true,
	config: {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.notifications.search'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

import isNil from 'lodash/isNil';

export default class Poller {
	/**
	 * @param {*} context this is passed to onStop and onPoll.
	 * @param {number} interval interval to poll in milliseconds
	 * @param {function} onStop called when the polling timeout is cleared
	 * @param {function} onPoll called after the interval
	 */
	constructor(context, interval, onStop, onPoll) {
		this.context = context;
		this.interval = interval;
		this.baseInterval = interval;
		this.onStop = onStop;
		this.onPoll = onPoll;
	}

	setOnStop(onStop) {
		this.onStop = onStop;
	}

	setOnPoll(onPoll) {
		this.onPoll = onPoll;
	}

	setInterval(interval) {
		this.interval = interval;
	}

	stop() {
		if (!isNil(this.timerId)) {
			clearTimeout(this.timerId);
			this.timerId = null;

			this.onStop(this.context);
		}
	}

	reset() {
		this.interval = this.baseInterval;

		this.stop();
		this.startPoll();
	}

	startPoll() {
		this.timerId = setTimeout(() => {
			this.onPoll(this.context);
		}, this.interval);
	}
}

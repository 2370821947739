/* eslint-disable react/no-access-state-in-setstate */
import moment from 'moment';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import {
	getEventTypeName,
	getEventTypeColor,
} from '../common/event-type-mapping';
import {
	getPatientClassColor,
	getPatientClassName,
} from '../common/patient-class-mapping';

const initialState = {
	eventHistoryEvents: null,
	documentEvents: null,
	error: null,
	isLoading: false,
	hasLoadedSuccessfully: false,
	fromDate: null,
	toDate: null,
};

function formatEventHistoryEvent(event) {
	const {
		eventDate,
		patientClass,
		eventType,
		sourceName,
		sourceLocationName,
	} = event;

	const parsedTime = moment(eventDate);

	return {
		...event,
		formatted: {
			eventDate: parsedTime.format('MM/DD/YYYY'),
			eventTime: parsedTime.format('h:mm a'),
			patientClassName: getPatientClassName(patientClass),
			patientClassColor: getPatientClassColor(patientClass),
			eventType: getEventTypeName(eventType),
			eventColor: getEventTypeColor(eventType),
			facilityName: !isEmpty(sourceLocationName)
				? sourceLocationName
				: sourceName,
		},
	};
}

function formatDocumentEvent(event) {
	const {
		serviceStartTime,
		serviceStopTime,
		authorName,
		authorDetail,
	} = event;

	let authorLine1 = null;
	if (!isEmpty(authorName) || !isEmpty(authorDetail)) {
		authorLine1 = `${authorName ?? ''}${
			!isEmpty(authorName) && !isEmpty(authorDetail) ? ', ' : ''
		}${authorDetail ?? ''}`;
	}

	return {
		...event,
		formatted: {
			authorLine1,
			serviceStartTime: moment(serviceStartTime).format(
				'MM/DD/YYYY, h:mm a',
			),
			serviceStopTime: moment(serviceStopTime).format(
				'MM/DD/YYYY, h:mm a',
			),
		},
	};
}

class PatientEventsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	retrieveEventsStart() {
		this.setState({
			isLoading: true,
			error: null,
			hasLoadedSuccessfully: false,
		});
	}

	retrieveEventsFinish([error, args]) {
		if (!isNil(error)) {
			this.setState({
				error,
				isLoading: false,
			});
			return;
		}

		const { events, numLookbackDays } = args;
		const eventHistoryEvents = [];
		const documentEvents = [];

		forEach(events, event => {
			if (event.messageType === 'ARCHERDOC') {
				documentEvents.push(formatDocumentEvent(event));
				return;
			}

			eventHistoryEvents.push(formatEventHistoryEvent(event));
		});

		const today = moment();
		const past = moment(today).subtract(numLookbackDays, 'days');

		this.setState({
			...initialState,
			eventHistoryEvents,
			documentEvents,
			error: null,
			hasLoadedSuccessfully: true,
			fromDate: past.format('MM/DD/YYYY'),
			toDate: today.format('MM/DD/YYYY'),
		});
	}
}

PatientEventsStore.storeName = 'PatientEventsStore';
PatientEventsStore.handlers = {
	RETRIEVE_EVENTS_START: 'retrieveEventsStart',
	RETRIEVE_EVENTS_FINISH: 'retrieveEventsFinish',
	CLOSE_NOTIFICATION_DETAILS: 'resetState',
	RETRIEVE_NOTIFICATION_DETAILS_START: 'resetState',
	SET_OPEN_NOTIFICATION: 'resetState',
	LOGOUT: 'resetState',
};

export default PatientEventsStore;

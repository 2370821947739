import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Data from '@audacious/components/components/Data';
import Modal from '@audacious/components/components/Modal';
import Document, { DocumentHeader } from '@audacious/components/components/Document';
import { SubHeading } from '@audacious/components/components/Typography';
import ExportAcknowledgement from './export-acknowledgement';
import ExportOptions from './export-options';
import { exportStart, cancelExport } from '../../actions/export-actions';

import './export-start-modal.scss';

function handleExecuteStart({ acknowledged }, results) {
	if (!isNil(results) || !acknowledged) {
		return false;
	}

	return true;
}

class ExportStartModal extends PureComponent {
	constructor(props) {
		super(props);

		this.handleCompleteAcknowledge = this.handleCompleteAcknowledge.bind(
			this,
		);
		this.handleViewAcknowledgement = this.handleViewAcknowledgement.bind(
			this,
		);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleExecuteExport = this.handleExecuteExport.bind(this);

		this.initialState = {
			curView: 'acknowledgement',
			showAcknowledgeCheck: true,
		};

		this.state = { ...this.initialState };

		this.dataRef = createRef();
	}

	handleCompleteAcknowledge() {
		this.setState({ curView: 'options', showAcknowledgeCheck: false });
	}

	handleViewAcknowledgement() {
		this.setState({ curView: 'acknowledgement' });
	}

	handleCancelClick() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(cancelExport, () => {
			this.setState(this.initialState);
		});
	}

	handleExecuteExport(exportOptions) {
		const { exportTimeFilterApplied, configuration, orderBy } = this.props;
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		this.initialState = { ...this.state };

		executeAction(exportStart, {
			exportOptions,
			configuration,
			exportTimeFilterApplied,
			orderBy,
		});
	}

	render() {
		const { exportOptions, exportOptionsOpen } = this.props;
		const { curView, showAcknowledgeCheck } = this.state;

		if (!exportOptionsOpen) {
			return null;
		}

		return (
			<Modal
				id="export-start-modal"
				open
				variant="modal"
				size="md"
				enableCloseOnOffClick
			>
				<Data
					id="exportForm"
					ref={this.dataRef}
					baseValue={exportOptions}
					onExecuteStart={handleExecuteStart}
					onExecute={this.handleExecuteExport}
					validateOnBlur
					validateOnExecute
				>
					<Document className="export-start-modal" size="lg">
						<DocumentHeader>
							<SubHeading level="5">Export</SubHeading>
						</DocumentHeader>
						{curView === 'acknowledgement' ? (
							<ExportAcknowledgement
								handleCompleteAcknowledge={
									this.handleCompleteAcknowledge
								}
								showAcknowledgeCheck={showAcknowledgeCheck}
								handleCancelClick={this.handleCancelClick}
							/>
						) : (
							<ExportOptions
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...this.props}
								dataRef={this.dataRef}
								handleViewAcknowledgement={
									this.handleViewAcknowledgement
								}
								handleCancelClick={this.handleCancelClick}
							/>
						)}
					</Document>
				</Data>
			</Modal>
		);
	}
}

ExportStartModal.propTypes = {
	exportOptionsOpen: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	exportOptions: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	configuration: PropTypes.object,
	exportTimeFilterApplied: PropTypes.bool.isRequired,
	orderBy: PropTypes.string.isRequired,
	maxSize: PropTypes.number,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

ExportStartModal.defaultProps = {
	exportOptions: null,
	configuration: null,
	maxSize: null,
};

export default connectToStores(
	applyFluxibleContext(ExportStartModal),
	['ExportStore', 'NotificationStore', 'SettingsStore'],
	context => {
		const exportStore = context.getStore('ExportStore');
		const settingsStore = context.getStore('SettingsStore');

		return {
			...exportStore.getState(),
			maxSize: settingsStore.getSettings()?.export?.maxSize,
		};
	},
);

import Symbol from 'es6-symbol';
import { ObjectCollection } from '@audacious/client';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import clone from 'lodash/clone';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import pullAt from 'lodash/pullAt';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import { getDateDisplayValue } from '../common/filter-utils';

const FIELDS = {
	filterConfigs: Symbol('filterConfigs'),
	name: Symbol('name'),
	filters: Symbol('filters'),
	hasOverridden: Symbol('hasOverridden'),
	totalDisabled: Symbol('totalDisabled'),
};

function getDisplayValue(optionsLookup, value) {
	return get(optionsLookup, [value, 'label'], value);
}

function buildDateData(valueType, value) {
	return {
		value,
		displayValue: getDateDisplayValue(valueType, value),
		enabled: true,
		overridden: false,
	};
}

function buildValueData(optionsLookup, value) {
	return {
		value,
		displayValue: getDisplayValue(optionsLookup, value),
		enabled: true,
		overridden: false,
	};
}

function processFilter(filterConfigs, filter) {
	const { lookup } = filterConfigs;

	const { var: path, value, valueType, op: operator } = filter;

	const filterConfig = lookup[path];

	if (isNil(filterConfig)) {
		return undefined;
	}

	const { allowMultipleValues } = filterConfig;

	const { name, type: definitionValueType, optionsLookup } = lookup[path];

	let wasSingle = false;
	let valueData = null;

	if (definitionValueType === 'date') {
		wasSingle = true;
		valueData = [buildDateData(valueType, value)];
	} else if (isArray(value)) {
		if (allowMultipleValues) {
			valueData = map(value, item => buildValueData(optionsLookup, item));
		} else {
			wasSingle = true;
			valueData = [buildValueData(optionsLookup, value[0])];
		}
	} else {
		valueData = [buildValueData(optionsLookup, value)];

		if (!allowMultipleValues) {
			wasSingle = true;
		}
	}

	return {
		id: `${path}:${operator}`,
		name,
		valueData,
		wasSingle,
		path,
		operator,
		valueType,
		isOverridden: false,
		totalEnabled: valueData.length,
	};
}

function processFilters(filterConfigs, filters) {
	const getId = filter => filter.id;
	const onLoad = filter => processFilter(filterConfigs, filter);

	return new ObjectCollection(filters, getId, {
		onLoad,
		ignoreNil: true,
	});
}

function resetOverrides(filter) {
	// eslint-disable-next-line no-param-reassign
	filter.isOverridden = false;

	forEach(filter.valueData, valueItem => {
		// eslint-disable-next-line no-param-reassign
		valueItem.overridden = false;
	});
}

function buildExpression(filter) {
	const { path, operator, wasSingle, valueData, valueType } = filter;

	const value = reduce(
		valueData,
		(valuesAcc, valueItem) => {
			if (valueItem.enabled && !valueItem.overridden) {
				valuesAcc.push(valueItem.value);
			}

			return valuesAcc;
		},
		[],
	);

	if (value.length <= 0) {
		return null;
	}

	return {
		var: path,
		op: operator,
		value: wasSingle ? value[0] : value,
		valueType,
	};
}

class FilterGroup {
	constructor(filterConfigs, name, filters) {
		this[FIELDS.filterConfigs] = filterConfigs;
		this[FIELDS.name] = name;
		this[FIELDS.filters] = processFilters(filterConfigs, filters);
		this[FIELDS.hasOverridden] = false;
		this[FIELDS.totalDisabled] = 0;
	}

	removeFilterValue(key, valueIndex) {
		const filter = this[FIELDS.filters].getById(key);

		if (isNil(filter)) {
			return;
		}

		pullAt(filter.valueData, [valueIndex]);

		if (filter.valueData.length <= 0) {
			this[FIELDS.filters].removeBy(key);
		} else {
			filter.valueData = clone(filter.valueData);
		}
	}

	toggleFilter(key, valueIndex, enabled) {
		const filter = this[FIELDS.filters].getById(key);

		if (isNil(filter)) {
			return;
		}

		// This is just in case something else slips through.
		// There is an equal ahead and I want to make sure it works.
		const normalizeEnabled = !!enabled;
		const adder = enabled ? 1 : -1;
		let totalAdder = 0;

		const check = valueItem => {
			if (valueItem.enabled !== normalizeEnabled) {
				// eslint-disable-next-line no-param-reassign
				valueItem.enabled = normalizeEnabled;

				filter.totalEnabled += adder;
				totalAdder += adder;
			}
		};

		if (isNil(valueIndex)) {
			forEach(filter.valueData, check);
		} else {
			check(filter.valueData[valueIndex]);
		}

		this[FIELDS.totalDisabled] -= totalAdder;
	}

	getFilter(key) {
		return this[FIELDS.filters].getById(key);
	}

	getFilters() {
		return this[FIELDS.filters].getData();
	}

	getName() {
		return this[FIELDS.name];
	}

	setOverride(overrideBy) {
		let isOverridden = false;

		let usedExclusionSets = [];

		if (!isNil(overrideBy)) {
			usedExclusionSets = overrideBy
				.getFilters()
				.reduce((acc, filterOverride) => {
					const exclusionSets = get(this[FIELDS.filterConfigs], [
						'lookup',
						filterOverride.path,
						'exclusionSet',
					]);

					return !isNil(exclusionSets)
						? acc.concat(exclusionSets)
						: acc;
				}, []);
		}

		this[FIELDS.filters].forEach(filter => {
			if (isNil(overrideBy)) {
				resetOverrides(filter);

				return;
			}

			const exclusionSets = get(this[FIELDS.filterConfigs], [
				'lookup',
				filter.path,
				'exclusionSet',
			]);

			let exclude = false;
			let isFilterOverridden = false;

			forEach(exclusionSets, exclusionSet => {
				const matchingSetIndex = findIndex(
					usedExclusionSets,
					usedExclusionSet => usedExclusionSet === exclusionSet,
				);

				exclude = matchingSetIndex >= 0;

				return !exclude;
			});

			const { valueData, wasSingle } = filter;

			if (exclude) {
				valueData[0].overridden = true;

				isFilterOverridden = true;
			} else {
				const overrideFilter = overrideBy.getFilter(filter.id);

				if (isNil(overrideFilter)) {
					resetOverrides(filter);

					return;
				}

				if (wasSingle) {
					valueData[0].overridden = true;

					isFilterOverridden = true;
				} else {
					forEach(valueData, savedValueItem => {
						const lowerSavedValue = toLower(savedValueItem.value);

						const index = findIndex(
							overrideFilter.valueData,
							overrideValueItem =>
								toLower(overrideValueItem.value) ===
								lowerSavedValue,
						);

						// eslint-disable-next-line no-param-reassign
						savedValueItem.overridden = index >= 0;

						isFilterOverridden =
							isFilterOverridden || savedValueItem.overridden;
					});
				}
			}

			// eslint-disable-next-line no-param-reassign
			filter.isOverridden = isFilterOverridden;

			isOverridden = isOverridden || isFilterOverridden;
		});

		this[FIELDS.hasOverridden] = isOverridden;
	}

	getExpressions() {
		return this[FIELDS.filters].reduce((acc, filter) => {
			const expression = buildExpression(filter);

			if (!isNil(expression)) {
				acc.push(buildExpression(filter));
			}

			return acc;
		}, []);
	}

	hasOverridden() {
		return this[FIELDS.hasOverridden];
	}

	hasDisabled() {
		return this[FIELDS.totalDisabled] > 0;
	}
}

export default FilterGroup;

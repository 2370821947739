import get from 'lodash/get';
import isNil from 'lodash/isNil';

function beforeRequest(actionContext) {
	actionContext.dispatch('RETRIEVE_PATIENT_DETAILS_START');
}

function onSuccess(actionContext, { response }) {
	const code = get(response, 'data.code');

	let error = null;
	let notification = get(response, 'data.notification');

	if (code !== 'SUCCESS') {
		error = code;
		notification = null;
	} else if (isNil(notification)) {
		error = 'MISSING NOTIFICATION';
		notification = null;
	}

	actionContext.dispatch('RETRIEVE_PATIENT_DETAILS_FINISH', [
		error,
		notification,
	]);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('RETRIEVE_PATIENT_DETAILS_FINISH', [error, null]);
}

export default config => ({
	serviceName: 'retrievePatientDetails',
	authenticate: true,
	config: {
		method: 'GET',
		url: get(config, 'endpoints.prompt.notifications.details'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

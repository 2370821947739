const basePath = '/prompt/v1';
const configPath = `${basePath}/configuration`;
const notificationsPath = `${basePath}/notifications`;
const documentPath = `${basePath}/documents`;
const filtersPath = `${basePath}/filters`;
const exportPath = `${basePath}/export`;

export default {
	prompt: {
		settings: {
			retrieve: `${configPath}/settings`,
		},
		notifications: {
			search: notificationsPath,
			count: `${notificationsPath}/count`,
			details: `${notificationsPath}/{notificationId}`,
			task: {
				update: `${notificationsPath}/{notificationId}/task`,
			},
			thresholdState: `${notificationsPath}/threshold-exceeded`,
		},
		configuration: {
			filter: {
				retrieve: `${configPath}/filters`,
			},
			export: {
				retrieve: `${configPath}/export-properties`,
			},
		},
		filters: {
			create: filtersPath,
			retrieve: filtersPath,
			retrieveQuick: `${basePath}/quick-filters`,
			filter: {
				retrieve: `${filtersPath}/{filterId}`,
				update: `${filtersPath}/{filterId}`,
				delete: `${filtersPath}/{filterId}`,
			},
		},
		export: {
			create: exportPath,
			getTask: exportPath,
			delete: exportPath,
			download: `${exportPath}/download`,
		},
		events: {
			retrieve: `${notificationsPath}/{notificationId}/events`,
		},
		document: {
			retrieve: `${documentPath}/{documentId}`,
		},
		patientSummary: {
			careTeams: {
				retrieve: `${notificationsPath}/{notificationId}/care-team`,
			},
		},
		careNotes: {
			create: `${notificationsPath}/{notificationId}/care-notes`,
			update: `${notificationsPath}/care-notes/{careNoteId}`,
			retrieve: `${notificationsPath}/{notificationId}/care-notes`,
		},
	},
};

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
	PageContainer,
	PageTitle,
} from '@audacious/components/components/Page';
import { Row, Column } from '@audacious/components/components/Grid';
import PatientDetails from './patient-details';
import CareTeams from './care-teams';
import './patient-summary-page.scss';

function PatientSummaryPage(props) {
	const { notificationId } = props;

	const history = useHistory();

	const handleBack = useCallback(() => history.push('notifications'), [
		history,
	]);

	return (
		<>
			<PageTitle pageName="Patient Summary" onBack={handleBack} />
			<PageContainer>
				<Row gutter="32">
					<Column>
						<PatientDetails notificationId={notificationId} />
					</Column>
					<Column>
						<CareTeams notificationId={notificationId} />
					</Column>
				</Row>
			</PageContainer>
		</>
	);
}

PatientSummaryPage.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	notificationId: PropTypes.string.isRequired,
};

PatientSummaryPage.defaultProps = {};

export default memo(PatientSummaryPage);

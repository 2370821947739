import get from 'lodash/get';
import ExportStatus from '../../../common/export-status';
import getExportPoller from '../../../common/export-poller';

function onSuccess(actionContext, { response, options }) {
	if (actionContext.getStore('Session').isAuthenticated()) {
		const { data, status } = response;
		const { name, size, rows } = data?.file ?? {};

		if (status === 204) {
			actionContext.dispatch('GET_EXPORT_TASK_FAILED', {
				error: null,
				status: null,
			});

			return;
		}

		actionContext.dispatch('GET_EXPORT_TASK_SUCCESS', {
			status: data?.status,
			exportFile: data?.file
				? {
						name,
						size,
						rows,
				  }
				: null,
			showModalIfComplete: options ? options.showModalIfComplete : false,
		});

		if (
			data?.status === ExportStatus.PENDING ||
			data?.status === ExportStatus.RUNNING
		) {
			getExportPoller(actionContext).startPoll();
		}
	}
}

function onFailed(actionContext, { response, error }) {
	if (actionContext.getStore('Session').isAuthenticated()) {
		const { status } = response;

		if (status >= 500 && status < 600) {
			actionContext.dispatch('GET_EXPORT_TASK_FAILED', {
				error,
				status: response?.data?.status,
			});
		} else {
			actionContext.dispatch('GET_EXPORT_TASK_FAILED', {
				error: null,
				status: null,
			});
		}
	}
}

export default config => ({
	serviceName: 'getExportTask',
	authenticate: true,
	config: {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.export.getTask'),
	},
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

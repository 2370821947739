import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import TabGroup from '@audacious/components/components/TabGroup';
import EventDetail from './event-detail';
import EventDetailsGroup from './event-details-group/event-details-group';

class EventDetailsTabContent extends PureComponent {
	render() {
		const { notification } = this.props;

		const eventDate = get(notification, 'formatted.eventDate');
		const receivedDate = get(notification, 'formatted.receivedDate');
		const eventType = get(notification, 'event.adttype');
		const patientClass = get(notification, 'event.adtpatientclass');
		const alertType = get(notification, 'event.alerttypecode');
		const ipVisits = get(notification, 'metadata.ipdischargecount');
		const erVisits = get(notification, 'metadata.erdischargecount');

		const tabs = map(
			notification.formatted.eventDetailsGroups,
			eventDetailsGroup => ({
				id: eventDetailsGroup.name,
				heading: eventDetailsGroup.name,
				body: (
					<EventDetailsGroup eventDetailsGroup={eventDetailsGroup} />
				),
			}),
		);

		return (
			<>
				<EventDetail
					eventDate={eventDate}
					receivedDate={receivedDate}
					eventType={eventType}
					patientClass={patientClass}
					alertType={alertType}
					ipVisits={ipVisits}
					erVisits={erVisits}
				/>
				<TabGroup tabs={tabs} size="sm" />
			</>
		);
	}
}

EventDetailsTabContent.propTypes = {
	notification: PropTypes.shape({
		event: PropTypes.shape({
			date: PropTypes.string,
			adttype: PropTypes.string,
			adtpatientclass: PropTypes.string,
			alerttypecode: PropTypes.string,
		}).isRequired,
		metadata: PropTypes.shape({
			erdischargecount: PropTypes.number.isRequired,
			ipdischargecount: PropTypes.number.isRequired,
			receiveddate: PropTypes.string.isRequired,
		}).isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		formatted: PropTypes.object.isRequired,
	}).isRequired,
};

EventDetailsTabContent.defaultProps = {};

export default EventDetailsTabContent;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
import moment from 'moment';
import map from 'lodash/map';
import merge from 'lodash/merge';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import nameFormatter from '@audacious/web-common/formatters/nameFormatter';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import { getInitialSelectedSubscription } from '@audacious/cca-components/helpers';
import {
	getEventTypeName,
	getEventTypeColor,
} from '../common/event-type-mapping';
import { getAlertTypeName, getAlertColor } from '../common/alert-mapping';
import {
	getPatientClassColor,
	getPatientClassName,
} from '../common/patient-class-mapping';
import formatAge from './common/format-age';
import formatDate from './common/format-date';
import NOTIFICATION_PAGE_STATE from '../common/notification-page-state';

const initialState = {
	notifications: [],
	pagination: {
		page: 1,
		pageSize: 100,
	},
	orderBy: 'receivedTime',
	dateRange: 30,
	taskStatus: 'all',
	initialRetrievalTime: null,
	prevRetrievalTime: null,
	querySearchTerm: null,
	isLoading: false,
	pageState: NOTIFICATION_PAGE_STATE.initial,
	showSpinner: false,
	error: null,
	pendingTotal: 0,
	total: {
		error: null,
		isLoading: false,
		count: null,
		since: null,
		sinceDate: null,
	},
	selectedSubscriptionKey: null,
	selectedSubscription: null,
	sortOrder: 'desc',
	thresholdMessageCleared: false,
};

function formatNotificationNameSubHeading(gender, dob) {
	const formattedGender = genderFormatter(gender);
	const formattedAge = formatAge(dob);

	if (formattedGender.length > 0 && formattedAge.length > 0) {
		return `${formattedGender}, ${formattedAge}`;
	}

	if (formattedGender.length > 0) {
		return formattedGender;
	}

	return formattedAge;
}

function formatFacility({ senderCode, senderName, location }) {
	if (senderCode === 'AI_EMERGENCY') {
		return !isNil(location) ? location : '';
	}

	return !isNil(senderName) ? senderName : '';
}

function formatNotification(notificationResult, isNew) {
	const {
		dob,
		gender,
		eventTime,
		eventCode,
		ptClass,
		alertType,
		careNotes,
	} = notificationResult;

	const careNoteCount = careNotes?.count;

	return {
		...notificationResult,
		isNew,
		name: nameFormatter('lfm', notificationResult),
		dob: formatDate(dob),
		eventDate: moment(eventTime).format('MM/DD/YYYY'),
		eventTime: moment(eventTime).format('hh:mm A'),
		eventName: getEventTypeName(eventCode),
		eventColor: getEventTypeColor(eventCode),
		patientClassName: getPatientClassName(ptClass),
		patientClassColor: getPatientClassColor(ptClass),
		alertTypeName: getAlertTypeName(alertType),
		alertColor: getAlertColor(alertType),
		genderAndAge: formatNotificationNameSubHeading(gender, dob),
		facility: formatFacility(notificationResult),
		hasCareNotes: !isNil(careNoteCount) && careNoteCount > 0,
	};
}

let loadingTimeout;

class NotificationStore extends CommonStore {
	constructor(dispatcher) {
		const selectedSubscription = getInitialSelectedSubscription(dispatcher);

		super(dispatcher, {
			...initialState,
			selectedSubscriptionKey: selectedSubscription?.key,
			selectedSubscription,
		});
	}

	startLoading(pageState) {
		const newState = {
			pageState,
		};

		if (!this.state.isLoading) {
			loadingTimeout = setTimeout(() => {
				this.setState({
					isLoading: true,
					showSpinner: true,
				});
			}, 3000);

			newState.isLoading = true;
			newState.showSpinner = false;
			newState.error = null;
		}

		this.setState(newState);
	}

	stopLoading() {
		this.setState({
			isLoading: false,
			showSpinner: false,
		});
	}

	retrieveQuickFiltersStart() {
		return this.startLoading(NOTIFICATION_PAGE_STATE.loadingQuickFilters);
	}

	retrieveQuickFiltersFinish(quickFilters) {
		const {
			orderBy = initialState.orderBy,
			sortOrder = initialState.sortOrder,
			dateRange = initialState.dateRange,
			taskStatus = initialState.taskStatus,
		} = quickFilters ?? {};

		this.setState({
			orderBy,
			sortOrder,
			dateRange,
			taskStatus,
			pageState: NOTIFICATION_PAGE_STATE.quickFiltersLoaded,
		});
	}

	retrieveNotificationsStart() {
		return this.startLoading(NOTIFICATION_PAGE_STATE.loadingNotifications);
	}

	retrieveNotificationsFinish({
		results,
		page,
		pageSize,
		orderBy,
		sortOrder,
		dateRange,
		taskStatus,
		selectedSubscriptionKey,
		selectedSubscription,
		initialRetrievalTime,
		refreshed,
		querySearchTerm,
	}) {
		const { notifications, since } = results;
		const prevRetrievalTime = !initialRetrievalTime
			? this.state.prevRetrievalTime
			: this.state.initialRetrievalTime;

		if (loadingTimeout) {
			clearTimeout(loadingTimeout);
		}

		let restAreNotNew = false;

		const formattedNotifications = map(
			notifications,
			notificationResult => {
				let isNew = false;

				if (refreshed && !restAreNotNew) {
					// Mark notifications that were received by PROMPT after the last retrival
					// time as new to render "New" chips after a refresh
					const receivedTimestamp = new Date(
						notificationResult.receivedTime,
					).getTime();
					isNew =
						!isNaN(receivedTimestamp) &&
						receivedTimestamp > prevRetrievalTime;

					if (!isNew && orderBy === 'receivedTime') {
						restAreNotNew = true;
					}
				}

				return formatNotification(notificationResult, isNew);
			},
		);

		const newStateValues = {
			pageState: refreshed
				? NOTIFICATION_PAGE_STATE.notificationsRefreshed
				: NOTIFICATION_PAGE_STATE.notificationsLoaded,
			pagination: {
				page,
				pageSize,
			},
			orderBy,
			sortOrder,
			dateRange,
			taskStatus,
			selectedSubscriptionKey,
			selectedSubscription,
			initialRetrievalTime,
			prevRetrievalTime,
			querySearchTerm: querySearchTerm || null,
			since,
			isLoading: false,
			showSpinner: false,
			error: null,
			thresholdMessageCleared: false,
		};

		const newState = merge(
			{
				...this.state,
				notifications: formattedNotifications,
			},
			newStateValues,
		);

		this.setState(newState);
	}

	retrieveNotificationsFail(error) {
		if (loadingTimeout) {
			clearTimeout(loadingTimeout);
		}

		this.setState({
			...this.state,
			pageState: NOTIFICATION_PAGE_STATE.errored,
			notifications: [],
			isLoading: false,
			showSpinner: false,
			error,
		});
	}

	retrieveNotificationInitialCountStart() {
		this.setState({
			total: merge({}, this.state.total, {
				isLoading: true,
			}),
		});
	}

	retrieveNotificationInitialCountSucceeded(data) {
		const { since, total, thresholdExceeded } = data;

		const sinceDate = formatDate(since);

		this.setState({
			total: {
				error: null,
				isLoading: false,
				count: total,
				since,
				thresholdExceeded,
				sinceDate,
			},
		});
	}

	retrieveNotificationInitialCountFailed(error) {
		this.setState({
			total: {
				error,
				isLoading: false,
				count: null,
				since: null,
				sinceDate: null,
			},
		});
	}

	retrieveNotificationCountSuccess({ pendingTotal }) {
		if (this.state.pendingTotal === pendingTotal) {
			return;
		}

		this.setState({
			pendingTotal,
		});
	}

	retrieveNotificationCountReset() {
		this.setState({
			pendingTotal: 0,
		});
	}

	updateTask({ id, task }) {
		this.state.notifications = map(
			this.state.notifications,
			notification => {
				if (notification.id === id) {
					return {
						...notification,
						taskStatusCode: task.statusCode,
					};
				}

				return notification;
			},
		);

		this.emitChange();
	}

	setDefaultSubscriptionSuccess(defaultSubscription) {
		if (this.state.selectedSubscriptionKey) {
			return;
		}

		// Update the selected subscription when the initial default was just selected
		this.setState({
			selectedSubscriptionKey: defaultSubscription.key,
			selectedSubscription: defaultSubscription,
		});
	}

	isLoading() {
		return this.state.isLoading;
	}

	getSavedFilterId() {
		return this.state.filterId;
	}

	getNotifications() {
		return this.state.notifications;
	}

	getState() {
		return this.state;
	}

	getTotal() {
		return this.state.queryTotal;
	}

	getPendingTotal() {
		return this.state.pendingTotal;
	}

	getSearchValue() {
		return this.state.querySearchTerm;
	}

	getPagination() {
		return this.state.pagination;
	}

	getOrderBy() {
		return this.state.orderBy;
	}

	getDateRange() {
		return this.state.dateRange;
	}

	getTaskStatus() {
		return this.state.taskStatus;
	}

	getSearchTerm() {
		return this.state.querySearchTerm;
	}

	getRetrievalTime() {
		return this.state.initialRetrievalTime;
	}

	getSelectedSubscriptionKey() {
		return this.state.selectedSubscriptionKey;
	}

	getSelectedSubscription() {
		return this.state.selectedSubscription;
	}

	getSelectedDateRange() {
		return this.state.dateRange;
	}

	getSortOrder() {
		return this.state.sortOrder;
	}

	getPageState() {
		return this.state.pageState;
	}

	getThresholdMessageCleared() {
		return this.state.thresholdMessageCleared;
	}

	clearThresholdMessage() {
		this.setState({
			thresholdMessageCleared: true,
		});
	}
}

NotificationStore.storeName = 'NotificationStore';
NotificationStore.handlers = {
	RETRIEVE_QUICK_FILTERS_START: 'retrieveQuickFiltersStart',
	RETRIEVE_QUICK_FILTERS_FINISH: 'retrieveQuickFiltersFinish',
	RETRIEVE_NOTIFICATIONS_START: 'retrieveNotificationsStart',
	RETRIEVE_NOTIFICATIONS_SUCCESS: 'retrieveNotificationsFinish',
	RETRIEVE_NOTIFICATIONS_FAILED: 'retrieveNotificationsFail',
	RETRIEVE_NOTIFICATION_COUNT_SUCCESS: 'retrieveNotificationCountSuccess',
	RETRIEVE_NOTIFICATION_INITIAL_COUNT_START:
		'retrieveNotificationInitialCountStart',
	RETRIEVE_NOTIFICATION_INITIAL_COUNT_SUCCEEDED:
		'retrieveNotificationInitialCountSucceeded',
	RETRIEVE_NOTIFICATION_INITIAL_COUNT_FAILED:
		'retrieveNotificationInitialCountFailed',
	RETRIEVE_NOTIFICATION_COUNT_RESET: 'retrieveNotificationCountReset',
	SET_DEFAULT_SUBSCRIPTION_SUCCESS: 'setDefaultSubscriptionSuccess',
	STOP_LOADING: 'stopLoading',
	LOGOUT: 'resetState',
	UPDATE_TASK: 'updateTask',
	CLEAR_THRESHOLD_MESSAGE: 'clearThresholdMessage',
};

export default NotificationStore;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { faAngleRight } from '@audacious/icons/solid/faAngleRight';
import { faAngleLeft } from '@audacious/icons/solid/faAngleLeft';
import IconButton from '@audacious/components/components/IconButton';
import Tooltip from '@audacious/components/components/Tooltip';
import {
	openNextNotificationDetails,
	openPreviousNotificationDetails,
} from '../../actions/notification-details-actions';

class EventDetailsNav extends PureComponent {
	constructor(props) {
		super(props);

		this.handleNext = this.handleNext.bind(this);
		this.handlePrevious = this.handlePrevious.bind(this);
	}

	handlePrevious() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(openPreviousNotificationDetails);
	}

	handleNext() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(openNextNotificationDetails);
	}

	render() {
		const { isPreviousDisabled, isNextDisabled } = this.props;

		return (
			<div className="event-details-nav">
				<Tooltip
					className="prev-notification-tooltip"
					anchor={IconButton}
					disabled={isPreviousDisabled}
					anchorProps={{
						className: 'prev-notification-nav',
						icon: faAngleLeft,
						size: 'sm',
						disabled: isPreviousDisabled,
						onClick: this.handlePrevious,
						'aria-label': 'Previous Notification',
					}}
				>
					Previous Notification
				</Tooltip>
				<Tooltip
					className="next-notification-tooltip"
					anchor={IconButton}
					disabled={isNextDisabled}
					anchorProps={{
						className: 'next-notification-nav',
						icon: faAngleRight,
						size: 'sm',
						disabled: isNextDisabled,
						onClick: this.handleNext,
						'aria-label': 'Next Notification',
					}}
				>
					Next Notification
				</Tooltip>
			</div>
		);
	}
}

EventDetailsNav.propTypes = {
	isPreviousDisabled: PropTypes.bool.isRequired,
	isNextDisabled: PropTypes.bool.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

EventDetailsNav.defaultProps = {};

export default applyFluxibleContext(EventDetailsNav);

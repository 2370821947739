const TEXT = {
	ADTALERT: 'ADT',
};

const COLOR = {
	ADTALERT: 'blue',
	HEDIS: 'red',
};

const DEFAULT_COLOR = 'orange';

export function getAlertTypeName(alertType) {
	return TEXT[alertType] ?? alertType;
}

export function getAlertColor(alertType) {
	return COLOR[alertType] ?? DEFAULT_COLOR;
}

import SettingsStore from './settings-store';
import NotificationStore from './notification-store';
import NotificationDetailsStore from './notification-details-store';
import FilterStore from './filter-store';
import ExportStore from './export-store';
import CareTeamsStore from './care-teams-store';
import PatientDetailsStore from './patient-details-store';
import PatientEventsStore from './patient-events-store';
import DocumentStore from './document-store';
import CareNotesStore from './care-notes-store';

export default [
	SettingsStore,
	NotificationStore,
	NotificationDetailsStore,
	FilterStore,
	ExportStore,
	CareTeamsStore,
	PatientDetailsStore,
	PatientEventsStore,
	DocumentStore,
	CareNotesStore,
];

const NOTIFICATION_PAGE_STATE = {
	initial: 'initial',
	loadingQuickFilters: 'loadingQuickFilters',
	quickFiltersLoaded: 'quickFiltersLoaded',
	loadingNotifications: 'loadingNotifications',
	notificationsLoaded: 'notificationsLoaded',
	notificationsRefreshed: 'notificationsRefreshed',
	errored: 'errored',
};

export default NOTIFICATION_PAGE_STATE;

/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { Text } from '@audacious/components/components/Typography';
import Link from '@audacious/components/components/Link';
import { Cell } from '@audacious/components/components/Table';
import CCDView from '@audacious/components/components/CCDView';
import { connectToStores } from 'fluxible-addons-react';
import EventDetailItem from '../event-detail-item';
import {
	closeDocumentView,
	openCachedDocument,
} from '../../../actions/notification-details-actions';
import EventDetailsTableView from '../common/event-details-table-view';

function renderRowChild(item) {
	const {
		formatted: { authorLine1 },
		authorInstitution,
	} = item;

	return (
		<Cell className="second-row-cell" colSpan={5}>
			<EventDetailItem label="Author" value={authorLine1} />
			<EventDetailItem
				label="Author Institution"
				value={authorInstitution}
			/>
		</Cell>
	);
}

class DocumentsTabContent extends PureComponent {
	constructor(props) {
		super(props);

		this.handleTitleClick = this.handleTitleClick.bind(this);
		this.handleCloseDocumentView = this.handleCloseDocumentView.bind(this);
		this.renderTopContent = this.renderTopContent.bind(this);

		this.columns = [
			{
				headerBody: 'Title',
				cellValuePath: ['name', 'id'],
				width: '30%',
				renderCell: ([name, id]) => (
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					<Link
						weight="semi-bold"
						onClick={this.handleTitleClick}
						onClickMeta={id}
					>
						{name}
					</Link>
				),
			},
			{
				headerBody: 'Source',
				cellValuePath: ['sourceFacility', 'sourceDepartment'],
				width: '25%',
				renderCell: ([sourceFacility, sourceDepartment]) => (
					<>
						<Text size="lg" weight="bold">
							{sourceFacility}
						</Text>
						<div>{sourceDepartment}</div>
					</>
				),
			},
			{
				headerBody: 'Service Time',
				cellValuePath: [
					'formatted.serviceStartTime',
					'formatted.serviceStopTime',
				],
				width: '167px',
				renderCell: ([serviceStartTime, serviceStopTime]) => (
					<>
						{serviceStartTime}
						<div>{serviceStopTime}</div>
					</>
				),
			},
			{
				headerBody: 'Type',
				cellValuePath: 'documentType',
				width: '25%',
			},
		];
	}

	componentDidMount() {
		const { notificationId, hasLoadedSuccessfully, isLoading } = this.props;

		if (hasLoadedSuccessfully || isLoading) {
			return;
		}

		const {
			fluxibleContext: {
				service: { retrieveEvents },
			},
		} = this.props;

		retrieveEvents({ options: { notificationId } });
	}

	handleTitleClick({ meta: id, event }) {
		const {
			fluxibleContext: {
				executeAction,
				service: { retrieveDocument },
			},
		} = this.props;
		const { documentEvents, documentState } = this.props;

		event.stopPropagation();

		const documentEvent = find(documentEvents, item => item.id === id);

		const documentHtml = documentState.documentCache[`_${id}`];

		if (!isNil(documentHtml)) {
			executeAction(openCachedDocument, {
				documentEvent,
				documentHtml,
			});
		} else {
			retrieveDocument({
				options: {
					documentId: id,
					documentEvent,
				},
			});
		}
	}

	handleCloseDocumentView() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(closeDocumentView);
	}

	renderTopContent() {
		const { fromDate, toDate } = this.props;

		return (
			<>
				<Text size="lg" weight="bold">
					Documents from
				</Text>
				<Text size="lg" weight="semi-bold">
					{`${fromDate} - ${toDate}`}
				</Text>
			</>
		);
	}

	render() {
		const {
			documentEvents,
			error,
			isLoading,
			userName,
			documentState,
		} = this.props;

		return (
			<>
				<EventDetailsTableView
					items={documentEvents}
					tableColumns={this.columns}
					error={error}
					isLoading={isLoading}
					renderTopContent={this.renderTopContent}
					renderRowChild={renderRowChild}
				/>
				<CCDView
					documentHtml={documentState.documentHtml}
					documentMeta={documentState.documentMeta}
					userName={userName}
					retrievalFailed={!isNil(documentState.error)}
					open={documentState.isOpen}
					onClose={this.handleCloseDocumentView}
				/>
			</>
		);
	}
}

DocumentsTabContent.propTypes = {
	notificationId: PropTypes.string.isRequired,
	error: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	hasLoadedSuccessfully: PropTypes.bool.isRequired,
	documentEvents: PropTypes.array.isRequired,
	fromDate: PropTypes.string.isRequired,
	toDate: PropTypes.string.isRequired,
	userName: PropTypes.string.isRequired,
	documentState: PropTypes.shape({
		error: PropTypes.object,
		isLoading: PropTypes.bool,
		isOpen: PropTypes.bool,
		documentMeta: PropTypes.object,
		documentHtml: PropTypes.string,
		documentCache: PropTypes.objectOf(PropTypes.string).isRequired,
	}).isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

DocumentsTabContent.defaultProps = {
	error: null,
};

export default connectToStores(
	applyFluxibleContext(DocumentsTabContent),
	['PatientEventsStore', 'DocumentStore', 'Session'],
	context => {
		const patientEventsStore = context.getStore('PatientEventsStore');

		const documentStore = context.getStore('DocumentStore');

		return {
			...patientEventsStore.getState(),
			documentState: documentStore.getState(),
			userName:
				context
					.getStore('Session')
					?.getUser()
					?.getFullName() ?? '',
		};
	},
);

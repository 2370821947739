/* eslint-disable no-underscore-dangle */
import Promise from 'bluebird';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import retrieveNotifications from './retrieve-notifications';
import DrawerTypes from '../common/drawer-types';
import { duplicateFilters } from '../common/static-text';

function reloadNotifications(context) {
	retrieveNotifications(context, {
		newPagination: {
			page: 1,
		},
	});
}

export function startDeleteFilterAction(context, data) {
	context.dispatch('OPEN_FILTER_DRAWER', {
		drawerId: DrawerTypes.DELETE,
		data,
	});
}

export function startCreateFilterAction(context, data) {
	context.dispatch('OPEN_FILTER_DRAWER', {
		drawerId: DrawerTypes.CREATE,
		data,
	});
}

export function startEditFilterAction(context, data) {
	context.dispatch('OPEN_FILTER_DRAWER', {
		drawerId: DrawerTypes.EDIT,
		data,
	});
}

export function startManageFilterAction(context) {
	context.dispatch('OPEN_FILTER_DRAWER', {
		drawerId: DrawerTypes.MANAGE,
		data: null,
	});
}

export function closeCurrentFilterDrawer(context) {
	context.dispatch('CLOSE_CURRENT_FILTER_DRAWER');
}

function createFilter(context, initialFilterData, saveOnly = false) {
	const filterData = !isNil(initialFilterData)
		? cloneDeep(initialFilterData)
		: {
				expressions: [],
				name: null,
		  };

	context.dispatch('OPEN_FILTER_DRAWER', {
		drawerId: DrawerTypes.CREATE,
		data: { filterData, saveOnly },
	});
}

export function createFilterFromNew(context) {
	createFilter(context, {
		name: null,
		expressions: [],
	});
}

export function editFilter(context) {
	const filterStore = context.getStore('FilterStore');

	const filterDataGroup = filterStore.getCustomFilterGroup();

	const expressions = !isNil(filterDataGroup)
		? filterDataGroup.getExpressions()
		: [];

	createFilter(context, {
		name: null,
		expressions,
	});
}

export function createFilterFromCombined(context) {
	const filterStore = context.getStore('FilterStore');

	const queryData = filterStore.getQuery();

	createFilter(context, queryData, true);
}

export function updateFilter(context, filterData) {
	const {
		service: { updateFilter: updateFilterService },
	} = context;

	const { _id, expressions, name } = filterData;

	updateFilterService({
		options: {
			filterId: _id,
		},
		data: {
			name,
			query: {
				expressions,
			},
		},
	});
}

export function saveFilter(context, filterData) {
	Promise.fromCallback(resolve => {
		const { name, expressions } = filterData;

		context.service.createFilter(
			{
				data: {
					name,
					query: {
						expressions,
					},
				},
			},
			resolve,
		);
	})
		.then(callContext => {
			// eslint-disable-next-line no-underscore-dangle
			const _id = callContext.response.data;

			context.dispatch('APPLY_CUSTOM_FILTERS', null);
			context.dispatch('APPLY_SAVED_FILTERS', _id);

			retrieveNotifications(context, {
				newPagination: {
					page: 1,
				},
			});

			closeCurrentFilterDrawer(context);
		})
		.catch(() => {
			// eslint-disable-next-line no-console
			console.error('Failed to save new filter');
		});
}

function selectSavedFilter(context) {
	const filterStore = context.getStore('FilterStore');

	const savedFilterGroup = filterStore.getSavedFilterGroup();

	if (!isNil(savedFilterGroup) && savedFilterGroup.hasOverridden()) {
		context.notify.info({
			message: duplicateFilters,
		});
	}
}

export function applyFilter(context, filterData) {
	context.dispatch('APPLY_CUSTOM_FILTERS', filterData);

	selectSavedFilter(context);

	retrieveNotifications(context, {
		newPagination: {
			page: 1,
		},
	});

	closeCurrentFilterDrawer(context);
}

export function applySavedFilter(context, id) {
	context.dispatch('APPLY_SAVED_FILTERS', id);

	selectSavedFilter(context);

	reloadNotifications(context);
}

export function clearAllFilters(context) {
	context.dispatch('CLEAR_ALL_FILTERS', null);

	reloadNotifications(context);
}

export function removeCustomFilter(context, payload) {
	context.dispatch('REMOVE_CUSTOM_FILTER', payload);

	reloadNotifications(context);
}

export function toggleSavedFilter(context, payload) {
	context.dispatch('TOGGLE_SAVED_FILTER', payload);

	reloadNotifications(context);
}

export function clearThresholdMessage(context) {
	context.dispatch('CLEAR_THRESHOLD_MESSAGE');
}

/* eslint-disable react/no-access-state-in-setstate */
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
	settings: null,
};

class SettingsStore extends CommonStore {
	constructor(dispatcher) {
		super(dispatcher, initialState);
	}

	setSettings(settings) {
		this.setState({
			settings,
		});
	}

	getSettings() {
		return this.state.settings;
	}

	getThreshold() {
		return this.state.settings?.notifications?.threshold;
	}
}

SettingsStore.storeName = 'SettingsStore';
SettingsStore.handlers = {
	SET_SETTINGS: 'setSettings',
};

export default SettingsStore;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import classnames from 'classnames';
import ActionSelector from '@audacious/components/components/ActionSelector';
import {
	STATUS_OPTIONS,
	STATUS_OPTIONS_NOT_SET,
} from '../../common/status-options';

function StatusSelector(props) {
	const { id, className, notificationId, status, disabled, onChange } = props;

	let options = STATUS_OPTIONS_NOT_SET;
	let value = 'none';

	if (!isNil(status) && status.length > 0) {
		value = status;
		options = STATUS_OPTIONS;
	}

	const selectorId = !isNil(id) ? `${id}-${notificationId}` : notificationId;

	return (
		<ActionSelector
			id={`status-selector-${selectorId}`}
			className={classnames('status-selector', className)}
			value={value}
			options={options}
			textWhenOpen="Set Status"
			size="xs"
			disabled={disabled}
			onChange={onChange}
		/>
	);
}

StatusSelector.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	notificationId: PropTypes.string.isRequired,
	status: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

StatusSelector.defaultProps = {
	id: null,
	className: null,
	status: null,
	disabled: false,
};

export default memo(StatusSelector);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthenticatedPage } from '@audacious/client';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, import/extensions
import PdfjsWorker from 'worker-loader!pdfjs-dist/build/pdf.worker.mjs';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PDFViewer from '@audacious/components/components/PDFViewer';
import NotificationsPage from './pages/notifications-page/notifications-page';
import PatientSummaryPage from './pages/patient-summary-page';

PDFViewer.setWorker(new PdfjsWorker());

class Root extends Component {
	componentDidMount() {
		const {
			fluxibleContext: {
				service: {
					retrieveFilterConfig,
					retrieveFilters,
					retrieveQuickFilters,
					retrieveSettings,
					getExportTask,
				},
			}
		} = this.props;

		retrieveFilterConfig();
		retrieveFilters();
		retrieveQuickFilters();
		retrieveSettings();

		getExportTask({ options: { showModalIfComplete: true } });
	}

	render() {
		return (
			<AuthenticatedPage id="prompt-page">
				<Switch>
					<Route exact path="/notifications">
						<NotificationsPage />
					</Route>
					<Route exact path="/notifications/:id/patient-summary">
						{({ match }) => (
							<PatientSummaryPage
								notificationId={match.params.id}
							/>
						)}
					</Route>
					<Route>
						<Redirect to="/notifications" />
					</Route>
				</Switch>
			</AuthenticatedPage>
		);
	}
}

Root.propTypes = {
	fluxibleContext: {
		service: {
			retrieveFilterConfig: PropTypes.func.isRequired,
			retrieveFilters: PropTypes.func.isRequired,
			retrieveQuickFilters: PropTypes.func.isRequired,
			retrieveSettings: PropTypes.func.isRequired,
			getExportTask: PropTypes.func.isRequired,
		}.isRequired,
	}.isRequired,
};

Root.defaultProps = {};

export default applyFluxibleContext(Root);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Menu, { MenuButton } from '@audacious/components/components/Menu';
import { faGear } from '@audacious/icons/solid/faGear';
import { faSliders } from '@audacious/icons/solid/faSliders';
import { AnchorButton } from '@audacious/components/components/DropDown';
import { isNil } from 'lodash';
import SavedFiltersMenuItem from './saved-filters-menu-item';
import {
	createFilterFromNew,
	startManageFilterAction,
} from '../../../../actions/filter-actions';

class FilterMenu extends Component {
	constructor(props) {
		super(props);

		this.handleCreateFilter = this.handleCreateFilter.bind(this);
		this.handleEditFiltersClick = this.handleEditFiltersClick.bind(this);
	}

	handleCreateFilter() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(createFilterFromNew);
	}

	handleEditFiltersClick() {
		const {
			fluxibleContext: { executeAction },
		} = this.props;

		executeAction(startManageFilterAction);
	}

	render() {
		const { savedFilterData, isSaving, hasSubscriptions } = this.props;

		const savedFilters = savedFilterData?.list;

		let savedFiltersContent = null;

		if (!isNil(savedFilters) && savedFilters.length > 0) {
			savedFiltersContent = (
				<>
					<SavedFiltersMenuItem savedFilterList={savedFilters} />
					<MenuButton
						id="edit-saved-filters-menu-item"
						menuIcon={faGear}
						onClick={this.handleEditFiltersClick}
					>
						Edit Saved Filters
					</MenuButton>
				</>
			);
		}

		return (
			<Menu
				id="filter-menu"
				Anchor={AnchorButton}
				anchorProps={{
					id: 'filterButton',
					color: 'secondary',
					variant: 'opaque',
					children: 'All Filters',
					leftIcon: faSliders,
				}}
				disabled={isSaving || !hasSubscriptions}
				size="sm"
			>
				<MenuButton
					id="new-filter-menu-item"
					onClick={this.handleCreateFilter}
				>
					New Filter
				</MenuButton>
				{savedFiltersContent}
			</Menu>
		);
	}
}

FilterMenu.propTypes = {
	savedFilterData: PropTypes.shape({
		list: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		),
	}),
	isSaving: PropTypes.bool.isRequired,
	hasSubscriptions: PropTypes.bool.isRequired,
	fluxibleContext: {
		executeAction: PropTypes.func.isRequired,
	}.isRequired,
};

FilterMenu.defaultProps = {
	savedFilterData: null,
};

export default connectToStores(
	applyFluxibleContext(FilterMenu),
	['FilterStore'],
	context => {
		const filterStore = context.getStore('FilterStore');

		return {
			savedFilterData: filterStore.getSavedFilterData(),
		};
	},
);

/* eslint-disable react/forbid-prop-types */
import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { faAngleUp } from '@audacious/icons/regular/faAngleUp';
import { faAngleDown } from '@audacious/icons/regular/faAngleDown';
import Icon from '@audacious/components/components/Icon';
import Spinner from '@audacious/components/components/Spinner';
import { SimpleTable } from '@audacious/components/components/Table';
import TableMessage from '@audacious/components/components/TableMessage';
import { PageNavigation } from '@audacious/components/components/Pagination';
import useTableManager from '@audacious/components/hooks/useTableManager';

const INITIAL_CONFIG = {
	paging: {
		currentPage: 1,
		recordsPerPage: 10,
	},
};

function renderTableMessage(items, isLoading, error) {
	if (error) {
		return (
			<TableMessage
				header="Something went wrong..."
				body="Please try again."
			/>
		);
	}

	if (isLoading || (items?.length ?? 0) > 0) {
		return null;
	}

	return <TableMessage header="No record(s) available at this time." />;
}

function EventDetailsTableView(props) {
	const {
		items,
		tableColumns,
		error,
		isLoading,
		renderTopContent,
		renderRowChild,
	} = props;

	const [openedItemId, setOpenedItemId] = useState(null);

	const columns = useMemo(
		() => [
			...tableColumns,
			{
				cellValuePath: 'id',
				width: '45px',
				renderCell: id => {
					const carat = id === openedItemId ? faAngleUp : faAngleDown;

					return <Icon size="md" icon={carat} />;
				},
			},
		],
		[tableColumns, openedItemId],
	);

	const [processedData, config, setCallbacks] = useTableManager(
		items,
		columns,
		INITIAL_CONFIG,
	);

	const handleRowClick = useCallback(
		({ meta }) => {
			const id = meta?.item?.id;

			setOpenedItemId(openedItemId !== id ? id : null);
		},
		[openedItemId],
	);

	if (isLoading) {
		return (
			<div className="spinner-container">
				<Spinner size="lg" variant="overlay" />
			</div>
		);
	}

	return (
		<>
			<div className="event-details-table-message">
				<div className="message-text-group">
					{isNil(error) ? renderTopContent() : null}
				</div>
				<PageNavigation
					numRecords={processedData.total}
					currentPage={config.paging.currentPage}
					recordsPerPage={config.paging.recordsPerPage}
					onGotoPage={setCallbacks.setCurrentPage}
					disabled={isLoading}
					firstAndLastNav
				/>
			</div>
			<SimpleTable
				className="event-details-table"
				items={processedData.items}
				columns={columns}
				minWidth={500}
				onClickRow={handleRowClick}
				isRowSelected={item => openedItemId === item.id}
				renderRowChild={item => {
					if (openedItemId !== item.id) {
						return null;
					}

					return renderRowChild(item);
				}}
				alwaysShowHeaders
			/>
			{renderTableMessage(processedData.items, isLoading, error) ?? (
				<div className="event-details-paging">
					<PageNavigation
						id="bottom-event-pagination-control"
						numRecords={processedData.total}
						currentPage={config.paging.currentPage}
						recordsPerPage={config.paging.recordsPerPage}
						onGotoPage={setCallbacks.setCurrentPage}
						disabled={isLoading}
						numJumpPages={5}
						jumpToPageNav
					/>
				</div>
			)}
		</>
	);
}

EventDetailsTableView.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
	error: PropTypes.object,
	isLoading: PropTypes.bool,
	renderTopContent: PropTypes.func.isRequired,
	renderRowChild: PropTypes.func.isRequired,
};

EventDetailsTableView.defaultProps = {
	error: null,
	isLoading: false,
};

export default memo(EventDetailsTableView);

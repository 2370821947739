/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { faCirclePlus } from '@audacious/icons/solid/faCirclePlus';
import {
	Container,
	Row,
	Column,
} from '@audacious/components/components/Grid';
import { DataList, DataButton, DataAccess } from '@audacious/components/components/Data';
import { Text } from '@audacious/components/components/Typography';
import EditFilterProperty from './filter-property';
import { filterConfigPropType } from '../../common/app-prop-types';
import './filter-properties.scss';

const mainPath = 'expressions';

function handleAddAnotherTopHidden(dataContext) {
	const {
		form: { get },
	} = dataContext;

	return get(mainPath).length <= 10;
}

function handleAddFilterTop(_, dataContext) {
	const {
		form: { insert },
	} = dataContext;

	insert({}, `${mainPath}.0`);
}

function handleAddFilter(_, dataContext) {
	const {
		form: { get, insert },
	} = dataContext;

	const filters = get(mainPath);

	insert({}, `${mainPath}.${filters.length}`);
}

class FilterProperties extends Component {
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.formRef = createRef();

		this.disableAddAnother = this.disableAddAnother.bind(this);
	}

	disableAddAnother(dataContext) {
		const {
			filterConfig: { totalPossible },
		} = this.props;

		const {
			form: { get },
		} = dataContext;

		const filters = get(mainPath);

		return filters.length >= totalPossible;
	}

	render() {
		const {
			filterConfig: { lookup },
			availableFilters,
			availableOperatorsLookup,
			onUpdateAvailableFilters,
		} = this.props;

		return (
			<>
				<DataAccess>
					{({ property: { results }, form: { executed } }) => {
						if (!executed || isEmpty(results)) {
							return null;
						}

						return (
							<Row gutter="16">
								<Column>
									<Text
										color="danger"
										weight="bold"
										size="sm"
									>
										All fields are required
									</Text>
								</Column>
							</Row>
						);
					}}
				</DataAccess>
				<Container gutter="16">
					<Row gutter="16">
						<Column>
							<DataButton
								id="add-another-top-btn"
								className="add-filter-btn"
								color="secondary"
								variant="opaque"
								onClick={handleAddFilterTop}
								disabled={this.disableAddAnother}
								hidden={[handleAddAnotherTopHidden, false]}
								leftIcon={faCirclePlus}
							>
								<span>{' Add another'}</span>
							</DataButton>
						</Column>
					</Row>
				</Container>
				<DataList path={mainPath} id="filters" className="filter-list">
					<EditFilterProperty
						filterLookUp={lookup}
						availableFilters={availableFilters}
						availableOperatorsLookup={availableOperatorsLookup}
						onUpdateAvailableFilters={onUpdateAvailableFilters}
					/>
				</DataList>
				<Row gutter="16">
					<Column>
						<DataButton
							id="add-another-bottom-btn"
							className="add-filter-btn"
							color="secondary"
							variant="opaque"
							onClick={handleAddFilter}
							disabled={this.disableAddAnother}
							leftIcon={faCirclePlus}
						>
							Add another
						</DataButton>
					</Column>
				</Row>
			</>
		);
	}
}

FilterProperties.propTypes = {
	filterConfig: filterConfigPropType.isRequired,
	availableFilters: PropTypes.shape({
		targeted: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
			.isRequired,
		basic: PropTypes.arrayOf(PropTypes.string).isRequired,
	}).isRequired,
	availableOperatorsLookup: PropTypes.objectOf(
		PropTypes.arrayOf(PropTypes.string),
	).isRequired,
	onUpdateAvailableFilters: PropTypes.func.isRequired,
};

FilterProperties.defaultProps = {};

export default FilterProperties;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Text } from '@audacious/components/components/Typography';
import getOperatorLabel from '../../../../common/get-operator-labels';

class FilterLabel extends PureComponent {
	render() {
		const { name, operator, value } = this.props;

		const content = <Text className="filter-value">{value}</Text>;

		if (isNil(name)) {
			return content;
		}

		return (
			<>
				<Text className="filter-name" weight="bold">
					{name}
				</Text>{' '}
				<Text className="filter-operator">
					{getOperatorLabel(operator)}
				</Text>
				{operator !== 'isempty' ? <> {content}</> : null}
			</>
		);
	}
}

FilterLabel.propTypes = {
	name: PropTypes.string,
	operator: PropTypes.string.isRequired,
	value: PropTypes.string,
};

FilterLabel.defaultProps = {
	name: null,
	value: null,
};

export default FilterLabel;

import get from 'lodash/get';

function beforeRequest(actionContext) {
	actionContext.dispatch('LOAD_CARE_TEAMS_START');
}

function onSuccess(actionContext, { response }) {
	actionContext.dispatch('LOAD_CARE_TEAMS_FINISH', [null, response.data]);
}

function onFailed(actionContext, { error }) {
	actionContext.dispatch('LOAD_CARE_TEAMS_FINISH', [error, null]);
}

export default config => ({
	serviceName: 'retrieveCareTeams',
	authenticate: true,
	config: {
		method: 'get',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		url: get(config, 'endpoints.prompt.patientSummary.careTeams.retrieve'),
	},
	beforeRequest,
	onSuccess,
	onFailed,
	onError: onFailed,
	onTimeout: onFailed,
	onFatal: onFailed,
});

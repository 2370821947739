const TEXT = {
	I: 'Inpatient',
	E: 'Emergency',
	O: 'Outpatient',
	OBS: 'Observation',
	AE: 'Ambulance Encounter',
	COVIDIMM: 'COVID-19 Vaccination',
	IZ: 'Immunization Gap in Care',
	DEATH: 'Patient Deceased',
	CIE: 'Clinical Intelligence Engine',
};

const COLOR = {
	I: 'red',
	E: 'green',
	O: 'purple',
};

const DEFAULT_COLOR = 'yellow';

export function getPatientClassName(patientClass) {
	return TEXT[patientClass] ?? patientClass;
}

export function getPatientClassColor(patientClass) {
	return COLOR[patientClass] ?? DEFAULT_COLOR;
}

import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Card from '@audacious/components/components/Card';
import { SubHeading, Paragraph , Text } from '@audacious/components/components/Typography';
import Spinner from '@audacious/components/components/Spinner';

class PatientDetails extends React.PureComponent {
	componentDidMount() {
		const {
			fluxibleContext: {
				service: { retrievePatientDetails },
			},
		} = this.props;

		const { notificationId, data } = this.props;

		if (isNil(data)) {
			retrievePatientDetails({
				options: {
					notificationId,
				},
			});
		}
	}

	renderData() {
		const { data } = this.props;

		let patientName = 'Patient Name';
		let gender = 'U';
		let birth = '--/--/----';

		if (!isNil(data)) {
			patientName = `${data.firstName} ${data.lastName}`;
			gender = data.gender;
			birth = `${data.dob}, ${data.age}`;
		}

		return (
			<>
				<SubHeading inline level="2">
					{patientName}
				</SubHeading>
				<SubHeading inline level="5">{` (${gender}) `}</SubHeading>
				<Text size="lg" weight="bold">
					DOB:
				</Text>
				<Text size="lg">{` ${birth}`}</Text>
			</>
		);
	}

	render() {
		const { isWorking, error } = this.props;

		let content = null;

		if (error) {
			content = (
				<Paragraph size="lg">
					Failed to load patient information
				</Paragraph>
			);
		} else if (isWorking) {
			content = (
				<>
					{this.renderData()}
					<Spinner size="md" variant="overlay" />
				</>
			);
		} else {
			content = this.renderData();
		}

		return (
			<Card id="patient-details-card" size="sm">
				{content}
			</Card>
		);
	}
}

PatientDetails.propTypes = {
	isWorking: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.any,
	data: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		gender: PropTypes.string.isRequired,
		dob: PropTypes.string.isRequired,
		age: PropTypes.string.isRequired,
	}),
	notificationId: PropTypes.string.isRequired,
	fluxibleContext: {
		service: {
			retrievePatientDetail: PropTypes.isRequired,
		}.isRequired,
	}.isRequired,
};

PatientDetails.defaultProps = {
	error: null,
	data: null,
};

export default connectToStores(
	applyFluxibleContext(PatientDetails),
	['PatientDetailsStore'],
	context => {
		const patientDetailsStore = context.getStore('PatientDetailsStore');

		return {
			isWorking:
				!patientDetailsStore.isLoaded() ||
				patientDetailsStore.isLoading(),
			error: patientDetailsStore.getError(),
			data: patientDetailsStore.getData(),
		};
	},
);

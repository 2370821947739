import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { NOTIF_STATUS_CODE_MAPPING } from '../common/status-options';

// eslint-disable-next-line import/prefer-default-export
export function commonRequestBody(
	notificationStore,
	filterStore,
	{
		searchQuery,
		orderBy,
		sortOrder,
		dateRange,
		taskStatus,
		subscriptionKey,
	} = {},
) {
	const finalSubscriptionKey =
		subscriptionKey ?? notificationStore.getSelectedSubscriptionKey();

	const nameMrn = !isUndefined(searchQuery)
		? searchQuery
		: notificationStore.getSearchTerm();

	const query = {
		expressions: [
			{
				var: 'subscription.id',
				op: 'equals',
				value: [finalSubscriptionKey],
				valueType: 'number',
			},
		],
	};

	const expressions = cloneDeep(filterStore.getQuery()?.expressions);

	let finalOrderBy = orderBy;

	if (filterStore.hasEventTime()) {
		finalOrderBy = 'eventTime';
	} else if (filterStore.hasReceivedTime()) {
		finalOrderBy = 'receivedTime';
	} else if (isNil(orderBy)) {
		finalOrderBy = notificationStore.getOrderBy();
	}

	const finalTaskStatus = taskStatus ?? notificationStore.getTaskStatus();

	if (!filterStore.hasTaskStatus() && finalTaskStatus !== 'all') {
		query.expressions.push({
			var: 'task.statusCode',
			op: 'equals',
			value: [`${NOTIF_STATUS_CODE_MAPPING[finalTaskStatus]}`],
			valueType: 'number',
		});
	}

	if (!isNil(expressions)) {
		query.expressions.push(...expressions);
	}

	const body = {
		nameMrn: nameMrn || undefined,
		query,
		options: {
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			orderBy: finalOrderBy,
			sortOrder: sortOrder ?? notificationStore.getSortOrder(),
			dateRange: dateRange ?? notificationStore.getSelectedDateRange(),
			taskStatus: finalTaskStatus,
		},
	};

	return body;
}
